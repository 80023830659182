export enum BuildingTypes {
  Acquisition = 'acquisition',
  Apartments = 'multi_family',
  Refinance = 'refinance',
  Manufacturing = 'manufacturing',
  MedicalOffice = 'medical_office',
  MobileHomePark = 'mobile_home_park',
  NewDevelopment = 'new_development',
  Hotel = 'hotel',
  Office = 'office',
  PhoneNumber = 'phone_number',
  Redevelopment = 'redevelopment',
  Religious = 'religious',
  RentalPortfolio = 'rental_portfolio',
  Retail = 'retail',
  SeniorApartments = 'senior_apartments',
  Storage = 'storage',
  StudentHousing = 'student_housing',
  Other = 'other',
}

import {FC, useEffect, useState} from 'react';
// TODO: disabled until there will be a solution about send message button
// import {Divider} from '@mui/material';
// import makeStyles from '@mui/styles/makeStyles';
// import BasicButton from 'shared/components/BasicButton';
import UserCard from 'pages/LenderView/UsersPanel/UserCard';
import {useDispatch, useSelector} from 'react-redux';
import {ava} from 'core/service/ava';
import {TeamMemberData} from 'core/_types/base';
import {selectorGetProject} from 'store/project-service/selector';
import {selectorGetCapitalAdvisorProfiles, selectorGetUserSettings} from 'store/user-service/selector';
import {getCapitalAdvisorProfiles} from 'store/user-service/asyncActions';
import AccordionWrapper from 'shared/components/Accordion';
import s from './UsersPanel.module.scss';
import {IsMobileViewProps} from '../helpers';

// TODO: disabled until there will be a solution about send message button
// const useStyles = makeStyles(() => ({
//   divider: {
//     '@media(max-width: 425px)': {
//       display: 'none',
//     },
//   },
// }));

const UsersPanel: FC<IsMobileViewProps> = ({isMobileView}) => {
  const dispatch = useDispatch();
  const project = useSelector(selectorGetProject);
  const capitalAdvisorProfiles = useSelector(selectorGetCapitalAdvisorProfiles);
  const userSettings = useSelector(selectorGetUserSettings);

  const [usersSyms, setUsersSyms] = useState<Array<string>>([]);

  useEffect(() => {
    if (project.capitalAdvisorList) {
      const newUsersSyms = project.capitalAdvisorList.reduce((res: any, item: any) => [...res, item], []);
      setUsersSyms(newUsersSyms);
    }
  }, [project]);

  useEffect(() => {
    if (usersSyms.length && ava) dispatch(getCapitalAdvisorProfiles({usersSyms, ava}));
  }, [usersSyms]);

  // TODO: disabled until there will be a solution about send message button
  // const classes = useStyles();

  const content = (
    <div className={s.usersPanel}>
      {capitalAdvisorProfiles.map((item: TeamMemberData) => (
        <div className={s.usersPanel__users} key={item.email}>
          <UserCard
            name={item.displayName}
            email={item.email}
            phoneNumber={item.phoneNumber.format(userSettings)}
            profileImageUrl={item.profileImage?.thumbnailUrl}
          />
        </div>
      ))}
    </div>
  );

  if (!capitalAdvisorProfiles.length) return null;
  if (isMobileView) return <AccordionWrapper title="Deal Team">{content}</AccordionWrapper>;

  return content;

  // TODO: disabled until there will be a solution about send message button
  //      <Divider className={classes.divider} />
  // <div className={s.questions}>
  //   <p className={s.questions__title}>Questions?</p>
  //   <BasicButton className="blue" variant="contained">
  //     Send a Message
  //   </BasicButton>
  // </div>
};

export default UsersPanel;

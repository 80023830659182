import {configureStore} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import projectReducer from './project-service/projectSlice';
import userReducer from './user-service/userProfilesSlice';
import avaReducer from './ava-service/avaSlice';
import quoteTermsReducer from './quote-terms-service/quoteTermsSlice';
import createNewDealReducer from './create-new-deal-service/createNewDealSlice';
import sharesReducer from './shares-service/sharesSlice';

const store = configureStore({
  reducer: {
    projectRedux: projectReducer,
    quoteTermsRedux: quoteTermsReducer,
    userRedux: userReducer,
    avaRedux: avaReducer,
    createNewDealRedux: createNewDealReducer,
    sharesRedux: sharesReducer,
  },
  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;

export default store;

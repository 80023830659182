import {FC} from 'react';
import cx from 'classnames';
import s from './KeyStatsCell.module.scss';

type DataCellProps = {
  label: string;
  value: string;
  isZero: boolean;
  isPrimary?: boolean;
};

const KeyStatsCell: FC<DataCellProps> = ({label, value, isZero, isPrimary}) => (
  <>
    {!isZero && (
      <div className={s.keyStatsCell}>
        <div className={s.keyStatsCell__label}>{label}</div>
        <div className={cx(s.keyStatsCell__number, {[s.keyStatsCell__mainNumber]: isPrimary})}>{value}</div>
      </div>
    )}
  </>
);

export default KeyStatsCell;

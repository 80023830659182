import {RootState} from 'store/store';
import {BrokerProfilesType, CapitalAdvisorProfilesType, SponsorProfilesType, UserSettingsType} from './interfaces';

export const selectorGetCapitalAdvisorProfiles =
  (store: RootState): CapitalAdvisorProfilesType =>
    store.userRedux.capitalAdvisorProfiles;

export const selectorGetSponsorProfiles =
  (store: RootState): SponsorProfilesType =>
    store.userRedux.sponsorProfiles;

export const selectorGetBrokerProfiles =
  (store: RootState): BrokerProfilesType =>
    store.userRedux.brokerProfiles;

export const selectorGetUserSettings =
  (store: RootState): UserSettingsType =>
    store.userRedux.userSettings;

import {useState, useEffect} from 'react';
import RadioGroup from 'shared/components/RadioGroup';
import NextButton from 'shared/components/NextButton';
import {LoanTermSubsteps, QuoteTermsSteps, storageKey, yesNoOptions} from 'core/_consts';
import {setAnyExtensions} from 'store/quote-terms-service/quoteTermsSlice';
import {useSelector} from 'react-redux';
import {selectAnyExtensions} from 'store/quote-terms-service/quoteTermsSelector';
import {vaultService} from 'core/service/storage';
import {nextButtonClickHandler, NextButtonRefType} from 'pages/LenderView/helpers';

const handleSelectedValue = (value: string) =>
  value === yesNoOptions.yes
    ? {
        step: QuoteTermsSteps[QuoteTermsSteps.loanTerm],
        substep: LoanTermSubsteps[LoanTermSubsteps.extensionsAmount],
      }
    : {
        step: QuoteTermsSteps[QuoteTermsSteps.loanTerm],
        substep: LoanTermSubsteps[LoanTermSubsteps.lengthAndFee],
      };

const AnyExtensions = () => {
  const defaultValue = useSelector(selectAnyExtensions);
  const [isAnyExtensions, setIsAnyExtensions] = useState<Array<string>>(defaultValue);
  const [nextButtonRef, setNextButtonRef] = useState<NextButtonRefType>(null);

  useEffect(() => {
    nextButtonClickHandler(nextButtonRef);
  }, [isAnyExtensions]);

  return (
    <div className="FormSlide">
      <p className="FormSlide__title mb35">Are there any extensions?</p>
      <RadioGroup
        isAnyValueSelected={isAnyExtensions}
        setIsAnyValueSelected={setIsAnyExtensions}
        radioButtonTitles={Object.values(yesNoOptions)}
        nextButtonTrigger={() => nextButtonClickHandler(nextButtonRef)}
      />
      <NextButton
        isHidden={!defaultValue.length}
        sideEffect={() => {
          if (isAnyExtensions[0] === yesNoOptions.no)
            vaultService.removeItem(storageKey.quoteTermsData, LoanTermSubsteps[LoanTermSubsteps.extensionsAmount]);
        }}
        setNextButtonRef={setNextButtonRef}
        isDisabled={!isAnyExtensions.length}
        slideData={{[LoanTermSubsteps[LoanTermSubsteps.anyExtensions]]: isAnyExtensions}}
        action={setAnyExtensions}
        nextSlide={handleSelectedValue(isAnyExtensions[0])}
      />
    </div>
  );
};

export default AnyExtensions;

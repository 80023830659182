export enum THEMES {
  white = 'white',
  lightBlue = '#b3d5f8',
  blue = '#5999d8',
  darkBlue = '#0073e6',
  grey = '#848484',
}

export enum BORDERS {
  SolidWhite1px = '1px solid white',
}

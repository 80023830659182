import React, {FC, useMemo} from 'react';
import cx from 'classnames';
import {Ava} from '@burnaby/ava';
import {useSelector} from 'react-redux';
import {selectorGetProject} from 'store/project-service/selector';
import AccordionWrapper from 'shared/components/Accordion';
import s from './SourcesAndUses.module.scss';
import {IsMobileViewProps} from '../helpers';

// FIXME: replace 'any' type
const ZERO_USD = (Ava as any).CurrencyValue.fromNumber((Ava as any).CurrencyUnit.USD, 0);
const sourcesHead = ['Sources', '$', '%'];
const usesHead = ['Uses', '$', '%'];

const SourcesAndUses: FC<IsMobileViewProps> = ({isMobileView}) => {
  const project = useSelector(selectorGetProject);
  const sources: Array<any> = useMemo(() => Array.from(project.capStack?.sourceList), [project]);
  const uses: Array<any> = useMemo(() => Array.from(project.capStack?.useList), [project]);
  const totalSources = useMemo(
    () => sources && sources.reduce((acc, source) => acc.add(source.principal), ZERO_USD),
    [sources]
  );
  const totalUses = useMemo(() => uses && uses.reduce((acc, use) => acc.add(use.principal), ZERO_USD), [uses]);
  const shouldSectionBeRendered = sources.length || uses.length;

  const content = (
    <div className={cx(s.sourcesAndUses, 'LenderView__section')}>
      {!isMobileView && <p className="LenderView__infoContentTitle">Sources & Uses</p>}
      <div className={s.sourcesAndUses__container}>
        <div className={s.sourcesAndUses__tables}>
          {!!sources.length && (
            <div className={s.table}>
              <div className={s.table__headers}>
                {sourcesHead.map((item, index) => (
                  <div className={index === 0 ? s.table__title : s.table__cell} key={item}>
                    {item}
                  </div>
                ))}
              </div>
              {sources.map((source) => (
                <React.Fragment key={source.formatType()}>
                  {!source.principal.isZero() && (
                    <div className={s.table__row}>
                      <div className={cx(s.table__cell, s['table__cell-highlighted'])}>{source.formatType()}</div>
                      <div className={cx(s.table__cell, s['table__cell-bolded'])}>
                        {source.principal.formatNoDecimal()}
                      </div>
                      <div className={cx(s.table__cell, s['table__cell-bolded'])}>
                        {source.principal.divide(totalSources, ZERO_USD).formatMaxDecimalPlaces(1)}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
              {totalSources && (
                <div className={cx(s['table__cell-total'], s['table__cell-highlighted'])}>
                  <p>Total Sources</p>
                  <p className={s.table__sum}>{totalSources.formatNoDecimal()}</p>
                </div>
              )}
            </div>
          )}
          {!!uses.length && (
            <div className={s.table}>
              <div className={s.table__headers}>
                {usesHead.map((item, index) => (
                  <div className={index === 0 ? s.table__title : s.table__cell} key={item}>
                    {item}
                  </div>
                ))}
              </div>
              {uses.map((item) => (
                <React.Fragment key={item.use}>
                  {!item.principal.isZero() && (
                    <div className={s.table__row}>
                      <div className={cx(s.table__cell, s['table__cell-highlighted'])}>{item.use}</div>
                      <div className={cx(s.table__cell, s['table__cell-bolded'])}>
                        {item.principal.formatNoDecimal()}
                      </div>
                      <div className={cx(s.table__cell, s['table__cell-bolded'])}>
                        {item.principal.divide(totalUses, ZERO_USD).formatMaxDecimalPlaces(1)}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
              {totalUses && (
                <div className={cx(s['table__cell-total'], s['table__cell-highlighted'])}>
                  <p>Total Uses</p>
                  <p className={s.table__sum}>{totalUses.formatNoDecimal()}</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  if (!shouldSectionBeRendered) return null;
  if (isMobileView) return <AccordionWrapper title="Sources & Uses">{content}</AccordionWrapper>;

  return content;
};

export default SourcesAndUses;

import React, {FC, useEffect, useState} from 'react';
import cx from 'classnames';
import {useSelector} from 'react-redux';
import {selectorGetProject} from 'store/project-service/selector';
import AccordionWrapper from 'shared/components/Accordion';
import s from './Guarantor.module.scss';
import {IsMobileViewProps} from '../helpers';

const Guarantor: FC<IsMobileViewProps> = ({isMobileView}) => {
  const project = useSelector(selectorGetProject);
  const [guarantors, setGuarantors] = useState<Array<any>>([]);

  useEffect(() => {
    if (Object.getOwnPropertyNames(project).length) setGuarantors(Array.from(project.guarantorList));
  }, [project]);

  const content = (
    <div className={cx(s.guarantor, 'LenderView__section')}>
      {!isMobileView && <p className="LenderView__infoContentTitle">Guarantor</p>}
      {guarantors.map((item: any) => (
        <React.Fragment key={item.name}>
          <p>{item.name}</p>
          {/* TODO: ask for more details about this info */}
          <p>Secondary information</p>
        </React.Fragment>
      ))}
    </div>
  );

  if (!guarantors.length) return null;
  if (isMobileView) return <AccordionWrapper title="Guarantor">{content}</AccordionWrapper>;

  return content;
};

export default Guarantor;

import {RequiredReservesSubsteps} from 'core/_consts';
import {FC, useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import ReservesDetails from './ReservesDetails';
import SelectRequiredReserves from './SelectRequiredReserves';

type RequiredReservesSubstepsKeys = keyof typeof RequiredReservesSubsteps;

const RequiredReserves: FC = () => {
  const location = useLocation();
  const [substep, setSubstep] = useState<RequiredReservesSubstepsKeys | null>();

  useEffect(() => {
    setSubstep(new URLSearchParams(location.search).get('substep') as RequiredReservesSubstepsKeys);
  }, [location.search]);

  switch (substep) {
    case RequiredReservesSubsteps[RequiredReservesSubsteps.selectRequiredReserves]:
      return <SelectRequiredReserves />;

    case RequiredReservesSubsteps[RequiredReservesSubsteps.reservesDetails]:
      return <ReservesDetails />;

    default:
      return null;
  }
};

export default RequiredReserves;

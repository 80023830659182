import {createGoogleMapsLink} from 'core/utils';
import {googleMapsBaseUrl} from 'core/_consts';
import {FC, useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';

import s from './ProjectNameHeader.module.scss';

type ProjectNameHeaderProps = {
  project: any;
};

const ProjectNameHeader: FC<ProjectNameHeaderProps> = ({project}) => {
  const location = useLocation();
  const [adressLink, setAdressLink] = useState('');

  useEffect(() => {
    if (project.address) setAdressLink(createGoogleMapsLink(project.address));
  }, [project]);

  return (
    <div className={s.projectNameHeader}>
      {!!Object.getOwnPropertyNames(project).length && (
        <>
          <p className={s.projectNameHeader__name}>{`${project.name || project.address.line1}`}</p>
          <div className={s.projectNameHeader__address}>
            <p>
              <span>{project.name !== project.address.line1 && `${project.address.line1}`},&nbsp;</span>
              <span>{`${project.address.formatCityAndState()}`}</span>
            </p>
            <a href={`${googleMapsBaseUrl + adressLink}`} target="_blank" className="link fs18 fw600" rel="noreferrer">
              View on map
            </a>
            {/* location.pathname.includes('view') ? (
              <Link to={`/deal:${project.sym}/underwriting`} className="link fs18 fw600" rel="noreferrer">
                Back to project
              </Link>
            ) : (
              <Link to={`/deal:${project.sym}/view`} className="link fs18 fw600" rel="noreferrer">
                View as lender
              </Link>
            ) */}
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectNameHeader;

import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {CapitalProvider, Deal, Person, ShareDeclined} from '@burnaby/ava';

import {ShareType} from 'core/_types/base';

import {createShare, declineShare, getShares, generateCopyLink, undeclineShare} from './asyncActions';

const initialState: any = {
  shares: [],
  loading: false,
};

const sharesSlice = createSlice({
  name: 'shares',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<{shareSym: string; isLoading: boolean}>) {
      const share = state.shares.find((s: ShareType) => s.share.sym === action.payload.shareSym);
      share.isLoading = action.payload.isLoading;
    },
    setShareContacts(state, action: PayloadAction<{shareSym: string; contacts: Person[]}>) {
      const {shareSym, contacts} = action.payload;
      const share = state.shares.find((s: ShareType) => s.share.sym === shareSym);
      share.contacts = contacts;
      share.isLoading = false;
    },
    setShareProvider(state, action: PayloadAction<{shareSym: string; provider: CapitalProvider}>) {
      const {shareSym, provider} = action.payload;
      const share = state.shares.find((s: ShareType) => s.share.sym === shareSym);
      share.provider = provider;
      share.isLoading = false;
    },
    setIsLinkCopied(state, action: PayloadAction<boolean>) {
      state.isLinkCopied = action.payload;
    },
    setShares(state, action: PayloadAction<Array<ShareType>>) {
      state.shares = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShares.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getShares.fulfilled, (state, action: PayloadAction<Array<ShareType>>) => {
      state.shares = action.payload;
      state.loading = false;
    });
    builder.addCase(generateCopyLink.fulfilled, (state, action: PayloadAction<any>) => {
      state.shares = state.shares.map((share: ShareType) => {
        if (share.share.sym === action.payload.shareSym) {
          share.isLinkCopied = true;
        } else {
          share.isLinkCopied = false;
        }
        return share;
      });
    });
  },
});

export const {setShareContacts, setShareProvider, setIsLinkCopied, setLoading, setShares} = sharesSlice.actions;

export default sharesSlice.reducer;

import {FC, useEffect} from 'react';
import {Form, FormikHandlers, FormikHelpers, FormikValues} from 'formik';
import {Grid, SelectChangeEvent} from '@mui/material';

// Shared
import {StepSelect} from 'shared/components/StepSelect';
import {THEMES} from 'shared/helpers';
import NumericInput from 'shared/components/NumericInput';
import QuestionMarkIcon from 'shared/components/QuestionMarkIcon';
import TextArea from 'shared/components/TextArea';

// Core
import {acquisitionStatusOptions} from 'core/utils/selectOptions/acquisitionStatusOptions';
import {dateRange, MaxValidationLimits} from 'core/_consts';
import {FinancialInfoType} from 'core/_types/UnderwritingTypes';
import {statusMessage} from 'core/enums/statusMessage';
import {AcquisitionStatus} from 'core/enums/acquisitionStatus';
import {investmentCategoryOptions} from 'core/utils/selectOptions/investmentCategoryOptions';
import {InvestmentCategory} from 'core/enums/investmentCategory';
import useStatusMessage from 'core/utils/hooks/useStatusMessage';
import {dateChecker} from 'core/helpers/dateChecker';

// Store
import {useDispatch, useSelector} from 'react-redux';
import {updateFinancialInfo} from 'store/project-service/asyncActions';
import {setUpdatedFinancialInfo} from 'store/project-service/projectSlice';
import {selectorGetProject, selectorGetUpdate} from 'store/project-service/selector';

// Styles
import s from './FinancialInfo.module.scss';

// const requestOptions = [
//   {value: 'exactValue', label: 'Exact Value', default: true},
//   {value: 'range', label: 'Range', default: false},
// ];
//
// const finInstrumentOptions = [
//   {value: 'senior', label: 'Senior Debt', default: true},
//   {value: 'mezzanine', label: 'Mezzanine Debt', default: false},
// ];
//
// const useRadioStyles = makeStyles({
//   root: {
//     color: '#5c6166',
//     '&.Mui-checked': {
//       color: '#2d9875',
//     },
//   },
// });
//
// const useSliderStyles = makeStyles({
//   colorPrimary: {
//     color: '#2d9875',
//   },
// });
//
// const ContactControlLabel = styled(FormControlLabel)({
//   font: '500 14px "Source Sans Pro"',
//   '.MuiFormControlLabel-label': {
//     font: '500 14px "Source Sans Pro"',
//   },
// });

type FinancialInfoFormProps = {
  values: FinancialInfoType;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: Pick<FormikHelpers<FormikValues>, 'setFieldValue'>['setFieldValue'];
  initialValues: FinancialInfoType;
  valuationPerUnit: string;
  valuationPerUnitValue: string;
  ltv: string;
  ltc: string;
  debtYield: string;
  loanTermRange: string;
};

const FinancialInfoForm: FC<FinancialInfoFormProps> = ({
  values,
  handleChange,
  setFieldValue,
  initialValues,
  valuationPerUnit,
  valuationPerUnitValue,
  ltv,
  ltc,
  debtYield,
  loanTermRange,
}) => {
  // const radioClasses = useRadioStyles();
  // const sliderClasses = useSliderStyles();

  const dispatch = useDispatch();

  const project = useSelector(selectorGetProject);
  const {financialInfo} = useSelector(selectorGetUpdate);
  const message = useStatusMessage(financialInfo);

  useEffect(() => {
    if (values !== initialValues) {
      dispatch(updateFinancialInfo({sym: project.sym, fields: values, dispatch}));
      dispatch(setUpdatedFinancialInfo(statusMessage.saving));
    }
  }, [values]);

  useEffect(
    () => () => {
      dispatch(setUpdatedFinancialInfo(''));
    },
    []
  );

  return (
    <Form>
      <p className={s.Financial__groupTitle}>Income & Valuation</p>
      <Grid container spacing={2.5} rowSpacing={3} marginBottom="24px">
        <Grid item xs={6}>
          <StepSelect
            name="investmentCategory"
            options={investmentCategoryOptions}
            theme={THEMES.blue}
            label="Investment Category"
            value={values.investmentCategory.label}
            handleChange={(e?: SelectChangeEvent<any>) =>
              e &&
              setFieldValue('investmentCategory', {
                value: InvestmentCategory[e.target.value.toUpperCase().replace(' ', '_')],
                label: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="acquisitionPrice" className={s.Financial__label}>
            Acquisition Price
          </label>
          <NumericInput
            className={s.Financial__mobile}
            id="acquisitionPrice"
            name="acquisitionPrice"
            onValueChange={handleChange}
            value={values.acquisitionPrice}
            theme={THEMES.grey}
            preventionLimit={MaxValidationLimits.highestLimit}
            placeholder="$"
            textAlign="left"
            thousandSeparator
          />
        </Grid>
        <Grid container item display="flex" direction="column" xs={6}>
          <label htmlFor="acquisitionPicker" className={s.Financial__label}>
            Acquisition Date
          </label>
          <input
            max={dateRange.maxDate}
            min={dateRange.minDate}
            id="acquisitionPicker"
            type="date"
            name="acquisitionDate"
            value={values.acquisitionDate}
            onChange={handleChange}
            className={s.Financial__datePicker}
          />
          <div className={s.Financial__dateError}>{dateChecker(values.acquisitionDate)}</div>
        </Grid>
        <Grid item xs={6}>
          <StepSelect
            name="acquisitionStatus"
            value={values.acquisitionStatus.label}
            handleChange={(e?: SelectChangeEvent<any>) =>
              e &&
              setFieldValue('acquisitionStatus', {
                value: AcquisitionStatus[e.target.value.toUpperCase().replace(' ', '_')],
                label: e.target.value,
              })
            }
            options={acquisitionStatusOptions}
            theme={THEMES.blue}
            label="Acquisition Status"
          />
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="latestAppraisalValue" className={s.Financial__label}>
            Latest Appraisal Value
          </label>
          <NumericInput
            className={s.Financial__mobile}
            id="latestAppraisalValue"
            name="latestAppraisalValue"
            onValueChange={handleChange}
            value={values.latestAppraisalValue}
            theme={THEMES.grey}
            preventionLimit={MaxValidationLimits.highestLimit}
            placeholder="$"
            textAlign="left"
            thousandSeparator
          />
        </Grid>
        <Grid container item display="flex" direction="column" xs={6}>
          <label htmlFor="latestAppraisalDatePicker" className={s.Financial__label}>
            Latest Appraisal Date
          </label>
          <input
            max={dateRange.maxDate}
            min={dateRange.minDate}
            id="latestAppraisalDatePicker"
            name="latestAppraisalDate"
            onChange={handleChange}
            value={values.latestAppraisalDate}
            type="date"
            className={s.Financial__datePicker}
          />
          <div className={s.Financial__dateError}>{dateChecker(values.latestAppraisalDate)}</div>
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="currentNetOperatingIncome" className={s.Financial__label}>
            Current Net Operating Income <QuestionMarkIcon />
          </label>
          <NumericInput
            className={s.Financial__mobile}
            id="currentNetOperatingIncome"
            name="currentNetOperatingIncome"
            onValueChange={handleChange}
            value={values.currentNetOperatingIncome}
            theme={THEMES.grey}
            preventionLimit={MaxValidationLimits.highestLimit}
            placeholder="$"
            textAlign="left"
            thousandSeparator
          />
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="proFormaNetOperatingIncome" className={s.Financial__label}>
            Pro Forma Net Operating Income <QuestionMarkIcon />
          </label>
          <NumericInput
            className={s.Financial__mobile}
            id="proFormaNetOperatingIncome"
            name="proFormaNetOperatingIncome"
            onValueChange={handleChange}
            value={values.proFormaNetOperatingIncome}
            theme={THEMES.grey}
            preventionLimit={MaxValidationLimits.highestLimit}
            placeholder="$"
            textAlign="left"
            thousandSeparator
          />
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="capRate" className={s.Financial__label}>
            Cap Rate
          </label>
          <NumericInput
            id="capRate"
            name="capRate"
            value={values.capRate}
            onValueChange={handleChange}
            theme={THEMES.grey}
            thousandSeparator
            width="282px"
            preventionLimit={MaxValidationLimits.percent}
            placeholder="%"
            textAlign="left"
          />
        </Grid>
        <Grid item xs={6}>
          <p className={s.Financial__label}>Cap Rate Valuation</p>
          <p>{project.capRateValuation.isZero() ? '-' : project.capRateValuation.format()}</p>
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="proFormaCapRate" className={s.Financial__label}>
            Pro Forma Cap Rate
          </label>
          <NumericInput
            id="proFormaCapRate"
            name="proFormaCapRate"
            value={values.proFormaCapRate}
            onValueChange={handleChange}
            theme={THEMES.grey}
            thousandSeparator
            width="282px"
            preventionLimit={MaxValidationLimits.percent}
            placeholder="%"
            textAlign="left"
          />
        </Grid>
        <Grid item xs={6}>
          <p className={s.Financial__label}>Pro Forma Cap Rate Valuation</p>
          <p>{project.stabilizedCapRateValuation.isZero() ? '-' : project.stabilizedCapRateValuation.format()}</p>
        </Grid>
        <Grid item xs={6}>
          <p className={s.Financial__label}>Value Per Unit</p>
          <p className={s.Financial__postLabel}>
            {valuationPerUnitValue} {valuationPerUnit}
          </p>
        </Grid>
        {/* <Grid item xs={6}>
          <label htmlFor="totalProjectBudget" className={s.Financial__label}>
            Total Project Budget
          </label>
          <NumericInput
            className={s.Financial__mobile}
            id="totalProjectBudget"
            name="totalProjectBudget"
            value={values.totalProjectBudget}
            onValueChange={handleChange}
            theme={THEMES.grey}
            thousandSeparator
            preventionLimit={MaxValidationLimits.highestLimit}
            placeholder="$"
            textAlign="left"
          />
        </Grid> */}
        <Grid item xs={12}>
          <div className={s.Financial__label}>
            Income & Valuation Notes <QuestionMarkIcon />
          </div>
          <TextArea
            width="100%"
            height="126px"
            name="financialNotes"
            value={values.financialNotes}
            onValueChange={handleChange}
            theme={THEMES.blue}
            resize="vertical"
            placeholder="Explain any uncommon elements of the deal's underwriting."
          />
        </Grid>
      </Grid>
      <p className={s.Financial__groupTitle}>Financing Expectations</p>
      <Grid container spacing={2.5} rowSpacing={3}>
        {/* <FieldArray name="investments">
        {({remove, push}) => (
          <Grid container spacing={2.5} rowSpacing={3} marginBottom="24px">
            {values.investments.length > 0 &&
              values.investments.map((category: any, index: number) => (
                <Grid item container spacing={2.5} key={category.id}>
                  <Grid item xs={5}>
                    <StepSelect
                      options={[]}
                      theme={THEMES.blue}
                      name={`investments.${index}.type`}
                      value={values.investments[index].type}
                      handleChange={handleChange}
                      label="Investment Category"
                    />
                  </Grid>
                  <Grid item xs={3} marginTop="23px">
                    <NumericInput
                      name={`investments.${index}.amount`}
                      onValueChange={handleChange}
                      value={values.investments[index].amount}
                      theme={THEMES.grey}
                      thousandSeparator
                      fullWidth
                      preventionLimit={MaxValidationLimits.highestLimit}
                      placeholder="$"
                      textAlign="left"
                    />
                  </Grid>
                  <Grid item xs={4} marginTop="23px">
                    <TextInput
                      name={`investments.${index}.entity`}
                      value={values.investments[index].entity}
                      onValueChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              ))}
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <button
                className={s.Financial__groupBtn}
                type="button"
                onClick={() => remove(values.investments.length - 1)}
              >
                Delete item
              </button>
              <button
                className={s.Financial__groupBtn}
                type="button"
                onClick={() => push({id: values.investments.length + 1, type: '', amount: '', entity: ''})}
              >
                Add an item
              </button>
            </Grid>
          </Grid>
        )}
      </FieldArray>
      <p className={s.Financial__groupTitle}>Financing Request Details</p>
      <Grid container spacing={2.5} rowSpacing={3} marginBottom="24px">
        <Grid item xs={6}>
          <p className={s.Financial__label}>Loan Amount Needed*</p>
          <Box display="flex" alignItems="center">
            {values.capitalizationType === 'range' ? (
              <>
                <NumericInput
                  name="loanAmountFrom"
                  value={values.loanAmountFrom}
                  onValueChange={handleChange}
                  theme={THEMES.grey}
                  fullWidth
                  preventionLimit={MaxValidationLimits.highestLimit}
                  textAlign="left"
                  placeholder="$"
                  thousandSeparator
                />
                <span className={s.Financial__amountDivider}>-</span>
                <NumericInput
                  name="loanAmountTo"
                  value={values.loanAmountTo}
                  onValueChange={handleChange}
                  theme={THEMES.grey}
                  fullWidth
                  preventionLimit={MaxValidationLimits.highestLimit}
                  textAlign="left"
                  placeholder="$"
                  thousandSeparator
                />
              </>
            ) : (
              <NumericInput
                name="principalNeeded"
                value={values.principalNeeded}
                onValueChange={handleChange}
                theme={THEMES.grey}
                width="280px"
                preventionLimit={MaxValidationLimits.highestLimit}
                textAlign="left"
                placeholder="$"
                thousandSeparator
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <RadioGroup row name="capitalizationType" value={values.capitalizationType} onChange={handleChange}>
            <Grid container direction="row" alignItems="center" marginTop="23px">
              {requestOptions.map((option) => (
                <ContactControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio classes={radioClasses} />}
                  label={option.label}
                />
              ))}
            </Grid>
          </RadioGroup>
        </Grid>
        <Grid item xs={6}>
          <p className={s.Financial__label}>LTV</p>
          <p>{ltv}</p>
        </Grid>
        <Grid item xs={6}>
          <p className={s.Financial__label}>LTC</p>
          <p>{ltc}</p>
        </Grid>
        <Grid item xs={6}>
          <div className={s.Financial__label}>
            Financial Instrument* <QuestionMarkIcon />
          </div>
          <RadioGroup row name="financialInstrument" value={values.financialInstrument} onChange={handleChange}>
            <Grid container direction="row" alignItems="center">
              {finInstrumentOptions.map((option) => (
                <ContactControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio classes={radioClasses} />}
                  label={option.label}
                />
              ))}
            </Grid>
          </RadioGroup>
        </Grid>
        <Grid item xs={6}>
          <p className={s.Financial__label}>Debt Yield</p>
          <p>{debtYield}</p>
        </Grid>
        <Grid item xs={6}>
          <StepSelect
            name="loanTerm"
            value={values.loanTerm}
            handleChange={handleChange}
            options={[]}
            theme={THEMES.blue}
            label="Loan Term*"
          />
        </Grid>
        <Grid item xs={6}>
          <StepSelect
            name="amortization"
            value={values.amortization.toString()}
            options={Object.values(ExpectedRepaymentSchedule)}
            handleChange={handleChange}
            theme={THEMES.blue}
            label="Amortization"
          />
        </Grid>
        <Grid item xs={6}>
          <Box sx={{width: '100%'}}>
            <Slider
              classes={sliderClasses}
              size="small"
              max={12}
              min={1}
              name="capitalizationPeriod"
              value={values.capitalizationPeriod}
              onChange={handleChange}
            />
          </Box>
          <p>{loanTermRange}</p>
        </Grid> */}
        <Grid item xs={12}>
          <TextArea
            width="100%"
            height="126px"
            name="financialExpectations"
            value={values.financialExpectations}
            onValueChange={handleChange}
            theme={THEMES.blue}
            resize="vertical"
            placeholder="What do you expect from a financial partner? Consider term, amortization, recourse, or other elements that are required or preferred."
          />
        </Grid>
        {!!message.length && (
          <Grid item xs={12} sx={{fontStyle: 'italic', color: '#757575'}}>
            <p>{message}</p>
          </Grid>
        )}
      </Grid>
    </Form>
  );
};

export default FinancialInfoForm;

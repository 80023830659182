import {FC, useEffect, useState} from 'react';
import {Formik} from 'formik';

import {Use} from 'core/_types/selectOptionsTypes/UsesType';

import UsesForm from './UsesForm';

type UsesProps = {
  uses: Array<Use>;
};

export const Uses: FC<UsesProps> = ({uses}) => {
  const initialValues: UsesProps = {
    uses,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        // eslint-disable-next-line no-alert
        alert(JSON.stringify(values));
      }}
    >
      {(props) => (
        <UsesForm
          values={props.values}
          handleChange={props.handleChange}
          setFieldValue={props.setFieldValue}
          initialValues={initialValues}
        />
      )}
    </Formik>
  );
};

import {ChangeEvent, FC, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormControlLabel, FormControl, MenuItem, RadioGroup, Radio, Select, SelectChangeEvent} from '@mui/material';

import {UserProfile, SymOrString, ava} from 'core/service/ava';
import {TeamMemberData} from 'core/_types/base';

// Shared
import TextInput from 'shared/components/TextInput';
import BasicButton from 'shared/components/BasicButton';

// Store
import {inviteAdvisorUser, inviteSponsorUser} from 'store/project-service/asyncActions';
import {selectorGetProject} from 'store/project-service/selector';

// Styles
import s from './NewUser.module.scss';

type NewUserProps = {
  handleClose: () => void;
  setSponsorSymOrEmails?: React.Dispatch<React.SetStateAction<SymOrString[]>>;
  sponsors?: TeamMemberData[];
  setCapitalAdvisorSyms?: React.Dispatch<React.SetStateAction<string[]>>;
  capitalAdvisors?: TeamMemberData[];
};

enum UserType {
  SPONSOR = 0,
  SALES_AGENT = 1,
  CAPITAL_ADVISOR = 2,
}

const allAdvisors: Map<string, UserProfile> = new Map();
let allAdvisorsInitialized = false;

const NewUser: FC<NewUserProps> = ({
  handleClose,
  setSponsorSymOrEmails,
  sponsors,
  setCapitalAdvisorSyms,
  capitalAdvisors,
}) => {
  const dispatch = useDispatch();

  const project = useSelector(selectorGetProject);
  // TODO(nathan): Use a selector for this?
  if (!allAdvisorsInitialized) {
    allAdvisorsInitialized = true;
    ava.userProfiles.getAllAdvisors().then((advisors) => {
      for (let i = 0; i < advisors.length; i += 1) {
        const advisor = advisors[i];
        allAdvisors.set(advisor.sym, advisor);
      }
    });
  }

  const [userEmail, setUserEmail] = useState('');
  const [userType, setUserType] = useState<UserType>(UserType.SPONSOR);
  const [advisorSym, setAdvisorSym] = useState('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserType(+e.target.value);
  };

  const inviteUser = (): void => {
    if (userType === UserType.SPONSOR) {
      dispatch(inviteSponsorUser({sym: project.sym, email: userEmail}));
      handleClose();
      if (sponsors && setSponsorSymOrEmails) {
        const newSponsors = sponsors.map(
          u => u.sym
            ? new SymOrString().setSym(u.sym)
            : new SymOrString().setString(u.email),
        );
        newSponsors.push(new SymOrString().setString(userEmail));
        setSponsorSymOrEmails(newSponsors);
      }
    }
    if (userType === UserType.CAPITAL_ADVISOR) {
      dispatch(inviteAdvisorUser({dealSym: project.sym, advisorSym}));
      handleClose();
      if (capitalAdvisors && setCapitalAdvisorSyms) {
        const newAdvisors = capitalAdvisors.map((item) => item.sym);
        newAdvisors.push(advisorSym);
        setCapitalAdvisorSyms(newAdvisors);
      }
    }
  };

  const labels = ['Sponsor Email', 'Sales Agent Email', 'Capital Advisor'];

  return (
    <div className={s.newUser}>
      <FormControl>
        <RadioGroup
          className={s.newUser__radioGroup}
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={userType}
          onChange={handleChange}
        >
          <FormControlLabel
            value={UserType.SPONSOR}
            control={<Radio />}
            label={<p className={s.newUser__radioLabel}>Sponsor</p>}
          />
          {/* <FormControlLabel
            value={UserType.SALES_AGENT}
            control={<Radio />}
            label={<p className={s.newUser__radioLabel}>Sales Agent</p>}
          /> */}
          <FormControlLabel
            value={UserType.CAPITAL_ADVISOR}
            control={<Radio />}
            label={<p className={s.newUser__radioLabel}>Capital Advisor</p>}
          />
        </RadioGroup>
      </FormControl>
      {userType === UserType.SPONSOR ? (
        <TextInput
          label={labels[userType]}
          name="email"
          type="text"
          value={userEmail}
          onValueChange={(e) => setUserEmail(e.target.value)}
          width="380px"
        />
      ) : (
        <Select
          displayEmpty
          defaultValue=""
          renderValue={(sym: string) => allAdvisors.get(sym)?.name || ''}
          value={advisorSym}
          onChange={(event: SelectChangeEvent) => setAdvisorSym(event.target.value)}
        >
          {Array.from(allAdvisors.values())
            .sort((a, b) => (a.lastName < b.lastName ? -1 : 1))
            .map((advisor) => (
              <MenuItem key={advisor.sym} value={advisor.sym}>
                {advisor.name}
              </MenuItem>
            ))}
        </Select>
      )}
      <footer className={s.footer}>
        <BasicButton className="blue" type="submit" width="150px" onClick={inviteUser}>
          Save
        </BasicButton>
        <BasicButton className="white" onClick={handleClose} width="120px">
          Cancel
        </BasicButton>
      </footer>
    </div>
  );
};

export default NewUser;

import {Tooltip} from '@mui/material';
import {withStyles} from '@mui/styles';

const BasicTooltip = withStyles({
  tooltip: {
    color: '#757575',
    backgroundColor: 'white',
    border: '1px solid #b3d5f8',
    boxShadow: '1px 1px 3px 0 rgba(0,0,0,0.2)',
    fontSize: '12px',
    lineHeight: '15px',
    width: '214px',
    height: 'max-content',
    padding: '20px 15px 20px 19px',
  },
  arrow: {
    color: 'white',
    '&::before': {
      border: '1px solid #b3d5f8',
    },
  },
})(Tooltip);

export default BasicTooltip;

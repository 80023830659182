import {FC} from 'react';
import {useDispatch} from 'react-redux';

// Store
import {createShare} from 'store/shares-service/asyncActions';

// Components
import StarButton from 'shared/components/StarButton';
import {AvaType} from 'store/ava-service/interfaces';

import s from './CapitalSources.module.scss';

export enum ShareStatus {
  OFFER_ACCEPTED,
  QUOTE,
  VIEWED,
  UNOPENED,
  UNSENT,
  DECLINED,
  AUTO_DECLINED,
}

type CapitalSourcesItemProps = {
  ava: AvaType;
  name: string;
  projectSym: string;
  providerSym: string;
  type?: string;
  isSaved?: boolean;
  isMatched?: boolean;
  invited?: boolean;
  shareSyms: Array<string>;
  setShareSyms: (shareSyms: Array<string>) => void;
  status?: ShareStatus;
  views?: number;
};

const CapitalSourcesItem: FC<CapitalSourcesItemProps> = ({
  ava,
  name,
  projectSym,
  providerSym,
  type,
  isSaved,
  isMatched,
  invited,
  shareSyms,
  setShareSyms,
  status,
  views,
}) => {
  const dispatch = useDispatch();

  const handleProviderSave = () => {
    dispatch(createShare({ava, projectSym, providerSym, dispatch}));
  };

  const handleShareSelect = (sym: string) => {
    if (shareSyms.includes(sym)) {
      setShareSyms(shareSyms.filter((shareSym) => shareSym !== sym));
    } else {
      setShareSyms([...shareSyms, sym]);
    }
  };

  return (
    <>
      {isSaved && !isMatched ? (
        <div className={s.capitalSources__item}>
          <input
            type="checkbox"
            name=""
            id=""
            checked={shareSyms.includes(providerSym)}
            className={s.capitalSources__selected}
            onClick={(e) => e.stopPropagation()}
            onChange={() => handleShareSelect(providerSym)}
          />
          <p className={s.capitalSources__name}>{name}</p>
          <small className={s.capitalSources__type}>
            <i> {type} </i>
          </small>
          <small className={s.capitalSources__status}>
            <i> {formatStatus(status, views)} </i>
          </small>
        </div>
      ) : (
        <div className={s.capitalSources__item}>
          <StarButton handleClick={handleProviderSave} />
          <p className={s.capitalSources__name}>{name}</p>
          <small className={s.capitalSources__type}>
            <i> {type} </i>
          </small>
        </div>
      )}
    </>
  );
};

function formatStatus(status = ShareStatus.UNSENT, views = 0) {
  switch (status) {
    case ShareStatus.OFFER_ACCEPTED:
      return 'Accepted';
    case ShareStatus.DECLINED:
      return 'Declined';
    case ShareStatus.AUTO_DECLINED:
      return 'Auto-Declined';
    case ShareStatus.QUOTE:
      return 'Quote';
    case ShareStatus.VIEWED:
      return `${views} View${views > 1 ? 's' : ''}`;
    case ShareStatus.UNOPENED:
      return 'Unopened';
    case ShareStatus.UNSENT:
      return 'Unsent';
    default:
      return '';
  }
}

export default CapitalSourcesItem;

import {ChangeEvent, FC, useEffect, useState} from 'react';
import cx from 'classnames';

import {Grid, IconButton} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';

// Components
import {StepSelect} from 'shared/components/StepSelect';
import {THEMES} from 'shared/helpers';
import {ModalTypes} from 'pages/ProjectView/helpers';
import ModalWin from 'shared/components/Modal/ModalWin';
import CheckboxButton from 'shared/components/CheckboxButton';
import BasicButton from 'shared/components/BasicButton';

import {searchBarFilters} from 'core/helpers/searchBarFilters';

import s from './SearchBar.module.scss';

enum Filters {
  'OPEN',
  'WON',
  'LOST',
}

type SearchBarProps = {
  handleSearch: (filters: any) => void;
  reset: boolean;
};

const SearchBar: FC<SearchBarProps> = ({handleSearch, reset}) => {
  const [isOpen, setOpen] = useState(false);
  const [modal, setModal] = useState<ModalTypes>();
  const [filters, setFilters] = useState<Array<string>>([]);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    if (filters.length >= 0) {
      handleSearch({status: filters});
    }
  }, [filters]);

  const addFilters = (filter: string): void => {
    if (filters.includes(filter)) {
      const newFilters = filters.slice();
      newFilters.splice(filters.indexOf(filter), 1);
      setFilters(newFilters);
    } else {
      setFilters([...filters, filter]);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    setTimeout(() => {
      handleSearch({search: e.target.value});
    }, 300);
  };

  const handleReset = () => {
    setFilters([]);
    setSearchText('');
  };

  return (
    <div className={s.searchBar}>
      <ModalWin isOpen={isOpen} setOpen={setOpen} modal={modal} setSearchText={setSearchText} title="Filter & sort" />
      <div className={s.toolBar}>
        <div className={s.toolBar__header}>
          <h1>Projects</h1>
        </div>
        <div className={s.toolBar__nav}>
          {reset && (
            <BasicButton className="text" onClick={handleReset}>
              Reset
            </BasicButton>
          )}
        </div>
      </div>
      <Grid container spacing={2.5} rowSpacing={3} className={s.searchBar__container}>
        <Grid item xs={3} className={s.searchBar__group}>
          <div className={s.searchBar__search}>
            <label htmlFor="search" className={s.searchBar__label}>
              Search
            </label>
            <div className={s.searchBar__field}>
              <input
                value={searchText}
                name="search"
                className={s.searchBar__item}
                onChange={handleChange}
                autoComplete="off"
              />
              <SearchIcon className={s.searchBar__searchIcon} />
            </div>
          </div>
          <div className={s.searchBar__sideMenu}>
            <IconButton
              onClick={() => {
                setOpen(true);
                setModal(ModalTypes.dashboardSort);
              }}
            >
              <SortIcon />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={3} className={s.searchBar__btnGroup}>
          <div>
            <label htmlFor="choose" className={cx(s.searchBar__label, s.searchBar__hide)}>
              Status
            </label>
            <div className={cx(s.searchBar__buttons, s.searchBar__hide)}>
              <CheckboxButton
                text={searchBarFilters.open}
                handleClick={() => addFilters(Filters[Filters.OPEN])}
                isActive={filters.includes(Filters[Filters.OPEN])}
              />
              <CheckboxButton
                text={searchBarFilters.won}
                handleClick={() => addFilters(Filters[Filters.WON])}
                isActive={filters.includes(Filters[Filters.WON])}
              />
              <CheckboxButton
                text={searchBarFilters.lost}
                handleClick={() => addFilters(Filters[Filters.LOST])}
                isActive={filters.includes(Filters[Filters.LOST])}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={3} className={cx(s.searchBar__hide, s.searchBar__group)}>
          <StepSelect
            name="dealTeam"
            options={['Option 1', 'Option 2', 'Option 3']}
            theme={THEMES.blue}
            label="Deal Team"
            placeholder="Select"
          />
        </Grid>
        <Grid item xs={3} className={cx(s.searchBar__hide, s.searchBar__group)}>
          <StepSelect
            name="sortBy"
            options={['Option 1', 'Option 2', 'Option 3']}
            theme={THEMES.blue}
            label="Sort By"
            placeholder="Select"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SearchBar;

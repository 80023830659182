export enum SourceTypeValues {
  OTHER,
  COMMON_EQUITY,
  PREFERRED_EQUITY,
  MEZZANINE_DEBT,
  SENIOR_DEBT,
  LAND_VALUE,
  PACE_FINANCING,
  OPEN,
  GROUND_LEASE,
  TAX_CREDITS,
}

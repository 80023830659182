import {useEffect, useState} from 'react';
import RadioGroup from 'shared/components/RadioGroup';
import {yesNoOptions, QuoteTermsSteps, PrepayPenaltySubsteps} from 'core/_consts';
import NextButton from 'shared/components/NextButton';
import {setAnyLockout} from 'store/quote-terms-service/quoteTermsSlice';
import {selectAnyLockout} from 'store/quote-terms-service/quoteTermsSelector';
import {useSelector} from 'react-redux';
import {nextButtonClickHandler, NextButtonRefType} from 'pages/LenderView/helpers';

const handleSelectedValue = (value: string) =>
  value === yesNoOptions.no
    ? {
        step: QuoteTermsSteps[QuoteTermsSteps.initialDeposit],
      }
    : {
        step: QuoteTermsSteps[QuoteTermsSteps.prepayPenalty],
        substep: PrepayPenaltySubsteps[PrepayPenaltySubsteps.lockoutDuration],
      };

const AnyLockout = () => {
  const defaultValue = useSelector(selectAnyLockout);
  const [isAnyLockout, setIsAnyLockout] = useState<Array<string>>(defaultValue);
  const [nextButtonRef, setNextButtonRef] = useState<NextButtonRefType>(null);

  useEffect(() => {
    nextButtonClickHandler(nextButtonRef);
  }, [isAnyLockout]);

  return (
    <div className="FormSlide">
      <p className="FormSlide__title mb35">Is there a lockout?</p>
      <RadioGroup
        isAnyValueSelected={isAnyLockout}
        setIsAnyValueSelected={setIsAnyLockout}
        radioButtonTitles={Object.values(yesNoOptions)}
        nextButtonTrigger={() => nextButtonClickHandler(nextButtonRef)}
      />
      <NextButton
        isHidden={!defaultValue.length}
        setNextButtonRef={setNextButtonRef}
        isDisabled={!isAnyLockout.length}
        slideData={{[PrepayPenaltySubsteps[PrepayPenaltySubsteps.anyLockout]]: isAnyLockout}}
        action={setAnyLockout}
        nextSlide={handleSelectedValue(isAnyLockout[0])}
      />
    </div>
  );
};

export default AnyLockout;

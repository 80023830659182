export enum ProjectViewTabs {
  'underwriting',
  'capital-sources',
  'offers',
  'offer-analysis',
}

export enum ModalTypes {
  'equity',
  'senior',
  'dashboardSort',
  'subordinate',
  'guarantors',
  'newUser',
  'removeShare',
}

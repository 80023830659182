import {ChangeEvent, FC, useMemo} from 'react';
import {FieldInputProps, FieldMetaProps} from 'formik';
import cx from 'classnames';

import s from './TextInput.module.scss';

interface ITextInputProps {
  width?: string;
  value?: string;
  name?: string;
  onValueChange?: (e: ChangeEvent<any>) => void;
  onBlur?: any;
  textAlign?: 'left' | 'center' | 'right';
  isValid?: boolean;
  error?: string;
  field?: FieldInputProps<any>;
  meta?: FieldMetaProps<any>;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  maxLength?: number;
  fullWidth?: boolean;
  type?: string;
  className?: string;
  autoCompleteRef?: any;
}

const TextInput: FC<ITextInputProps> = ({
  autoCompleteRef,
  width,
  value,
  name,
  defaultValue,
  isValid,
  error,
  onValueChange,
  onBlur,
  maxLength,
  fullWidth,
  field,
  meta,
  label,
  placeholder,
  textAlign = 'left',
  type,
}) => {
  const responsiveInputWidth = useMemo(() => (fullWidth ? '100%' : width), [fullWidth, width]);

  return (
    <div className={cx(s.textInput, {fullWidth})}>
      {label && <p className="FormSlide__inputLabel">{label}</p>}
      <input
        {...field}
        ref={autoCompleteRef}
        defaultValue={defaultValue}
        value={value}
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={(e) => field?.onChange(e) || onValueChange?.(e)}
        onBlur={onBlur}
        className={cx(s.textInput__item, {inputValid: isValid ?? (!meta?.error && (meta?.touched || field?.value))})}
        style={{width: responsiveInputWidth, textAlign}}
        maxLength={maxLength}
      />
      {isValid && <p className={s.textInput__error}>{error}</p>}
    </div>
  );
};
export default TextInput;

import {FC} from 'react';
import {Button} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';

// Shared
import {THEMES} from 'shared/helpers';

const useStyles = makeStyles({
  primaryBtn: {
    color: '#848484',
    border: '1px solid gray',
    borderRadius: '20px',
    font: '400 16px Source Sans Pro',
    textTransform: 'none',

    '&:hover': {
      color: '#000',
      backgroundColor: THEMES.lightBlue,
    },
  },
  modalFilter: {
    color: 'black',
    padding: '10px 20px',
  },
  modalFilterStatic: {
    flexBasis: '180px',
    color: 'black',
    padding: '10px 0',
  },
  capitalSources: {
    flexBasis: '140px',
  },
  active: {
    color: 'black',
    backgroundColor: THEMES.lightBlue,
  },
});

type CheckboxButtonProps = {
  text: string;
  variant?: string;
  handleClick?: () => void;
  isActive?: boolean;
};

const CheckboxButton: FC<CheckboxButtonProps> = ({text, variant, handleClick, isActive}) => {
  const classes = useStyles();

  return (
    <Button
      className={cx(
        classes.primaryBtn,
        isActive ? classes.active : '',
        variant === 'capitalSourcesFilter' && classes.capitalSources,
        variant === 'modalFilter' && classes.modalFilter,
        variant === 'modalFilterStatic' && classes.modalFilterStatic
      )}
      onClick={() => {
        if (handleClick) {
          handleClick();
        }
      }}
    >
      {text}
    </Button>
  );
};

export default CheckboxButton;

import {ChangeEvent, CSSProperties, FC, FocusEvent} from 'react';
import cx from 'classnames';

import useWindowWidth from 'core/utils/hooks/useWindowWidth';
import {THEMES} from 'shared/helpers';

import s from './TextArea.module.scss';

interface TextArea {
  width: string;
  height: string;
  onValueChange: (e: ChangeEvent<any>) => void;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  isValid?: boolean;
  defaultValue?: string;
  label?: string;
  name?: string;
  value?: string;
  theme?: THEMES;
  resize?: CSSProperties['resize'];
  placeholder?: string;
}

const TextArea: FC<TextArea> = ({
  width,
  height,
  onValueChange,
  onBlur,
  isValid,
  defaultValue,
  label,
  name,
  value,
  theme,
  resize,
  placeholder,
}) => {
  const windowWidth = useWindowWidth();

  const inputWidth = windowWidth > 580 ? width : '100%';

  return (
    <>
      {label && (
        <p
          style={{
            color: theme === THEMES.blue ? '#757575' : '#fff',
            fontWeight: theme === THEMES.blue ? 'normal' : 'bold',
          }}
          className={s.textArea__label}
        >
          {label}
        </p>
      )}
      <textarea
        name={name}
        onChange={(e) => onValueChange(e)}
        onBlur={onBlur}
        className={cx(s.textArea, {FormSlide__inputValid: isValid}, {[s.textArea__white]: theme === THEMES.white})}
        style={{width: inputWidth, height, resize}}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
      />
    </>
  );
};

export default TextArea;

import {useEffect, useMemo, useState} from 'react';
import RadioGroup from 'shared/components/RadioGroup';
import NextButton from 'shared/components/NextButton';
import {QuoteTermsSteps, LoanTermSubsteps, storageKey, loanTermDurationOptions} from 'core/_consts';
import {Link, useLocation} from 'react-router-dom';
import {queryParams} from 'core/utils';
import {setLengthAndFee, setLoanTermDuration} from 'store/quote-terms-service/quoteTermsSlice';
import {useDispatch, useSelector} from 'react-redux';
import {selectLoanTermDuration} from 'store/quote-terms-service/quoteTermsSelector';
import {vaultService} from 'core/service/storage';
import {nextButtonClickHandler, NextButtonRefType} from 'pages/LenderView/helpers';

const handleSelectedValue = (value: string) =>
  (
    [
      loanTermDurationOptions.fivePlusFive,
      loanTermDurationOptions.twoPlusOne,
      loanTermDurationOptions.threePlusOnePlusOne,
    ] as Array<string>
  ).includes(value)
    ? {
        step: QuoteTermsSteps[QuoteTermsSteps.loanTerm],
        substep: LoanTermSubsteps[LoanTermSubsteps.lengthAndFee],
      }
    : {
        step: QuoteTermsSteps[QuoteTermsSteps.loanTerm],
        substep: LoanTermSubsteps[LoanTermSubsteps.anyExtensions],
      };

const SelectTerm = () => {
  const dispatch = useDispatch();
  const defaultValue = useSelector(selectLoanTermDuration);
  const [selectedTerm, setSelectedTerm] = useState<Array<string>>(defaultValue);
  const [nextButtonRef, setNextButtonRef] = useState<NextButtonRefType>(null);

  const location = useLocation();
  const formPosition = new URLSearchParams(location.search).get('formPosition') || '';

  const customLoanTermPath = useMemo(
    () => ({
      pathname: `${location.pathname}`,
      search: `${queryParams({
        step: QuoteTermsSteps[QuoteTermsSteps.loanTerm],
        substep: LoanTermSubsteps[LoanTermSubsteps.customLoanTerm],
        formPosition,
      })}`,
      hash: `${location.hash}`,
    }),
    [location, formPosition]
  );

  const onClickHandler = () => {
    vaultService.updateItem(storageKey.quoteTermsData, {[LoanTermSubsteps[LoanTermSubsteps.loanTermDuration]]: []});
  };

  useEffect(() => {
    dispatch(
      setLengthAndFee({
        initialTerm: null,
        initialTermDateFormat: '',
        originationFee: null,
        originationFeeValueFormat: '',
        extensions: [],
      })
    );
  }, []);

  useEffect(() => {
    nextButtonClickHandler(nextButtonRef);
  }, [selectedTerm]);

  return (
    <div className="FormSlide">
      <p className="FormSlide__title mb35">What is the loan term?</p>
      <RadioGroup
        maxWidth="680px"
        isAnyValueSelected={selectedTerm}
        setIsAnyValueSelected={setSelectedTerm}
        radioButtonTitles={Object.values(loanTermDurationOptions)}
        nextButtonTrigger={() => nextButtonClickHandler(nextButtonRef)}
      />
      <p className="FormSlide__redirect mt25">
        I’d rather enter a &nbsp;
        <Link className="link" to={customLoanTermPath} onClick={onClickHandler}>
          custom loan term
        </Link>
        .
      </p>
      <NextButton
        isHidden={!defaultValue.length}
        setNextButtonRef={setNextButtonRef}
        sideEffect={() => {
          vaultService.removeItem(storageKey.quoteTermsData, LoanTermSubsteps[LoanTermSubsteps.customLoanTerm]);
        }}
        isDisabled={!selectedTerm.length}
        slideData={{[LoanTermSubsteps[LoanTermSubsteps.loanTermDuration]]: selectedTerm}}
        action={setLoanTermDuration}
        nextSlide={handleSelectedValue(selectedTerm[0])}
      />
    </div>
  );
};

export default SelectTerm;

import {CreditScoreType, EntityType, UsCitizenshipType} from '../../_types/selectOptionsTypes/GuarantorsTypes';

export const entityTypeOptions: Array<EntityType> = [
  {value: 'INDIVIDUAL', label: 'Individual'},
  {value: 'LLC', label: 'LLC'},
  {value: 'CORPORATION', label: 'Corporation'},
];

export const usCitizenshipTypeOptions: Array<UsCitizenshipType> = [
  {value: 'OTHER', label: 'Other'},
  {value: 'US_CITIZEN', label: 'US citizen'},
  {value: 'IMMIGRANT_LEGAL', label: 'Legal immigrant'},
  {value: 'NON_IMMIGRANT_VISA', label: 'Non immigrant Visa'},
];

export const creditScoreOptions: Array<CreditScoreType> = [
  {value: 'UNSET', label: ''},
  {value: 'BAD', label: 'Bad'},
  {value: 'POOR', label: 'Poor'},
  {value: 'OKAY', label: 'Okay'},
  {value: 'GOOD', label: 'Good'},
  {value: 'GREAT', label: 'Great'},
  {value: 'EXCELLENT', label: 'Excellent'},
];

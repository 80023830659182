import {Ava, CurrencyUnit, CurrencyValue} from '@burnaby/ava';

export * from '@burnaby/ava';

const remote = process.env.REACT_APP_AVA_REMOTE;
if (!remote) {
  throw new Error('Required environment variable REACT_APP_AVA_REMOTE is unset');
}

Ava.configureRemote(remote);

export const ava = new Ava({remote});

export function usd(value: number | string) {
  if (typeof value === 'string') {
    value = +value.replace(/^\$|,/g, '');
  }
  return CurrencyValue.fromNumber(CurrencyUnit.USD, value);
}

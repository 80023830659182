export enum NewDealSliderSteps {
  'new-user-email',
  'asset-type',
  'investment-property',
  'tenants',
  'multiFamily-acquisition',
  'financing-purpose',
  'address',
  'property-income',
  'project-budget',
  'financing-request',
  'financing-details',
  'attachments',
  'sponsor-role',
  'guarantors',
  'project-summary',
  'finalize',
}

export enum NewUserDealSteps {
  'asset-type',
  'investment-property',
  'tenants',
  'multiFamily-acquisition',
  'financing-purpose',
  'address',
  'property-income',
  'project-budget',
  'financing-request',
  'financing-details',
  'attachments',
  'sponsor-role',
  'guarantors',
  'project-summary',
}

export enum AssetTypes {
  'Multi-family',
  'Office',
  'Industrial',
  'Retail',
  'Hospitality',
  'Land',
  'Self Storage',
  'Religious',
  'Hospital',
  'Other',
}

export enum MultiFamilyTypes {
  'Apartments',
  'Condominiums',
  'Senior Apartments',
  'Student Housing',
  'Assisted Living',
  'Rental Portfolio',
  'Mobile Home Park',
  'Co-Living',
}

export enum IndustrialTypes {
  'Light Industrial',
}

export enum OfficeInvestmentPropertyTypes {
  'Owner occupied',
  'Investment',
}

export enum HospitalityInvestmentPropertyTypes {
  'Flagged/Branded Hotel',
  'Boutique Hotel',
}

export enum TenantsTypes {
  'Single Tenant',
  'Multiple Tenants',
}

export enum FinancePurpose {
  'New Development',
  'Redevelopment',
  'Acquisition',
  'Refinance',
}

export enum UserRole {
  'Direct Borrower',
  'Representing the Borrower',
}

export enum PreferredContactMethod {
  'EMAIL',
  'PHONE',
}

export enum MultiFamilyAcquisition {
  'Produce a professional underwriting',
  'Research and collect market comps & data',
  'Prepare an acquisition letter of intent',
  'Match my deal with capital sources',
}

export const isValue = (value: string) => Number.isNaN(Number(value));

import {FC} from 'react';
import withStyles from '@mui/styles/withStyles';
import {Switch} from '@mui/material';

type SwitcherProps = {
  onChange: () => void;
  checked: boolean;
};

const Switcher: FC<SwitcherProps> = ({onChange, checked}) => {
  const StyledSwitch = withStyles({
    track: {
      backgroundColor: '#D8D8D8',
      opacity: '1',
    },
    switchBase: {
      color: '#979797',

      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: '18px',
        border: 'solid #fff',
        borderWidth: '0 0 2px 0',
        width: '9px',
      },
      '&$checked': {
        color: '#5999D8',

        '&:after': {
          content: '""',
          position: 'absolute',
          bottom: '17px',
          border: 'solid #fff',
          transform: 'rotate(-45deg)',
          borderWidth: '0 0 2px 2px',
          height: '4px',
          width: '9px',
        },
      },
      '&$track': {
        backgroundColor: '#B3D5F8',
      },
    },
    checked: {},
  })(Switch);

  return <StyledSwitch onChange={onChange} checked={checked} />;
};

export default Switcher;

import {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// MUI
import {Button, CircularProgress} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Core
import {Share, ShareDeclined, ava} from 'core/service/ava';
import {CapitalSource, DealType, ShareType} from 'core/_types/base';

// Store
import {selectorGetCapitalSources} from 'store/project-service/selector';
import {selectorGetLoading, selectorGetShares} from 'store/shares-service/selector';
import {getContacts, getProviders, sendInvites} from 'store/shares-service/asyncActions';
import {concealedShares, getCapitalSources, getProject} from 'store/project-service/asyncActions';
import {selectorGetUserSettings} from 'store/user-service/selector';

// Components
import Switcher from 'shared/components/Switcher';
import CapitalSourcesItem, {ShareStatus} from './CapitalSourcesItem';

import {CapitalSourceDetails} from './CapitalSourceDetails';
import {InstrumentButton} from './InstrumentButton';
import {instruments, instrumentsConfig} from './helpers';

import s from './CapitalSources.module.scss';

const useStyles = makeStyles({
  greyBtn: {
    display: 'block',
    padding: '5px 10px',
    color: '#fff',
    backgroundColor: '#848484',
    borderRadius: '5px',
    fontWeight: 'bold',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#6e6e6e',
    },
  },
  linkBtn: {
    display: 'block',
    padding: 0,
    fontSize: '16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  root: {
    border: 'none',
    borderTop: '1px dotted #dde4e4',
    boxShadow: 'none',
  },
  details: {
    border: 'none',
    boxShadow: 'none',
  },
  icon: {
    transform: 'rotateZ(-90deg)',
  },
  summary: {
    padding: 0,
  },
});

type CapitalSourcesProps = {
  project?: DealType;
};

const hackSentShares = new Set<string>();

const CapitalSources: FC<CapitalSourcesProps> = ({project}) => {
  const classes = useStyles();

  const [instrument, setInstrument] = useState<instruments>(instruments.showAll);
  const [shareSyms, setShareSyms] = useState<Array<string>>([]);
  const [showConcealedShares, setShowConcealedShares] = useState<boolean>(!!project?.showConcealedShares);

  const capitalSources: Array<CapitalSource> = useSelector(selectorGetCapitalSources);
  const sharesData: Array<ShareType> = useSelector(selectorGetShares)
    ?.slice()
    .sort((a, b) => getShareStatus(a.share) - getShareStatus(b.share));
  const loading = useSelector(selectorGetLoading);
  const userSettings = useSelector(selectorGetUserSettings);

  const dispatch = useDispatch();

  const dealApproved = !!project?.approved;

  useEffect(() => {
    if (project) {
      const {filterValue} = instrumentsConfig[instrument];
      const instrumentFilters = filterValue ? [filterValue] : [];
      dispatch(getCapitalSources({projectSym: project.sym, filters: {instrument: instrumentFilters}}));
    }
  }, [project?.sym, project?.shareList, instrument]);

  const handleInvite = () => {
    dispatch(sendInvites({ava, shareSyms}));
    shareSyms.forEach((sym) => {
      hackSentShares.add(sym);
    });
    setShareSyms([]);
  };

  const handleSelectAll = () => {
    const syms = sharesData.map((data) => data.share.sym);
    setShareSyms(syms);
  };

  const handleClick = (share: ShareType) => {
    dispatch(getContacts({share, dispatch}));

    if (share.share.provider) {
      dispatch(getProviders({share, dispatch}));
    }
  };

  const handleReveal = () => {
    setShowConcealedShares(!showConcealedShares);
    dispatch(concealedShares({sym: project?.sym as string, showConcealedShares}));
  };

  return (
    <div className={s.capitalSources}>
      <h2 className={s.capitalSources__heading}>Saved Capital Sources</h2>
      <div className={s.capitalSources__reveal}>
        <h3 className={s.capitalSources__heading_sub}>Reveal Capital Sources</h3>
        <Switcher onChange={handleReveal} checked={showConcealedShares} />
      </div>
      <div className={s.capitalSources__links}>
        <Button className={classes.linkBtn} onClick={handleSelectAll}>
          Select all
        </Button>
        {shareSyms.length ? (
          <Button className={classes.linkBtn} onClick={() => setShareSyms([])}>
            Unselect all
          </Button>
        ) : null}
      </div>
      <div className={s.sources}>
        {loading ? (
          <div className={s.loader}>
            <CircularProgress />
          </div>
        ) : !sharesData.length ? (
          <p>No saved sources</p>
        ) : (
          <>
            {sharesData.map((data: ShareType) => (
              <Accordion key={data?.share?.sym} className={classes.root}>
                <AccordionSummary
                  className={classes.summary}
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  id="panel1a-header"
                  onClick={() => handleClick(data)}
                >
                  <CapitalSourcesItem
                    ava={ava}
                    projectSym={project?.sym as string}
                    providerSym={data?.share?.sym}
                    name={data?.org?.name.replace('[2]', '') || 'Unnamed Provider'}
                    invited={data?.share?.invitationSentByPlatform}
                    type={data?.org?.briefDescription}
                    shareSyms={shareSyms}
                    setShareSyms={setShareSyms}
                    status={getShareStatus(data.share)}
                    views={data.share.views}
                    isSaved
                  />
                </AccordionSummary>
                <AccordionDetails sx={{padding: 0}}>
                  <CapitalSourceDetails
                    ava={ava}
                    userSettings={userSettings}
                    isSaved
                    capitalSource={data?.provider}
                    provider={data?.org}
                    share={data.share}
                    contacts={data?.contacts}
                    isLoading={data?.isLoading}
                    isLinkCopied={data?.isLinkCopied}
                    dealApproved={dealApproved}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}
      </div>
      {!dealApproved ? null : (
        <Button className={classes.greyBtn} onClick={handleInvite}>
          Invite Selected
        </Button>
      )}
      <h2 className={s.capitalSources__heading}>Matched Capital Sources</h2>
      <div className={s.filter}>
        <div className={s.filter__disabled}>
          <p className={s.filter__label}>Filter by capital type: </p>
          <div className={s.filter__list}>
            <InstrumentButton instrument={instruments.showAll} selected={instrument} setSelected={setInstrument} />
            <InstrumentButton instrument={instruments.senior} selected={instrument} setSelected={setInstrument} />
            <InstrumentButton
              instrument={instruments.subordinate}
              selected={instrument}
              setSelected={setInstrument}
              longText
            />
            <InstrumentButton instrument={instruments.equity} selected={instrument} setSelected={setInstrument} />
            <InstrumentButton instrument={instruments.cPACE} selected={instrument} setSelected={setInstrument} />
            <InstrumentButton instrument={instruments.groundLease} selected={instrument} setSelected={setInstrument} />
            <InstrumentButton instrument={instruments.lineOfCredit} selected={instrument} setSelected={setInstrument} />
          </div>
        </div>

        <div className={s.sources}>
          {!capitalSources ? (
            <div className={s.loader}>
              <CircularProgress />
            </div>
          ) : !capitalSources.length ? (
            <p>No sources found matching search criteria</p>
          ) : (
            <>
              {capitalSources.map((provider: CapitalSource) => (
                <Accordion key={provider?.sym} className={classes.root}>
                  <AccordionSummary
                    className={classes.summary}
                    expandIcon={<ExpandMoreIcon className={classes.icon} />}
                    id="panel1a-header"
                  >
                    <CapitalSourcesItem
                      ava={ava}
                      projectSym={project?.sym as string}
                      providerSym={provider?.sym}
                      name={provider?.name.replace('[2]', '')}
                      type={provider?.formatType()}
                      shareSyms={shareSyms}
                      setShareSyms={setShareSyms}
                      isMatched
                    />
                  </AccordionSummary>
                  <AccordionDetails sx={{padding: 0}}>
                    <CapitalSourceDetails
                      ava={ava}
                      capitalSource={provider}
                      userSettings={userSettings}
                      dealApproved={dealApproved}
                    />
                  </AccordionDetails>
                </Accordion>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

function getShareStatus(share: Share): ShareStatus {
  const madeOffer = !share.offerList.isEmpty();
  // TODO(nathan): Ava needs to provide this information.
  const accepted = false as boolean;
  switch (true) {
    case accepted:
      return ShareStatus.OFFER_ACCEPTED;
    case share.declined === ShareDeclined.AUTO:
      return ShareStatus.AUTO_DECLINED;
    case share.declined === ShareDeclined.MANUAL:
    case share.declined === ShareDeclined.EMAIL:
      return ShareStatus.DECLINED;
    case madeOffer:
      return ShareStatus.QUOTE;
    case share.views > 0:
      return ShareStatus.VIEWED;
    case share.tokenFirstCreated.isEmpty() && !hackSentShares.has(share.sym):
      return ShareStatus.UNSENT;
    default:
      return ShareStatus.UNOPENED;
  }
}

export default CapitalSources;

import {Instrument} from 'core/_types/Filters';

export enum instruments {
  showAll,
  senior,
  subordinate,
  equity,
  cPACE,
  groundLease,
  lineOfCredit,
}

export const instrumentsConfig: Record<instruments, {label: string; filterValue?: Instrument}> = {
  [instruments.showAll]: {
    label: 'Show all',
  },
  [instruments.senior]: {
    label: 'Senior',
    filterValue: 'SENIOR',
  },
  [instruments.subordinate]: {
    label: 'Subordinate',
    filterValue: 'SUBORDINATE',
  },
  [instruments.equity]: {
    label: 'Equity',
    filterValue: 'EQUITY',
  },
  [instruments.cPACE]: {
    label: 'C-PACE',
    filterValue: 'CPACE',
  },
  [instruments.groundLease]: {
    label: 'Ground Lease',
    filterValue: 'GROUND_LEASE',
  },
  [instruments.lineOfCredit]: {
    label: 'Line of Credit',
    filterValue: 'LINE_OF_CREDIT',
  },
};

import {FC, useMemo} from 'react';
import cx from 'classnames';
import {Link, useLocation, useParams} from 'react-router-dom';
import {filterObjectByTruthyValue, queryParams} from 'core/utils';
import {ROUTERS} from 'core/_consts/routes';
import {URLParams} from 'core/_types/base';
import s from './DataCell.module.scss';

type DataCellProps = {
  data: string | number;
  title: string;
  isEditingMode?: boolean;
  step?: string;
  substep?: string;
  reversedStyles?: boolean;
  currentPosition?: string;
};

const DataCell: FC<DataCellProps> = ({
  data,
  title,
  isEditingMode,
  reversedStyles,
  currentPosition = '',
  step = '',
  substep = '',
}) => {
  const location = useLocation();
  const params = useParams<URLParams>();

  const redirectOnEditingSlide = useMemo(
    () => ({
      pathname: `${ROUTERS.PROJECT}:${params.dealSym}/view`,
      search: `${queryParams(filterObjectByTruthyValue({step, substep, formPosition: currentPosition}))}`,
      hash: `${location.hash}`,
      state: {isEditingMode: true},
    }),
    [location]
  );

  return (
    <div className={s.dataCell}>
      <p className={cx(s.dataCell__title, {[s.dataCell__title_reversed]: reversedStyles})}>
        {title}
        {isEditingMode && (
          <Link to={redirectOnEditingSlide} className={cx(s.dataCell__edit, 'link')}>
            Edit
          </Link>
        )}
      </p>
      <p className={cx(s.dataCell__subtitle, {[s.dataCell__subtitle_reversed]: reversedStyles})}>{data}</p>
    </div>
  );
};

export default DataCell;

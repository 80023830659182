import {FC, useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {LoanTermSubsteps} from 'core/_consts';
import SelectTerm from './SelectTerm';
import TermDuration from './TermDuration';
import AnyExtensions from './AnyExtensions';
import ExtensionsAmount from './ExtensionsAmount';
import LengthAndFee from './LengthAndFee';

type LoanTermSubstepsKeys = keyof typeof LoanTermSubsteps;

const LoanTerm: FC = () => {
  const location = useLocation();
  const [substep, setSubstep] = useState<LoanTermSubstepsKeys | null>();

  useEffect(() => {
    setSubstep(new URLSearchParams(location.search).get('substep') as LoanTermSubstepsKeys);
  }, [location.search]);

  switch (substep) {
    case LoanTermSubsteps[LoanTermSubsteps.loanTermDuration]:
      return <SelectTerm />;

    case LoanTermSubsteps[LoanTermSubsteps.anyExtensions]:
      return <AnyExtensions />;

    case LoanTermSubsteps[LoanTermSubsteps.extensionsAmount]:
      return <ExtensionsAmount />;

    case LoanTermSubsteps[LoanTermSubsteps.customLoanTerm]:
      return <TermDuration />;

    case LoanTermSubsteps[LoanTermSubsteps.lengthAndFee]:
      return <LengthAndFee />;

    default:
      return null;
  }
};

export default LoanTerm;

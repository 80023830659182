import {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// Core
import {DealGuarantor, usd} from 'core/service/ava';
import {statusMessage} from 'core/enums/statusMessage';

import {selectorGetProject, selectorGetUpdate} from 'store/project-service/selector';
import {createGuarantor, updateGuarantors} from 'store/project-service/asyncActions';
import {
  setUpdatedGuarantors,
  setUpdatedGuarantorsAddCompleted,
  setUpdatedGuarantorsEditCompleted,
} from 'store/project-service/projectSlice';

import GuarantorsForm from './GuarantorsForm';

type GuarantorsProps = {
  setOpen: (open: boolean) => void;
  guarantors: DealGuarantor[];
  setGuarantors: Dispatch<SetStateAction<DealGuarantor[]>>;
  completeStatus?: string;
  guarantorEdit?: number;
};

const Guarantors: FC<GuarantorsProps> = ({setOpen, guarantors, setGuarantors, completeStatus, guarantorEdit}) => {
  const dispatch = useDispatch();
  const project = useSelector(selectorGetProject);
  const {guarantorsUpd} = useSelector(selectorGetUpdate);

  const guarantor: DealGuarantor =
    guarantorEdit !== undefined && guarantors && guarantorEdit >= 0
      ? guarantors[guarantorEdit]
      : new DealGuarantor().setNetWorth(usd(0)).setLiquidity(usd(0));

  useEffect(() => {
    if (guarantorsUpd.addCompleted) {
      setOpen(false);
      dispatch(setUpdatedGuarantorsAddCompleted(false));
    }
  }, [guarantorsUpd.addCompleted]);

  useEffect(() => {
    if (guarantorsUpd.editCompleted && guarantors) {
      setOpen(false);
    }
    dispatch(setUpdatedGuarantorsEditCompleted(false));
  }, [guarantorsUpd.editCompleted]);

  const setGuarantor = (value: DealGuarantor) => {
    if (guarantors && guarantorEdit !== undefined) {
      if (guarantorEdit < 0) {
        setGuarantors([...guarantors, value]);

        dispatch(
          createGuarantor({
            sym: project.sym,
            fields: value,
            guarantors,
            dispatch,
          })
        );
        dispatch(setUpdatedGuarantors(statusMessage.saving));
      } else {
        const newGuarantors = [...guarantors.slice(0, guarantorEdit), value, ...guarantors.slice(guarantorEdit + 1)];
        setGuarantors(newGuarantors);

        dispatch(
          updateGuarantors({
            sym: project.sym,
            guarantors: newGuarantors,
            dispatch,
          })
        );
        dispatch(setUpdatedGuarantors(statusMessage.saving));
      }
    }
  };

  return <GuarantorsForm value={guarantor} setValue={setGuarantor} setOpen={setOpen} completeStatus={completeStatus} />;
};

export default Guarantors;

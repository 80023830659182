import {FC, useState} from 'react';
import {useFormikContext} from 'formik';
import {StepSelect} from 'shared/components/StepSelect';
import {THEMES} from 'shared/helpers';
import {UnsavedChangesPrompt} from 'shared/components/UnsavedChangesPrompt';
import SelectionCard from '../../SelectionCard';
import {AssetTypes, HospitalityInvestmentPropertyTypes, isValue, OfficeInvestmentPropertyTypes} from '../../helpers';

// Style
import s from './InvestmentProperty.module.scss';

type InvestmentPropertyProps = {
  goNext: (stepAmount?: number) => void;
};

const InvestmentProperty: FC<InvestmentPropertyProps> = ({goNext}) => {
  const {values, handleChange, setFieldValue}: any = useFormikContext();

  const [selectedCard, setSelectedCard] = useState(values.investmentProperty);

  const office = AssetTypes[AssetTypes.Office];
  const hospitality = AssetTypes[AssetTypes.Hospitality];
  const flaggedHotel = HospitalityInvestmentPropertyTypes[HospitalityInvestmentPropertyTypes['Flagged/Branded Hotel']];
  const boutiqueHotel = HospitalityInvestmentPropertyTypes[HospitalityInvestmentPropertyTypes['Boutique Hotel']];

  const iconTitles =
    values.assetType === office
      ? (Object.keys(OfficeInvestmentPropertyTypes) as Array<keyof typeof OfficeInvestmentPropertyTypes>).filter(
          isValue
        )
      : (
          Object.keys(HospitalityInvestmentPropertyTypes) as Array<keyof typeof HospitalityInvestmentPropertyTypes>
        ).filter(isValue);

  const mockOptions = ['option 1', 'option 2', 'option 3'];

  return (
    <div className={s.investmentProperty}>
      <div className={s.investmentProperty__header}>
        {values.assetType === hospitality && values.investmentProperty === flaggedHotel ? (
          <p className={s.investmentProperty__title}>Hotel Flag</p>
        ) : (
          <p className={s.investmentProperty__title}>Is this an investment property?</p>
        )}
        {values.assetType === office && (
          <p className={s.investmentProperty__subtitle}>
            Choose owner-occupied if the owner occupies more the 50% of the available square footage.
          </p>
        )}
      </div>
      <div className={s.investmentProperty__cards}>
        {iconTitles.map((iconTitles) => (
          <SelectionCard
            key={iconTitles}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            handleCardSelection={() => {
              setFieldValue('investmentProperty', iconTitles);
              setSelectedCard(iconTitles);
              if (iconTitles !== flaggedHotel) {
                goNext();
              }
              if (iconTitles === boutiqueHotel) {
                goNext(2);
              }
            }}
            title={iconTitles}
            iconName={iconTitles.toLocaleLowerCase().replace('/', '')}
          />
        ))}
      </div>
      {values.assetType === hospitality && values.investmentProperty === flaggedHotel && (
        <div className={s.investmentProperty__form}>
          <div className={s.investmentProperty__inputs}>
            <StepSelect
              placeholder="Select Hotel Flag"
              label="Hotel Flag"
              name="hotelFlag"
              value={values.hotelFlag}
              theme={THEMES.white}
              options={mockOptions}
              handleChange={handleChange}
            />
            <div className={s.investmentProperty__container}>
              <label htmlFor="datePicker" className={s.investmentProperty__label}>
                Know the expiration date
              </label>
              <input
                id="datePicker"
                type="date"
                name="hotelExpirationDate"
                value={values.hotelExpirationDate}
                onChange={handleChange}
                className={s.investmentProperty__datePicker}
              />
            </div>
          </div>
          <UnsavedChangesPrompt />
        </div>
      )}
    </div>
  );
};

export {InvestmentProperty};

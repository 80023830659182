import {FC, useMemo} from 'react';
import {Divider} from '@mui/material';

// Core
import {BuildingTypes} from 'core/enums/buildingTypes';
import {
  Deal,
  ExpectedInterestRate,
  ExpectedRecourse,
  ExpectedRepaymentSchedule,
  TermRange,
} from 'core/service/ava';

// Components
import {ReactComponent as ClosingDateIcon} from 'shared/media/ClosingDate.svg';

import s from './ProjectInfoCard.module.scss';

const defaultDetails = {
  loanTerm: 'Loan Term: Any',
  interestRate: 'Interest Rate: Any',
  amortization: 'Amortization : Any',
  recourse: 'Recourse: Any',
};

type ProjectInfoCardProps = {
  project: Deal;
};

const EMPTY_TERM_RANGE = TermRange.ofYears(1, 0);

const ProjectInfoCard: FC<ProjectInfoCardProps> = ({project}) => {
  const getIconSource = (data: string) =>
    BuildingTypes[
      data.split(' ').join('') as keyof typeof BuildingTypes
    ] || BuildingTypes.Other;

  const labels = useMemo(
    () => [project?.formatAssetType(), project.formatFinancingPurpose()],
    [project],
  );
  const expectations =
    project.offerExpectations.loanTermRange.equals(EMPTY_TERM_RANGE)
      ? project.offerExpectations.deleteLoanTermRange()
      : project.offerExpectations;
  const showExpectations = !expectations.isDefault();

  return (
    <div className={s.projectInfoCard}>
      <div className={[
        s.labels,
        showExpectations ? '' : s.simple,
      ].join(' ')}>
        {labels.map((label) => (
          <div className={s.labels__item} key={label}>
            <img
              src={`/images/infoCard/${getIconSource(label)}.svg`}
              alt="icon"
            />
            <p className={s.labels__text}>{label}</p>
          </div>
        ))}
      </div>
      {(!project.targetCloseDate.isEmpty() || showExpectations) && (
        <Divider />
      )}
      {!project.targetCloseDate.isEmpty() && (
        <>
          <div className={s.closingDate}>
            <ClosingDateIcon className={s.closingDate__icon} />
            <p>
              <span className={s.closingDate__date}>
                {project.targetCloseDate?.formatHumanAbbr()}
                &nbsp;
              </span>
              target closing date
            </p>
          </div>
        </>
      )}
      {showExpectations && (
        <ul className={s.list}>
          <li className={s.list__item}>
            <span className={s.list__text}>
              {
                expectations.loanTermRange.format()
                  ? `${expectations.loanTermRange.format()} investment term`
                  : defaultDetails.loanTerm
              }
            </span>
          </li>
          <li className={s.list__item}>
            <span className={s.list__text}>
              {expectations.interestRate === ExpectedInterestRate.UNSET
                ? defaultDetails.interestRate
                : `${expectations.formatInterestRate()} interest rate`}
            </span>
          </li>
          <li className={s.list__item}>
            <span className={s.list__text}>
              {
                expectations.repaymentSchedule
                  === ExpectedRepaymentSchedule.UNSET
                  ? defaultDetails.amortization
                  : `${expectations.formatRepaymentSchedule()} amortization`
              }
            </span>
          </li>
          <li className={s.list__item}>
            <span className={s.list__text}>
              {
                expectations.recourse === ExpectedRecourse.UNSET
                  ? defaultDetails.recourse
                  : expectations.formatRecourse()
              }
            </span>
          </li>
        </ul>
      )}
      <p
        className={s.projectInfoCard__additionalInfo}
        dangerouslySetInnerHTML={{__html: expectations.notes.formatAsHtml()}}
      />
    </div>
  );
};

export default ProjectInfoCard;

import {FC} from 'react';

import {Sources} from './Sources';
import {Uses} from './Uses';

type SourceAndUsesProps = {
  // TODO: add types for Sources & Uses (and other stored/formatted data)
  sources: Array<any>;
  uses: Array<any>;
};

export const SourcesAndUses: FC<SourceAndUsesProps> = ({sources, uses}) => (
  <>
    <Sources sources={sources} />
    <Uses uses={uses} />
  </>
);

import {useEffect, useState} from 'react';
import RadioGroup from 'shared/components/RadioGroup';
import NextButton from 'shared/components/NextButton';
import {AmortizationSubsteps, QuoteTermsSteps, RecourseSubsteps, yesNoOptions} from 'core/_consts';
import {setInterestOnlyPeriod} from 'store/quote-terms-service/quoteTermsSlice';
import {useSelector} from 'react-redux';
import {selectIsInterestOnlyPeriod} from 'store/quote-terms-service/quoteTermsSelector';
import {nextButtonClickHandler, NextButtonRefType} from 'pages/LenderView/helpers';

const handleSelectedValue = (value: string) =>
  value === yesNoOptions.yes
    ? {
        step: QuoteTermsSteps[QuoteTermsSteps.amortization],
        substep: AmortizationSubsteps[AmortizationSubsteps.interestOnlyPeriodDuration],
      }
    : {
        step: QuoteTermsSteps[QuoteTermsSteps.recourse],
        substep: RecourseSubsteps[RecourseSubsteps.availableRecourse],
      };

const OnlyPeriod = () => {
  const defaultValue = useSelector(selectIsInterestOnlyPeriod);
  const [selectedOnlyPeriod, setSelectedOnlyPeriod] = useState<Array<string>>(defaultValue);
  const [nextButtonRef, setNextButtonRef] = useState<NextButtonRefType>(null);

  useEffect(() => {
    nextButtonClickHandler(nextButtonRef);
  }, [selectedOnlyPeriod]);

  return (
    <div className="FormSlide">
      <p className="FormSlide__title mb35">Is there an interest only period?</p>
      <RadioGroup
        isAnyValueSelected={selectedOnlyPeriod}
        setIsAnyValueSelected={setSelectedOnlyPeriod}
        radioButtonTitles={Object.values(yesNoOptions)}
        nextButtonTrigger={() => nextButtonClickHandler(nextButtonRef)}
      />
      <NextButton
        isHidden={!defaultValue.length}
        setNextButtonRef={setNextButtonRef}
        isDisabled={!selectedOnlyPeriod.length}
        slideData={{[AmortizationSubsteps[AmortizationSubsteps.interestOnlyPeriod]]: selectedOnlyPeriod}}
        action={setInterestOnlyPeriod}
        nextSlide={handleSelectedValue(selectedOnlyPeriod[0])}
      />
    </div>
  );
};

export default OnlyPeriod;

import {ChangeEvent, FC, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormControlLabel, Grid, Radio, RadioGroup} from '@mui/material';
import {Form, FormikHandlers, FormikHelpers, FormikValues} from 'formik';

// Store
import {selectorGetOrgDetails, selectorGetProfile, selectorGetProfileUpdate} from 'store/ava-service/selector';
import {updateUserProfile} from 'store/ava-service/asyncActions';

// Core
import {ProfileType} from 'core/_types/ProfileType';
import {statusMessage} from 'core/enums/statusMessage';
import {setUpdateProfile} from 'store/ava-service/avaSlice';
import useDebounce from 'core/utils/hooks/useDebounce';
import useGoogleAutocomplete from 'core/utils/hooks/useGoogleAutocomplete';
import useStatusMessage from 'core/utils/hooks/useStatusMessage';

// Shared
import BasicButton from 'shared/components/BasicButton';
import TextInput from 'shared/components/TextInput';
import TextArea from 'shared/components/TextArea';
import {THEMES} from 'shared/helpers';

// Styles
import s from './Profile.module.scss';

type ProfileFormProps = {
  values: ProfileType;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: Pick<FormikHelpers<FormikValues>, 'setFieldValue'>['setFieldValue'];
  initialValues: ProfileType;
};

const ProfileForm: FC<ProfileFormProps> = ({values, handleChange, setFieldValue, initialValues}) => {
  const dispatch = useDispatch();
  const profile = useSelector(selectorGetProfile);
  const org = useSelector(selectorGetOrgDetails);
  const profileUpdate = useSelector(selectorGetProfileUpdate);
  const message = useStatusMessage(profileUpdate);

  const debouncedValues = useDebounce(values, 1000);

  const profilePhone = profile?.phoneNumber?.nationalNumber.toString();
  const formattedPhoneNumber = `(${profilePhone.slice(0, 3)}) ${profilePhone.slice(3, 6)}-${profilePhone.slice(6, 10)}`;

  const formatPhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const phoneNumber = e.target.value.replace(/\D/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return setFieldValue('nationalNumber', phoneNumber);
    if (phoneNumberLength < 7) {
      return setFieldValue('nationalNumber', `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`);
    }
    return setFieldValue(
      'nationalNumber',
      `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
    );
  };

  const mainAddressField = useRef<HTMLInputElement>(null);

  const {line1} = values.address;
  useGoogleAutocomplete({mainAddressField, setFieldValue, line1});

  useEffect(() => {
    if (values !== initialValues) {
      dispatch(updateUserProfile({sym: profile.sym, fields: values, dispatch}));
      dispatch(setUpdateProfile(statusMessage.saving));
    }
  }, [debouncedValues]);

  useEffect(
    () => () => {
      dispatch(setUpdateProfile(''));
    },
    []
  );

  return (
    <Form>
      <div className={s.photo}>
        <img src="/images/profile-picture.png" alt="profile" style={{width: '140px', height: '140px'}} />
        <div className={s.photo__actions}>
          <BasicButton variant="contained">
            <label htmlFor="photo" className={s.photo__label}>
              <input type="file" accept="image/*" id="photo" name="photo" />
              Upload photo
            </label>
          </BasicButton>
          <BasicButton variant="outlined">Remove photo</BasicButton>
        </div>
      </div>
      <div className={s.profile__personalRow}>
        <TextInput name="name" label="Name" width="240px" value={values.name} onValueChange={handleChange} />
        <div>
          <p className={s.profile__label}>Organization</p>
          <p>{org && org.name}</p>
        </div>
        <div>
          <p className={s.profile__label}>Organization Website</p>
          <a href={org && org.website.hostname} target="_blank" rel="noreferrer">
            {org && org.website.hostname}
          </a>
        </div>
      </div>
      <div className={s.profile__contactRow}>
        <div className={s.profile__group}>
          <TextInput
            name="phone"
            label="Phone"
            width="140px"
            value={
              values.nationalNumber === initialValues.nationalNumber ? formattedPhoneNumber : values.nationalNumber
            }
            onValueChange={formatPhoneNumber}
          />
          <TextInput
            name="extension"
            placeholder="Ext"
            width="80px"
            value={values.extension}
            onValueChange={handleChange}
          />
        </div>
        <div>
          <p className={s.profile__label}>Preferred Contact Method</p>
          <RadioGroup name="radio-buttons-group" row>
            <FormControlLabel
              checked={values.preferredContactMethod === 'PHONE' || +values.preferredContactMethod === 1}
              value="PHONE"
              name="preferredContactMethod"
              control={<Radio />}
              label="Phone"
              onChange={handleChange}
            />
            <FormControlLabel
              checked={values.preferredContactMethod === 'EMAIL' || +values.preferredContactMethod === 0}
              value="EMAIL"
              name="preferredContactMethod"
              control={<Radio />}
              label="Email"
              onChange={handleChange}
            />
          </RadioGroup>
        </div>
      </div>
      <div className={s.profile__addressRow}>
        <div className={s.profile__addressRow__col}>
          <TextInput
            autoCompleteRef={mainAddressField}
            name="address.line1"
            label="Office Address"
            placeholder="Line 1"
            width="240px"
            value={values.address.line1}
            onValueChange={handleChange}
          />
          <TextInput
            name="address.line2"
            placeholder="Line 2"
            width="240px"
            value={values.address.line2}
            onValueChange={handleChange}
          />
          <div className={s.profile__group}>
            <TextInput
              name="address.city"
              placeholder="City"
              width="140px"
              value={values.address.city}
              onValueChange={handleChange}
            />
            <TextInput
              name="address.state"
              placeholder="State"
              width="80px"
              value={values.address.state}
              onValueChange={handleChange}
            />
          </div>
          <TextInput
            name="address.zip"
            placeholder="Zip"
            width="140px"
            value={values.address.zip}
            onValueChange={handleChange}
          />
        </div>
        <div>
          <TextArea
            resize="vertical"
            name="bio"
            label="Bio"
            value={values.bio}
            theme={THEMES.blue}
            width="620px"
            height="150px"
            onValueChange={handleChange}
          />
        </div>
      </div>
      {!!message.length && (
        <Grid item xs={12} sx={{fontStyle: 'italic', color: '#757575'}}>
          <p>{message}</p>
        </Grid>
      )}
    </Form>
  );
};

export default ProfileForm;

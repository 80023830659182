import {FC, useMemo} from 'react';
import cx from 'classnames';
// TODO: uncomment after getting response about disclaimers
// import {ReactComponent as DisclaimerIcon} from 'shared/media/Disclaimer.svg';
import {useSelector} from 'react-redux';
import {selectorGetProject} from 'store/project-service/selector';
import DataCell, {DataCellProps} from 'shared/components/DataCell/DataCell';
import AccordionWrapper from 'shared/components/Accordion';
import s from './Sponsor.module.scss';
import {IsMobileViewProps} from '../helpers';

const filterValuesByBoolean = (values: Array<DataCellProps>) => {
  if (!values) return false;
  return values.map((item) => (item.isTruthy ?? item.data) && item.title !== 'Sponsor Type').some(Boolean);
};

const Sponsor: FC<IsMobileViewProps> = ({isMobileView}) => {
  const project = useSelector(selectorGetProject);

  const sponsorTableData = useMemo(
    () => [
      {title: 'Year Founded', data: project.sponsorProfile?.yearFounded},
      {title: 'Years Of Experience', data: project.sponsorProfile?.yearsOfExperience},
      {
        title: 'Address',
        data: project.sponsorProfile?.address.formatCityAndState(),
        isTruthy: !project.sponsorProfile?.address.isEmpty(),
      },
      {
        title: 'Assets Under Ownership',
        data: project.sponsorProfile?.assetsUnderOwnership.format(),
        isTruthy: !project.sponsorProfile?.assetsUnderOwnership.isZero(),
      },
      {title: 'Sponsor Type', data: project.sponsorProfile?.formatSponsorType},
    ],
    [project]
  );

  const content = (
    <div className="LenderView__section">
      {!isMobileView && <p className="LenderView__infoContentTitle">Sponsor</p>}
      <div className={cx(s.table, 'LenderView__flexTable')}>
        <div className={s.table__mainInfo}>
          <div className="fw600">{project.sponsorProfile?.name}</div>
          <a href={project.sponsorProfile?.website} className={cx('link', 'fw600')}>
            {project.sponsorProfile?.website && hostname(project.sponsorProfile.website)}
          </a>
        </div>
        {sponsorTableData?.map((item) => (
          <DataCell key={item.title} data={item.data} title={item.title} isTruthy={item.isTruthy} />
        ))}
      </div>
      <div className={s.disclaimer}>
        <p className={s.disclaimer__title}>Disclaimer</p>
        {/* TODO: enable after asking about disclaimers info */}
        {/* <div className={s.disclaimer__list}>
  {!!disclaimers.length &&
    project.sponsorProfile?.description.formatAsHtml().split('.').map((item) => (
      <div className={s.disclaimer__item} key={item}>
        <DisclaimerIcon className={s.disclaimer__icon} />
        <p>{item}</p>
      </div>
    ))}
</div> */}
      </div>
    </div>
  );

  if (!filterValuesByBoolean(sponsorTableData)) return null;
  if (isMobileView) return <AccordionWrapper title="Sponsor">{content}</AccordionWrapper>;

  return content;
};

export default Sponsor;

function hostname(website: string) {
  try {
    const url = new URL(website);
    return url.hostname;
  } catch (e) {
    console.log('Could not parse hostname:', website);
    return website;
  }
}

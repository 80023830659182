import {FC, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

// Core
import {ChecklistItemType} from 'core/_models/projectOverview';
import {DealType} from 'core/_types/base';
import {SinatraDealTab, sinatraDealUrl} from 'core/helpers/sinatra';

// Assets
import {ReactComponent as CompleteIcon} from 'assets/icons/complete.svg';

// Store
import {updateApproved} from 'store/project-service/asyncActions';

// Components
import BasicButton from 'shared/components/BasicButton';
import ChecklistItem from './ChecklistItem';

import s from './Checklist.module.scss';
import {getChecklist} from './items';

type ChecklistProps = {
  project: DealType;
  emailVerified: boolean;
};

const Checklist: FC<ChecklistProps> = ({project, emailVerified}) => {
  const dispatch = useDispatch();
  const [approving, setApproving] = useState(false);

  const toggleApproved = () => {
    setApproving(true);
    dispatch(updateApproved({
      sym: project.sym,
      approved: !project.approved,
      dispatch,
    }));
  };

  // TODO: add an opportunity to scroll to the open Accordion in Underwriting
  // (when clicked on checklist item)

  const items: ChecklistItemType[] = [
    ...getChecklist({
      sponsorEmailVerified: emailVerified,
      deal: project,
    }),
  ].map(
    (u, id) => ({
      id,
      label: u.label,
      isChecked: u.checked,
      link: '',
    }),
  );

  const [checklistItems, setChecklistItems] =
    useState<Array<ChecklistItemType>>(items);
  const [isCompleted, setIsCompleted] = useState<boolean>();

  useEffect(() => {
    setChecklistItems(items);
  }, [items.toString(), project, emailVerified]);

  useEffect(() => {
    if (items.every((item) => item.isChecked)) {
      setIsCompleted(true);
    } else setIsCompleted(false);
  }, [checklistItems]);

  return (
    <div className={s.Checklist}>
      <div className={s.Checklist__header}>
        <h2 className={s.Checklist__heading}>Project Checklist</h2>
        {project.approved ? null : approving ? '...' : (
          <BasicButton
            className="grey"
            onClick={() => toggleApproved()}
          >
            Approve
          </BasicButton>
        )}
      </div>
      {isCompleted ? (
        <div className={s.Checklist__completed}>
          <CompleteIcon />
          <p>Your checklist is complete!</p>
          <p
            className={s.Checklist__undo}
            onClick={() => setIsCompleted(false)}
          >
            Undo
          </p>
        </div>
      ) : (
        <>
          <p className={s.Checklist__description}>
            The following items <strong>must be completed</strong> before your
            deal can be sent to capital sources.{' '}
            <a
              href={sinatraDealUrl(project.sym, SinatraDealTab.view)}
              className={s.Checklist__link}
            >
              <strong>Preview</strong>
            </a>
          </p>
          <div className={s.Checklist__list}>
            {checklistItems.map((item, index) => (
              <ChecklistItem key={item.id} item={item} index={index} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Checklist;

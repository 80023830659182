import {RefObject, useEffect} from 'react';
import {FormikHelpers, FormikValues} from 'formik';

import {StandardUsAddress} from 'core/service/ava';

type googleAutocompleteParams = {
  mainAddressField: RefObject<HTMLInputElement>;
  setFieldValue?: Pick<FormikHelpers<FormikValues>, 'setFieldValue'>['setFieldValue'];
  setAddress?: (address: StandardUsAddress) => void;
  line1: string;
};

const useGoogleAutocomplete = ({mainAddressField, setFieldValue, setAddress, line1}: googleAutocompleteParams) => {
  useEffect(() => {
    if (!mainAddressField.current) {
      return;
    }

    const autoComplete = new window.google.maps.places.Autocomplete(mainAddressField.current, {
      componentRestrictions: {country: 'us'},
      fields: ['formatted_address', 'address_components', 'geometry'],
      types: ['address'],
      strictBounds: false,
    });

    autoComplete.addListener('place_changed', () => {
      const addressObject = autoComplete.getPlace();
      const responseQuery = addressObject.formatted_address;
      let addr = new StandardUsAddress();

      if (responseQuery) {
        if (setFieldValue) {
          setFieldValue('address.line1', responseQuery.split(',')[0]);
        }
        if (setAddress) {
          addr = addr.setLine1(responseQuery.split(',')[0]).setLine2('');
        }
      }

      addressObject?.address_components?.forEach((component) => {
        const componentType = component.types[0];

        switch (componentType) {
          case 'postal_code':
            if (setFieldValue) {
              setFieldValue('address.zip', component.short_name);
            }
            addr = addr.setZip(component.short_name);
            break;
          case 'locality':
            if (setFieldValue) {
              setFieldValue('address.city', component.long_name);
            }
            addr = addr.setCity(component.long_name);
            break;
          case 'administrative_area_level_1':
            if (setFieldValue) {
              setFieldValue('address.state', component.short_name);
            }
            addr = addr.setState(component.short_name);
            break;
          default:
            break;
        }
      });

      if (setAddress) {
        setAddress(addr);
      }
    });
  }, [line1]);
};

export default useGoogleAutocomplete;

import {PrepayPenaltySubsteps} from 'core/_consts';
import {FC, useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import AnyLockout from './AnyLockout';
import AnyPenalty from './AnyPenalty';
import FeeAmount from './FeeAmount';
import InterestPerionDuration from './InterestPerionDuration';
import LockoutDuration from './LockoutDuration';
import PenaltyDetails from './PenaltyDetails';
import PenaltyType from './PenaltyType';
import PrepaymentPenalty from './PrepaymentPenalty';

type PrepayPenaltySubstepsKeys = keyof typeof PrepayPenaltySubsteps;

const PrepayPenalty: FC = () => {
  const location = useLocation();
  const [substep, setSubstep] = useState<PrepayPenaltySubstepsKeys | null>();

  useEffect(() => {
    setSubstep(new URLSearchParams(location.search).get('substep') as PrepayPenaltySubstepsKeys);
  }, [location.search]);

  switch (substep) {
    case PrepayPenaltySubsteps[PrepayPenaltySubsteps.anyPenalty]:
      return <AnyPenalty />;

    case PrepayPenaltySubsteps[PrepayPenaltySubsteps.penaltyType]:
      return <PenaltyType />;

    case PrepayPenaltySubsteps[PrepayPenaltySubsteps.anyLockout]:
      return <AnyLockout />;

    case PrepayPenaltySubsteps[PrepayPenaltySubsteps.lockoutDuration]:
      return <LockoutDuration />;

    case PrepayPenaltySubsteps[PrepayPenaltySubsteps.prepaymentPenalty]:
      return <PrepaymentPenalty />;

    case PrepayPenaltySubsteps[PrepayPenaltySubsteps.feeAmount]:
      return <FeeAmount />;

    case PrepayPenaltySubsteps[PrepayPenaltySubsteps.minimumInterestPerion]:
      return <InterestPerionDuration />;

    case PrepayPenaltySubsteps[PrepayPenaltySubsteps.penaltyDetails]:
      return <PenaltyDetails />;

    default:
      return null;
  }
};

export default PrepayPenalty;

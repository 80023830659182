import {FC, ReactElement, useState} from 'react';
import {useDispatch} from 'react-redux';

// MUI
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Icons
import {MenuItem, MenuList, Popover} from '@mui/material';

import {ReactComponent as DownloadIcon} from 'assets/icons/DownloadIcon.svg';
import {ReactComponent as RemoveIcon} from 'assets/icons/trash.svg';
import {ReactComponent as ImageIcon} from 'assets/icons/image.svg';
import {ReactComponent as PdfIcon} from 'assets/icons/pdf.svg';
import {ReactComponent as SpreadSheetIcon} from 'assets/icons/spreadsheet.svg';
import {ReactComponent as OtherIcon} from 'assets/icons/other_attachment.svg';
import {ReactComponent as TextIcon} from 'assets/icons/text.svg';

import {Attachment, AttachmentType} from 'core/service/ava';

import {setAttachmentTypes} from 'store/project-service/asyncActions';

import s from './Attachments.module.scss';

const TYPE_OPTIONS =
  (Array.from(AttachmentType) as AttachmentType[])
    .sort(
      (a, b) =>
        a === AttachmentType.UNSET ? 1
        : b === AttachmentType.UNSET ? -1
        : AttachmentType.format(a) < AttachmentType.format(b) ? -1 : 1,
    );

type AttachmentPropsType = {
  dealSym: string,
  attachment: Attachment,
  handleAttachmentRemove: (sym: string) => void,
};

const AttachmentComponent: FC<AttachmentPropsType> = ({
  dealSym,
  attachment,
  handleAttachmentRemove,
}) => {
  const dispatch = useDispatch();

  const getIconByFileName = (fileName: string) => {
    const m = /\.(\w+)$/.exec(fileName);
    const ext = m ? m[1] : '';
    return getIconByFileType(ext);
  };

  const getIconByFileType = (fileType: string): ReactElement => {
    switch (fileType) {
      case 'pdf':
        return <PdfIcon />;
      case 'doc':
        return <TextIcon />;
      case 'docx':
        return <TextIcon />;
      case 'txt':
        return <TextIcon />;
      case 'rtf':
        return <TextIcon />;
      case 'xlsx':
        return <SpreadSheetIcon />;
      case 'jpg':
        return <ImageIcon />;
      case 'png':
        return <ImageIcon />;
      default:
        return <OtherIcon />;
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [type, setType] = useState<AttachmentType>(attachment.primaryType);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'dropdown-menu' : undefined;

  return (
    <div className={s.attachment}>
      <div className={s.attachment__details}>
        {getIconByFileName(attachment.name)}
        <div>
          <p className={s.attachment__name} title={attachment.name}>
            {attachment.name}
          </p>
          <ul className={s.attachment__data}>
            <li className={s.attachment__date}>
              {attachment.addedTime.formatHumanAbbr()}
            </li>
          </ul>
        </div>
      </div>
      <div className={s.actions}>
        <a href={attachment.url} target="_blank" rel="noreferrer" download>
          <DownloadIcon className={s.attachment__icon} />
        </a>
        <RemoveIcon
          className={s.attachment__icon}
          onClick={() => handleAttachmentRemove(attachment.sym)}
        />
        <div className={s.header__dropdown}>
          <div className={s.actions__type} onClick={handleClick}>
            {
              type === AttachmentType.UNSET
                ? 'Type'
                : AttachmentType.format(type)
            }
            <KeyboardArrowDownIcon />
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <MenuList autoFocusItem style={{maxHeight: '200px'}}>
              {TYPE_OPTIONS.map((type) => (
                <MenuItem
                  key={type}
                  onClick={() => {
                    setType(type);
                    dispatch(setAttachmentTypes({
                      dealSym,
                      attachmentSym: attachment.sym,
                      primaryType: type,
                    }));
                    handleClose();
                  }}
                  style={{justifyContent: 'flex-start', color: '#848484'}}
                >
                  {AttachmentType.format(type) || 'Other'}
                </MenuItem>
              ))}
            </MenuList>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default AttachmentComponent;

import {FC, useState, useLayoutEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Form, Formik} from 'formik';
import cx from 'classnames';

import {IconButton} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';

import {ROUTERS} from 'core/_consts';
import {lastSegmentOfPath} from 'core/utils';
import {NewProject} from 'core/_types/NewProject';

import StepSlider from 'shared/components/StepSlider';
import Header from 'shared/components/Header';
import {THEMES} from 'shared/helpers';

import ProjectView from 'pages/ProjectView';
import LenderView from 'pages/LenderView';
import {ProjectViewTabs} from 'pages/ProjectView/helpers';

import {
  AssetType,
  InvestmentProperty,
  Tenants,
  Second,
  Third,
  Fourth,
  Fifth,
  Sixth,
  Seventh,
  Eighth,
  Ninth,
  Tenth,
  Eleventh,
  Twelfth,
} from '../Steps';
import {AssetTypes, NewDealSliderSteps, NewUserDealSteps} from '../helpers';

import s from './CreateNewDealWrapper.module.scss';

const useStyles = makeStyles({
  button: {
    position: 'absolute',
    padding: 0,
    color: 'white',
    font: '600 18px Source Sans Pro',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '@media(max-width: 768px)': {
      display: '',
    },
  },
  close: {
    right: 20,
    top: 20,
    zIndex: 1,
    '@media(max-width: 425px)': {
      top: 14,
      right: 14,
    },
  },
  back: {
    left: 20,
    bottom: 20,
    gap: '5px',
  },
  next: {
    right: 20,
    bottom: 20,
    gap: '5px',
  },
});

const stepsTitles = Array(12)
  .fill('Step')
  .map((item, index) => `${item} ${index + 1}`);

type StepKeysType = keyof typeof NewUserDealSteps;

const UserCreateNewDealWrapper: FC = () => {
  const [isStartPoint, setIsStartPoint] = useState(false);
  const [step, setStep] = useState<StepKeysType>(NewUserDealSteps[NewUserDealSteps['asset-type']] as StepKeysType);

  const history = useHistory();
  const location = useLocation();

  const classes = useStyles();

  const office = AssetTypes[AssetTypes.Office];
  const hospitality = AssetTypes[AssetTypes.Hospitality];
  const multiFamilyAcquisition = NewDealSliderSteps[NewDealSliderSteps['multiFamily-acquisition']];
  const assetType = NewDealSliderSteps[NewDealSliderSteps['asset-type']];
  const investmentProperty = NewDealSliderSteps[NewDealSliderSteps['investment-property']];

  const renderStep = () => {
    switch (step) {
      case NewUserDealSteps[NewUserDealSteps['asset-type']]:
        return <AssetType goNext={goNext} />;

      case NewUserDealSteps[NewUserDealSteps['investment-property']]:
        return <InvestmentProperty goNext={goNext} />;

      case NewUserDealSteps[NewUserDealSteps.tenants]:
        return <Tenants goNext={goNext} />;

      case NewUserDealSteps[NewUserDealSteps['multiFamily-acquisition']]:
        return <Second goNext={goNext} />;

      case NewUserDealSteps[NewUserDealSteps['financing-purpose']]:
        return <Third goNext={goNext} />;

      case NewUserDealSteps[NewUserDealSteps.address]:
        return <Fourth />;

      case NewUserDealSteps[NewUserDealSteps['property-income']]:
        return <Fifth />;

      case NewUserDealSteps[NewUserDealSteps['project-budget']]:
        return <Sixth />;

      case NewUserDealSteps[NewUserDealSteps['financing-request']]:
        return <Seventh />;

      case NewUserDealSteps[NewUserDealSteps['financing-details']]:
        return <Eighth />;

      case NewUserDealSteps[NewUserDealSteps.attachments]:
        return <Ninth />;

      case NewUserDealSteps[NewUserDealSteps['sponsor-role']]:
        return <Eleventh goNext={goNext} />;

      case NewUserDealSteps[NewUserDealSteps.guarantors]:
        return <Twelfth goNext={goNext} />;

      case NewUserDealSteps[NewUserDealSteps['project-summary']]:
        return <Tenth isFinal />;

      default:
        return null;
    }
  };

  const initialValues: NewProject = {
    targetCloseDate: '',
    address: {
      line1: '',
      city: '',
      state: '',
      zip: '',
    },
    assetType: '',
    investmentProperty: '',
    tenantsType: '',
    tenants: '',
    hotelFlag: '',
    hotelExpirationDate: '',
    financingPurpose: '',
    sponsorRole: '',
    multiFamilyAcquisition: '',
    loanTerm: '',
    amortization: '',
    recourse: '',
    interestRate: '',
    notes: '',
  };

  const data = localStorage.getItem('projectInitialValues');
  const projectInitialValues = data && JSON.parse(data);

  const goNext = (stepAmount?: number) => {
    if (!stepAmount) {
      const currentStep = NewUserDealSteps[
        NewUserDealSteps[step as keyof typeof NewUserDealSteps] + 1
      ] as StepKeysType;

      setStep(currentStep);
      history.replace(`${ROUTERS.CREATE_NEW_PROJECT}/${currentStep}`);
    }

    if (stepAmount) {
      const currentStep = NewUserDealSteps[
        NewUserDealSteps[step as keyof typeof NewUserDealSteps] + stepAmount
      ] as StepKeysType;

      setStep(currentStep);
      history.replace(`${ROUTERS.CREATE_NEW_PROJECT}/${currentStep}`);
    }
  };

  const nextStep = (values: any, stepAmount: number) => {
    const currentStep = NewUserDealSteps[
      NewUserDealSteps[step as keyof typeof NewUserDealSteps] + stepAmount
    ] as StepKeysType;

    setStep(currentStep);
    localStorage.setItem('projectInitialValues', JSON.stringify(values));
    history.replace(`${ROUTERS.CREATE_NEW_PROJECT}/${currentStep}`);
  };

  const prevStep = (stepAmount: number) => {
    if (!isStartPoint) {
      const currentStep = NewUserDealSteps[
        NewUserDealSteps[step as keyof typeof NewUserDealSteps] - stepAmount
      ] as StepKeysType;
      setStep(currentStep);
      history.replace(`${ROUTERS.CREATE_NEW_PROJECT}/${currentStep}`);
    }
  };

  useLayoutEffect(() => {
    const currentStep =
      (lastSegmentOfPath(location.pathname) as StepKeysType) || NewUserDealSteps[NewUserDealSteps['asset-type']];
    setStep(currentStep);
    setIsStartPoint(currentStep === NewUserDealSteps[NewUserDealSteps['asset-type']]);
  }, [step]);

  if (
    location.pathname.includes(ProjectViewTabs[ProjectViewTabs.underwriting]) ||
    location.pathname.includes(ProjectViewTabs[ProjectViewTabs['capital-sources']]) ||
    location.pathname.includes(ProjectViewTabs[ProjectViewTabs.offers])
  )
    return <ProjectView />;

  if (location.pathname.includes('view')) return <LenderView />;

  return (
    <>
      <Header />
      <Formik
        enableReinitialize
        initialValues={projectInitialValues || initialValues}
        // eslint-disable-next-line no-console
        onSubmit={(values) => console.log('Values: ', values)}
      >
        {({values}) => (
          <Form>
            <div className={s.createNewDealWrapper}>
              <IconButton
                className={cx(classes.button, classes.close)}
                disableRipple
                onClick={() => history.push(ROUTERS.DASHBOARD)}
                size="small"
              >
                <CloseIcon />
              </IconButton>

              <div className={s.createNewDealWrapper__content}>{renderStep()}</div>
              <div className={s.createNewDealWrapper__sliderWrapper}>
                {step && (
                  <StepSlider
                    titles={stepsTitles}
                    theme={THEMES.white}
                    steps={Object.values(NewUserDealSteps)}
                    currentStep={NewUserDealSteps[step as keyof typeof NewUserDealSteps]}
                  />
                )}
              </div>

              {!isStartPoint && (
                <IconButton
                  className={cx(classes.button, classes.back, s.createNewDealWrapper__backBtn)}
                  disableRipple
                  onClick={() => {
                    if (step === multiFamilyAcquisition) {
                      if (values.assetType !== office && values.assetType !== hospitality) {
                        prevStep(3);
                      } else prevStep(1);
                      if (values.assetType === hospitality) {
                        prevStep(2);
                      }
                    } else prevStep(1);
                  }}
                  size="small"
                >
                  <ArrowBackIcon />
                  <span>Back</span>
                </IconButton>
              )}
              <IconButton
                className={cx(classes.button, classes.next, s.createNewDealWrapper__nextBtn)}
                disableRipple
                onClick={() => {
                  if (step === assetType && values.assetType !== office && values.assetType !== hospitality) {
                    nextStep(values, 3);
                  } else nextStep(values, 1);
                  if (step === investmentProperty && values.assetType === hospitality) {
                    nextStep(values, 2);
                  }
                }}
                size="small"
              >
                <span>Next</span>
                <ArrowForwardIcon />
              </IconButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UserCreateNewDealWrapper;

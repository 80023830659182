import {FC, ReactElement, useMemo} from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import cx from 'classnames';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {FieldInputProps} from 'formik';

import {THEMES} from 'shared/helpers';
import BasicTooltip from './BasicTooltip';

type SelectInputProps = {
  width?: string;
  options: Array<string>;
  label?: string;
  labelTooltip?: ReactElement | string;
  field?: FieldInputProps<any>;
  isValid?: boolean;
  activeValue?: string;
  theme?: string;
  className?: string;
  placeholder?: string;
  require?: boolean;
};

const SelectInput: FC<SelectInputProps> = ({
  label,
  options,
  field,
  isValid,
  activeValue,
  theme,
  labelTooltip,
  width,
  require,
}) => {
  const borderColor = theme === THEMES.white ? 'white' : '#848484';
  const iconColor = theme === THEMES.white ? 'white' : 'black';
  const labelColor = theme === THEMES.white ? 'white' : '#757575';

  const useStyles = useMemo(
    () =>
      makeStyles({
        root: {
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          minWidth: '180px',
        },
        label: {
          color: labelColor,
          fontSize: 14,
          fontWeight: 600,
        },
        select: {
          '& .MuiNativeSelect-icon': {
            right: '5px',
            color: iconColor,
          },
          '& .MuiNativeSelect-select': {
            padding: '0 0 0 5px',
            color: iconColor,
            fontFamily: 'Source Sans Pro',
            fontSize: 14,
            fontWeight: 400,
          },
        },
        valid: {
          '& .MuiNativeSelect-select': {
            border: '1px solid #5999d8',
          },
        },
        value: {
          color: labelColor,
          fontSize: 14,
        },
        placeholder: {
          color: labelColor,
        },
        hint: {
          alignItems: 'center',
          display: 'inline-flex',
          justifyContent: 'center',
          width: '14px',
          height: '14px',
          borderRadius: '50%',
          backgroundColor: '#fff',
          color: '#000',
          cursor: 'default',
          marginLeft: '4px',
          marginTop: '2px',
        },
      }),
    [theme]
  );

  const classes = useStyles();

  const StyledInput = useMemo(
    () =>
      withStyles(() =>
        createStyles({
          input: {
            boxSizing: 'border-box',
            height: '42px',
            border: `1px solid ${borderColor}`,
            borderRadius: '5px',
            fontSize: '16px',
            outline: 'none',
            '&:focus': {
              border: '1px solid #5999d8',
              borderRadius: '5px',
              backgroundColor: 'transparent',
            },
            width,
          },
        })
      )(InputBase),
    [theme]
  );
  return (
    <div className={cx(classes.root)}>
      <div className={classes.label}>
        {label}
        {labelTooltip && (
          <span>
            <BasicTooltip title={labelTooltip} arrow placement="bottom">
              <p className={classes.hint}> ?</p>
            </BasicTooltip>
          </span>
        )}
      </div>
      <NativeSelect
        {...field}
        className={cx(classes.select, {
          [classes.valid]: isValid ?? !!field?.value,
        })}
        value={activeValue || field?.value}
        input={<StyledInput fullWidth margin="dense" />}
        IconComponent={ExpandMoreIcon}
        defaultValue=""
        required={require}
      >
        <option disabled>{label}</option>
        {options.map((item) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </NativeSelect>
    </div>
  );
};

export default SelectInput;

import {FC, useState} from 'react';
import {useFormikContext} from 'formik';

// Pages
import SelectionCard from 'pages/CreateNewDeal/SelectionCard';
import {AssetTypes, isValue} from 'pages/CreateNewDeal/helpers';

import s from './AssetType.module.scss';

type AssetTypeProps = {
  goNext: (stepAmount?: number) => void;
};

const AssetType: FC<AssetTypeProps> = ({goNext}) => {
  const {values, setFieldValue}: any = useFormikContext();

  const office = AssetTypes[AssetTypes.Office];
  const hospitality = AssetTypes[AssetTypes.Hospitality];

  const [selectedCard, setSelectedCard] = useState(values.assetType);

  const iconTitles = Object.keys(AssetTypes).filter(isValue);

  return (
    <div className={s.first}>
      <div className={s.first__header}>
        <p className={s.first__title}>
          What is the primary asset type of your project?
        </p>
        <p className={s.first__subtitle}>
          If your project is mixed use, choose the asset type which represents
          the greatest portion.
        </p>
      </div>
      <div className={s.first__cards}>
        {iconTitles.map((iconTitle) => (
          <SelectionCard
            key={iconTitle}
            selectedCard={selectedCard}
            handleCardSelection={() => {
              setFieldValue('assetType', iconTitle);
              setSelectedCard(iconTitle);
              if (iconTitle === office || iconTitle === hospitality) goNext();
              else goNext(3);
            }}
            title={iconTitle}
            iconName={iconTitle.toLocaleLowerCase()}
          />
        ))}
      </div>
    </div>
  );
};

export {AssetType};

import {useState} from 'react';
import {validateNumericInput} from 'core/utils';
import {LoanFeesSubsteps, MaxValidationLimits, QuoteTermsSteps} from 'core/_consts';
import NextButton from 'shared/components/NextButton';
import {setInitialDeposit} from 'store/quote-terms-service/quoteTermsSlice';
import NumericInput from 'shared/components/NumericInput';
import {useSelector} from 'react-redux';
import {selectInitialDeposit} from 'store/quote-terms-service/quoteTermsSelector';

const InitialDeposit = () => {
  const defaultValue = useSelector(selectInitialDeposit);
  const [initialDepositValue, setInitialDepositValue] = useState(defaultValue);

  return (
    <div className="FormSlide">
      <div className="FormSlide__heading">
        <p className="FormSlide__title">Enter the required initial deposit amount.</p>
        <p className="FormSlide__subTitle mb25">You can skip this step if it&apos;s not needed.</p>
      </div>
      <NumericInput
        defaultValue={defaultValue || ''}
        onValueChange={(data) => setInitialDepositValue(Number(data.value))}
        preventionLimit={MaxValidationLimits.highestLimit}
        thousandSeparator
        textAlign="left"
        isValid={validateNumericInput(initialDepositValue, MaxValidationLimits.highestLimit)}
        width="205px"
        sign="$"
        reversed
      />
      <NextButton
        isDisabled={false}
        slideData={{[QuoteTermsSteps[QuoteTermsSteps.initialDeposit]]: initialDepositValue}}
        action={setInitialDeposit}
        nextSlide={{
          step: QuoteTermsSteps[QuoteTermsSteps.loanFees],
          substep: LoanFeesSubsteps[LoanFeesSubsteps.selectLoanFees],
        }}
      />
    </div>
  );
};

export default InitialDeposit;

import {dateOptions} from 'core/_consts';
import {BorrowerStipulationsValue, quoteTermsStorageType} from 'core/_types/quoteTermsTypes';
import {
  IAmortization,
  ICashManagement,
  IInerestRate,
  ILoanAmount,
  ILoanFees,
  ILoanTerm,
  IPrepayPenalty,
  IRecourse,
  IRequiredReserves,
} from 'store/quote-terms-service/interfaces';

export class QuoteTermsInitialValues {
  loanAmount: ILoanAmount;

  loanTerm: ILoanTerm;

  interestRate: IInerestRate;

  amortization: IAmortization;

  recourse: IRecourse;

  prepayPenalty: IPrepayPenalty;

  initialDeposit: number;

  loanFees: ILoanFees;

  requiredReserves: IRequiredReserves;

  cashManagement: ICashManagement;

  borrowerStipulations: BorrowerStipulationsValue;

  otherRequirements: string;

  constructor(data?: quoteTermsStorageType) {
    this.loanAmount = {
      loanSizingConstraints: data?.loanSizingConstraints || {
        LTC: null,
        LTV: null,
        DSCR: null,
        DebtYield: null,
      },
      dollarLoanAmount: data?.dollarLoanAmount || null,
    };

    this.loanTerm = {
      loanTermDuration: data?.loanTermDuration || [],
      lengthAndFee: data?.lengthAndFee || {
        initialTerm: null,
        initialTermDateFormat: '',
        originationFee: null,
        originationFeeValueFormat: '',
        extensions: [],
      },
      anyExtensions: data?.anyExtensions || [],
      extensionsAmount: data?.extensionsAmount || [],
      customLoanTerm: data?.customLoanTerm || {
        dateValue: dateOptions.years,
        termValue: 0,
        isAnyExtensions: [],
      },
    };

    this.interestRate = {
      rateResets: data?.rateResets || [],
      resetYear: data?.resetYear || [],
      rateDetails: data?.rateDetails || {
        selectedRateDetails: [],
        fixedPercent: '',
        fixedSpread: {
          index: '',
          spread: '',
          fixedAt: '',
          rateFloor: '',
          indexFloor: '',
        },
        floating: {
          index: '',
          spread: '',
          rateFloor: '',
          indexFloor: '',
        },
      },
      rateCalculation: data?.rateCalculation || [],
    };

    this.amortization = {
      amortizationPeriod: data?.amortizationPeriod || [],
      interestOnlyPeriod: data?.interestOnlyPeriod || [],
      interestOnlyPeriodDuration: data?.interestOnlyPeriodDuration || {
        dateFormat: dateOptions.years,
        dateValue: 0,
      },
      amount: data?.amount || {
        dateFormat: dateOptions.years,
        amortizationAmount: 0,
      },
    };

    this.recourse = {
      availableRecourse: data?.availableRecourse || {
        selectedRecourse: [],
        partialRecoursePercent: 0,
      },
      burnOffTrigger: data?.burnOffTrigger || {
        selectedBurn: [],
        numericInputValue: 0,
        otherValue: '',
      },
      burnOffAvailable: data?.burnOffAvailable || {
        isRecourseBurn: [],
        whatDoesItBurnTo: [],
        partialRecoursePercent: 0,
      },
    };

    this.prepayPenalty = {
      anyPenalty: data?.anyPenalty || [],
      penaltyType: data?.penaltyType || [],
      anyLockout: data?.anyLockout || [],
      lockoutDuration: data?.lockoutDuration || {
        dateFormat: dateOptions.years,
        lockoutDurationValue: 0,
      },
      prepaymentPenalty: data?.prepaymentPenalty || {years: []},
      feeAmount: data?.feeAmount || 0,
      minimumInterestPerion: data?.minimumInterestPerion || {
        dateFormat: dateOptions.years,
        interestOnlyPeriodDuration: 0,
      },
      penaltyDetails: data?.penaltyDetails || '',
    };

    this.initialDeposit = data?.initialDeposit || 0;

    this.loanFees = {
      selectLoanFees: data?.selectLoanFees || [],
      feesDetails: data?.feesDetails || [],
    };

    this.requiredReserves = {
      selectRequiredReserves: data?.selectRequiredReserves || [],
      reservesDetails: data?.reservesDetails || [],
    };

    this.cashManagement = {
      isLockboxNeeded: data?.isLockboxNeeded || [],
      selectLockboxType: data?.selectLockboxType || [],
    };

    this.borrowerStipulations = data?.borrowerStipulations || {
      creditScore: null,
      netWorth: null,
      liquidityAfterClosing: null,
    };

    this.otherRequirements = data?.otherRequirements || '';
  }
}

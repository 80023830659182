import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {QuoteTermsInitialValues} from 'core/utils/quoteTermsValues';
import {
  AmortizationPeriodDurationValues,
  AmortizationsAmountValues,
  AvailableRecourseValues,
  BurnOffAvailableValues,
  BurnOffTriggerValues,
  InterestPerionDurationValues,
  LengthAndFeeValues,
  LockoutDurationValues,
  FeesDetailsValue,
  ReservesDetailsValue,
  SizingConstrainsValues,
  TermDurationValues,
  BorrowerStipulationsValue,
  IRateDetailsSlideData,
  PrepaymentPenaltyValues,
} from 'core/_types/quoteTermsTypes';
import {IQuoteTermsReducer} from './interfaces/index';

const initialState: {data: IQuoteTermsReducer} = {
  data: new QuoteTermsInitialValues(),
};

const quoteTermsSlice = createSlice({
  name: 'quoteTerms',
  initialState,
  reducers: {
    setInitialValues: (state, action: PayloadAction<IQuoteTermsReducer>) => {
      state.data = action.payload;
    },

    setLoanSizingConstraints: (state, action: PayloadAction<SizingConstrainsValues>) => {
      state.data.loanAmount.loanSizingConstraints = action.payload;
    },
    setDollarLoanAmount: (state, action: PayloadAction<number>) => {
      state.data.loanAmount.dollarLoanAmount = action.payload;
    },

    setLoanTermDuration: (state, action: PayloadAction<Array<string>>) => {
      state.data.loanTerm.loanTermDuration = action.payload;
    },
    setLengthAndFee: (state, action: PayloadAction<LengthAndFeeValues>) => {
      state.data.loanTerm.lengthAndFee = action.payload;
    },
    setAnyExtensions: (state, action: PayloadAction<Array<string>>) => {
      state.data.loanTerm.anyExtensions = action.payload;
    },
    setExtensionsAmount: (state, action: PayloadAction<Array<string>>) => {
      state.data.loanTerm.extensionsAmount = action.payload;
    },
    setTermDuration: (state, action: PayloadAction<TermDurationValues>) => {
      state.data.loanTerm.customLoanTerm = action.payload;
    },

    setRateResets: (state, action: PayloadAction<Array<string>>) => {
      state.data.interestRate.rateResets = action.payload;
    },
    setResetYear: (state, action: PayloadAction<Array<string>>) => {
      state.data.interestRate.resetYear = action.payload;
    },
    setRateDetails: (state, action: PayloadAction<IRateDetailsSlideData>) => {
      state.data.interestRate.rateDetails = action.payload;
    },
    setRateCalculation: (state, action: PayloadAction<Array<string>>) => {
      state.data.interestRate.rateCalculation = action.payload;
    },

    setAmortizationPeriod: (state, action: PayloadAction<Array<string>>) => {
      state.data.amortization.amortizationPeriod = action.payload;
    },
    setInterestOnlyPeriod: (state, action: PayloadAction<Array<string>>) => {
      state.data.amortization.interestOnlyPeriod = action.payload;
    },
    setInterestOnlyPeriodDuration: (state, action: PayloadAction<AmortizationPeriodDurationValues>) => {
      state.data.amortization.interestOnlyPeriodDuration = action.payload;
    },
    setAmount: (state, action: PayloadAction<AmortizationsAmountValues>) => {
      state.data.amortization.amount = action.payload;
    },

    setAvailableRecourse: (state, action: PayloadAction<AvailableRecourseValues>) => {
      state.data.recourse.availableRecourse = action.payload;
    },
    setBurnOffTrigger: (state, action: PayloadAction<BurnOffTriggerValues>) => {
      state.data.recourse.burnOffTrigger = action.payload;
    },
    setBurnOffAvailable: (state, action: PayloadAction<BurnOffAvailableValues>) => {
      state.data.recourse.burnOffAvailable = action.payload;
    },

    setAnyPenalty: (state, action: PayloadAction<Array<string>>) => {
      state.data.prepayPenalty.anyPenalty = action.payload;
    },
    setPenaltyType: (state, action: PayloadAction<Array<string>>) => {
      state.data.prepayPenalty.penaltyType = action.payload;
    },
    setAnyLockout: (state, action: PayloadAction<Array<string>>) => {
      state.data.prepayPenalty.anyLockout = action.payload;
    },
    setLockoutDuration: (state, action: PayloadAction<LockoutDurationValues>) => {
      state.data.prepayPenalty.lockoutDuration = action.payload;
    },
    setPrepaymentPenalty: (state, action: PayloadAction<PrepaymentPenaltyValues>) => {
      state.data.prepayPenalty.prepaymentPenalty = action.payload;
    },
    setFeeAmount: (state, action: PayloadAction<number>) => {
      state.data.prepayPenalty.feeAmount = action.payload;
    },
    setInterestPerionDuration: (state, action: PayloadAction<InterestPerionDurationValues>) => {
      state.data.prepayPenalty.minimumInterestPerion = action.payload;
    },
    setPenaltyDetails: (state, action: PayloadAction<string>) => {
      state.data.prepayPenalty.penaltyDetails = action.payload;
    },

    setInitialDeposit: (state, action: PayloadAction<number>) => {
      state.data.initialDeposit = action.payload;
    },

    setSelectedLoanFeesValues: (state, action: PayloadAction<Array<string>>) => {
      state.data.loanFees.selectLoanFees = action.payload;
    },
    setLoanFeesDetails: (state, action: PayloadAction<Array<FeesDetailsValue>>) => {
      state.data.loanFees.feesDetails = action.payload;
    },

    setSelectRequiredReserves: (state, action: PayloadAction<Array<string>>) => {
      state.data.requiredReserves.selectRequiredReserves = action.payload;
    },
    setReservesDetails: (state, action: PayloadAction<Array<ReservesDetailsValue>>) => {
      state.data.requiredReserves.reservesDetails = action.payload;
    },

    setIsLockboxNeeded: (state, action: PayloadAction<Array<string>>) => {
      state.data.cashManagement.isLockboxNeeded = action.payload;
    },
    setSelectLockboxType: (state, action: PayloadAction<Array<string>>) => {
      state.data.cashManagement.selectLockboxType = action.payload;
    },

    setBorrowerStipulations: (state, action: PayloadAction<BorrowerStipulationsValue>) => {
      state.data.borrowerStipulations = action.payload;
    },

    setOtherRequirements: (state, action: PayloadAction<string>) => {
      state.data.otherRequirements = action.payload;
    },
  },
});

export const {
  setInitialValues,

  setLoanSizingConstraints,
  setDollarLoanAmount,

  setLoanTermDuration,
  setLengthAndFee,
  setAnyExtensions,
  setExtensionsAmount,
  setTermDuration,

  setRateResets,
  setResetYear,
  setRateDetails,
  setRateCalculation,

  setAmortizationPeriod,
  setInterestOnlyPeriod,
  setInterestOnlyPeriodDuration,
  setAmount,

  setAvailableRecourse,
  setBurnOffTrigger,
  setBurnOffAvailable,

  setAnyPenalty,
  setPenaltyType,
  setAnyLockout,
  setLockoutDuration,
  setPrepaymentPenalty,
  setFeeAmount,
  setInterestPerionDuration,
  setPenaltyDetails,

  setInitialDeposit,

  setSelectedLoanFeesValues,
  setLoanFeesDetails,

  setSelectRequiredReserves,
  setReservesDetails,

  setIsLockboxNeeded,
  setSelectLockboxType,

  setBorrowerStipulations,

  setOtherRequirements,
} = quoteTermsSlice.actions;

export default quoteTermsSlice.reducer;

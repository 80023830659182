import {RecourseSubsteps} from 'core/_consts';
import {FC, useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import AvailableRecourse from './AvailableRecourse';
import BurnOffAvailable from './BurnOffAvailable';
import BurnOffTrigger from './BurnOffTrigger';

type RecourseSubstepsKeys = keyof typeof RecourseSubsteps;

const Recourse: FC = () => {
  const location = useLocation();
  const [substep, setSubstep] = useState<RecourseSubstepsKeys | null>();

  useEffect(() => {
    setSubstep(new URLSearchParams(location.search).get('substep') as RecourseSubstepsKeys);
  }, [location.search]);

  switch (substep) {
    case RecourseSubsteps[RecourseSubsteps.availableRecourse]:
      return <AvailableRecourse />;

    case RecourseSubsteps[RecourseSubsteps.burnOffTrigger]:
      return <BurnOffTrigger />;

    case RecourseSubsteps[RecourseSubsteps.burnOffAvailable]:
      return <BurnOffAvailable />;

    default:
      return null;
  }
};

export default Recourse;

import {useSelector} from 'react-redux';

import {selectorGetProfile} from 'store/ava-service/selector';

// Components
import {CreateNewDealWrapper, UserCreateNewDealWrapper} from './CreateNewDealWrapper';

import s from './styles.module.scss';

const CreateNewDeal = () => {
  const userProfile = useSelector(selectorGetProfile);

  return (
    <div className={s.createNewDeal}>{userProfile ? <UserCreateNewDealWrapper /> : <CreateNewDealWrapper />}</div>
  );
};

export default CreateNewDeal;

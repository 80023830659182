import {useState} from 'react';
import {LoanAmountSubsteps, QuoteTermsSteps, LoanTermSubsteps, MaxValidationLimits, storageKey} from 'core/_consts';
import NextButton from 'shared/components/NextButton';
import {validateNumericInput} from 'core/utils';
import NumericInput from 'shared/components/NumericInput';
import {setDollarLoanAmount} from 'store/quote-terms-service/quoteTermsSlice';
import {useSelector} from 'react-redux';
import {selectDollarLoanAmount} from 'store/quote-terms-service/quoteTermsSelector';
import {vaultService} from 'core/service/storage';

const DollarAmount = () => {
  const defaultValue = useSelector(selectDollarLoanAmount);
  const [dollarAmountValue, setDollarAmountValue] = useState(Number(defaultValue) || '');

  return (
    <div className="FormSlide">
      <p className="FormSlide__title mb35">Enter your dollar loan amount.</p>
      <NumericInput
        defaultValue={dollarAmountValue}
        onValueChange={(values) => setDollarAmountValue(Number(values.value))}
        thousandSeparator
        isValid={validateNumericInput(Number(dollarAmountValue), MaxValidationLimits.highestLimit)}
        width="205px"
        preventionLimit={MaxValidationLimits.highestLimit}
        sign="$"
        reversed
      />
      <NextButton
        sideEffect={() =>
          vaultService.removeItem(
            storageKey.quoteTermsData,
            LoanAmountSubsteps[LoanAmountSubsteps.loanSizingConstraints]
          )
        }
        isDisabled={!dollarAmountValue}
        slideData={{[LoanAmountSubsteps[LoanAmountSubsteps.dollarLoanAmount]]: dollarAmountValue}}
        action={setDollarLoanAmount}
        nextSlide={{
          step: QuoteTermsSteps[QuoteTermsSteps.loanTerm],
          substep: LoanTermSubsteps[LoanTermSubsteps.loanTermDuration],
        }}
      />
    </div>
  );
};

export default DollarAmount;

import {useState} from 'react';
import NumericInput from 'shared/components/NumericInput';
import RadioInput from 'shared/components/RadioInput';
import NextButton from 'shared/components/NextButton';
import {PrepayPenaltySubsteps, QuoteTermsSteps, dateOptions} from 'core/_consts';
import {getMaxLimit, validateNumericInput} from 'core/utils';
import {setInterestPerionDuration} from 'store/quote-terms-service/quoteTermsSlice';
import {selectInterestPerionDuration} from 'store/quote-terms-service/quoteTermsSelector';
import {useSelector} from 'react-redux';

const InterestPerionDuration = () => {
  const defaultValues = useSelector(selectInterestPerionDuration);
  const [dateFormat, setDateFormat] = useState(defaultValues.dateFormat);
  const [interestOnlyPeriodDuration, setInterestOnlyPeriodDuration] = useState(
    defaultValues.interestOnlyPeriodDuration
  );

  return (
    <div className="FormSlide">
      <p className="FormSlide__title mb35">How long is the minimum interest period?</p>
      <div className="FormSlide__section">
        <NumericInput
          width="60px"
          defaultValue={interestOnlyPeriodDuration || ''}
          preventionLimit={getMaxLimit(dateFormat)}
          onValueChange={(values) => setInterestOnlyPeriodDuration(Number(values.value))}
          isValid={validateNumericInput(Number(interestOnlyPeriodDuration), getMaxLimit(dateFormat))}
        />
        <RadioInput
          controlledInputValue={interestOnlyPeriodDuration}
          defaultValue={dateFormat}
          setRadioInputValue={setDateFormat}
          values={Object.values(dateOptions)}
        />
      </div>
      <NextButton
        isDisabled={!validateNumericInput(Number(interestOnlyPeriodDuration), getMaxLimit(dateFormat))}
        slideData={{
          [PrepayPenaltySubsteps[PrepayPenaltySubsteps.minimumInterestPerion]]: {
            dateFormat,
            interestOnlyPeriodDuration,
          },
        }}
        action={setInterestPerionDuration}
        nextSlide={{
          step: QuoteTermsSteps[QuoteTermsSteps.prepayPenalty],
          substep: PrepayPenaltySubsteps[PrepayPenaltySubsteps.anyLockout],
        }}
      />
    </div>
  );
};

export default InterestPerionDuration;

/* eslint-disable prefer-const */
import {createAsyncThunk, Dispatch} from '@reduxjs/toolkit';

import {toast} from 'react-toastify';

import {AvaType, ShareType} from 'core/_types/base';
import {Deal, ava} from 'core/service/ava';

import {setIsLinkCopied, setLoading, setShareContacts, setShareProvider} from './sharesSlice';
import {setProject} from '../project-service/projectSlice';

export const createShare = createAsyncThunk(
  'share/createShare',
  async ({
    ava,
    projectSym,
    providerSym,
    dispatch,
  }: {
    ava: AvaType;
    projectSym: string;
    providerSym: string;
    dispatch: Dispatch;
  }): Promise<Deal | null> => {
    try {
      await ava.shares.createFromProvider(projectSym, providerSym);
      // This is a weird way to force a project update here. There's probably
      // a better way.
      await hackUpdateDeal(dispatch, projectSym);
      return ava.deals.get(projectSym);
    } catch (err: any) {
      toast.error(err.message);
      return null;
    }
  }
);

export const getShares = createAsyncThunk(
  'share/getShares',
  async ({ava, sharesSyms}: {ava: AvaType; sharesSyms: string[]}): Promise<Array<ShareType>> => {
    try {
      const shares = await Promise.all(sharesSyms.map((sym) => ava.shares.get(sym)));

      const data: Array<ShareType> = await Promise.all(
        shares.map(async (share) => {
          const [org, provider] = await Promise.all([
            share.org ? await ava.orgs.get(share.org) : undefined,
            share.provider ? await ava.providers.get(share.provider) : undefined,
          ]);

          return {share, provider, org};
        })
      );

      return data;
    } catch (err: any) {
      toast.error(err.message);
      return [];
    }
  }
);

export const getProviders = createAsyncThunk(
  'share/getProviders',
  async ({share, dispatch}: {share: ShareType; dispatch: Dispatch}): Promise<any> => {
    try {
      dispatch(setLoading({shareSym: share.share.sym, isLoading: true}));

      const provider = await ava.providers.get(share?.share?.provider);

      if (provider) {
        dispatch(setShareProvider({shareSym: share?.share?.sym, provider}));
      }
    } catch (err: any) {
      toast.error(err.message);
    }
  }
);

export const getContacts = createAsyncThunk(
  'share/getContacts',
  async ({share, dispatch}: {share: ShareType; dispatch: Dispatch}): Promise<void> => {
    try {
      if (!share.contacts) {
        dispatch(setLoading({shareSym: share.share.sym, isLoading: true}));

        const contacts = Array.from(await ava.shares.getContacts(share?.share?.sym));
        const persons = await Promise.all(contacts.map((contact) => ava.people.getByEmail(contact?.email)));

        if (persons) {
          dispatch(setShareContacts({shareSym: share?.share?.sym, contacts: persons}));
        }
      }
    } catch (err: any) {
      toast.error(err.message);
    }
  }
);

export const declineShare = createAsyncThunk(
  'share/decline',
  async ({ava, shareSym}: {ava: AvaType; shareSym?: string}) => {
    try {
      if (shareSym) {
        await ava.shares.decline(shareSym);
      }
    } catch (err: any) {
      toast.error(err.message);
    }
    return shareSym;
  }
);

export const undeclineShare = createAsyncThunk(
  'share/undecline',
  async ({ava, shareSym}: {ava: AvaType; shareSym?: string}) => {
    try {
      if (shareSym) {
        await ava.shares.undecline(shareSym);
      }
    } catch (err: any) {
      toast.error(err.message);
    }
    return shareSym;
  }
);

export const removeShare = createAsyncThunk(
  'share/delete',
  async ({ava, shareSym}: {ava: AvaType; shareSym?: string}) => {
    try {
      if (shareSym) {
        ava.shares.delete(shareSym);
      }
    } catch (err: any) {
      toast.error(err.message);
    }
  }
);

export const sendInvites = createAsyncThunk(
  'share/sendInvite',
  async ({ava, shareSyms}: {ava: AvaType; shareSyms?: Array<string>}) => {
    try {
      if (shareSyms && shareSyms.length) {
        Promise.all(shareSyms.map((sym) => ava.shares.sendInvites(sym)));
      }
    } catch (err: any) {
      toast.error(err.message);
    }
  }
);

export const generateCopyLink = createAsyncThunk('share/generateCopyLink', async (shareSym: string): Promise<any> => {
  try {
    const link = await ava.shares.createInviteUrl(shareSym);
    if (link) {
      navigator.clipboard.writeText(link);
    }

    return {link, shareSym};
  } catch (err: any) {
    toast.error(err.message);
    return null;
  }
});

async function hackUpdateDeal(dispatch: Dispatch, sym: string) {
  const deal = await ava.deals.get(sym);
  dispatch(setProject(deal));
}

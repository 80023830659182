import {useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {useFormikContext} from 'formik';
import {useSelector} from 'react-redux';
import {selectDollarLoanAmount} from 'store/quote-terms-service/quoteTermsSelector';
import {BORDERS, THEMES} from 'shared/helpers';

// Core
import {MaxValidationLimits} from 'core/_consts';
import {validateNumericInput} from 'core/utils';
import {FormikElement} from 'core/enums/formik-controller.enum';

// Components
import BasicTooltip from 'shared/components/BasicTooltip';
import NumericInput from 'shared/components/NumericInput';
import {UnsavedChangesPrompt} from 'shared/components/UnsavedChangesPrompt';
import Controller from 'shared/components/FormikController';
import {StepSelect} from 'shared/components/StepSelect';

import s from './Fifth.module.scss';

const selectOptionsMock = ['Value Add', 'Stabilized', 'Vacant'];

const useStyles = makeStyles({
  hint: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '14px',
    height: '14px',
    borderRadius: '50%',
    backgroundColor: 'white',
    color: 'blue',
    cursor: 'default',
    marginLeft: '4px',
    marginTop: '2px',
  },
  label: {
    color: `white`,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
    fontStyle: 'normal',
  },
});

const Fifth = () => {
  const {values, handleChange, setFieldValue}: any = useFormikContext();

  const classes = useStyles();
  const defaultValue = useSelector(selectDollarLoanAmount);
  const [currentNetValue, setCurrentNetValue] = useState(Number(defaultValue) || '');
  const [proFormaNetValue, setProFormaNetValue] = useState(Number(defaultValue) || '');
  const [fixedPercentValue, setFixedPercentValue] = useState(Number(defaultValue) || '');

  return (
    <div className={s.fifth}>
      <div className={s.fifth__header}>
        <p className={s.fifth__title}>Property income</p>
      </div>
      <div>
        <div id="user-form" className={s.fifth__form}>
          <div className={s.fifth__formSelect}>
            <label htmlFor="firstName" className={classes.label}>
              Category*
            </label>
            <StepSelect
              id="category"
              placeholder="Select asset type"
              name="category"
              theme={THEMES.white}
              options={selectOptionsMock}
              value={values.category}
              handleChange={(values) => handleChange(values)}
            />
          </div>
          <div>
            <label htmlFor="label" className={s.fifth__label}>
              Current Net Operating Income
              <BasicTooltip
                title="12 months of total income minus operating expenses. Do not include financing costs as per of your calculation."
                arrow
                placement="bottom"
              >
                <div className={classes.hint}>?</div>
              </BasicTooltip>
            </label>
            <NumericInput
              theme={THEMES.white}
              name="currentNet"
              value={values.currentNet}
              defaultValue={currentNetValue}
              onValueChange={(values) => {
                setCurrentNetValue(Number(values.value));
                setFieldValue('currentNet', values.value);
              }}
              thousandSeparator
              isValid={validateNumericInput(Number(currentNetValue), MaxValidationLimits.highestLimit)}
              width="300px"
              preventionLimit={MaxValidationLimits.highestLimit}
              placeholder="$"
              textAlign="left"
            />
          </div>
          <div>
            <label htmlFor="label" className={s.fifth__label}>
              Pro Forma Net Operating Income
            </label>
            <NumericInput
              theme={THEMES.white}
              name="proFormaNet"
              defaultValue={proFormaNetValue}
              onValueChange={(values) => {
                setProFormaNetValue(Number(values.value));
                setFieldValue('proFormaNet', values.value);
              }}
              thousandSeparator
              isValid={validateNumericInput(Number(proFormaNetValue), MaxValidationLimits.highestLimit)}
              width="300px"
              preventionLimit={MaxValidationLimits.highestLimit}
              placeholder="$"
              textAlign="left"
            />
          </div>
          <div>
            <label htmlFor="label" className={s.fifth__label}>
              Pro Forma Cap Rate
            </label>
            <Controller
              theme={THEMES.white}
              border={BORDERS.SolidWhite1px}
              control={FormikElement.NUMERIC_INPUT}
              width="300px"
              defaultValue={fixedPercentValue}
              onValueChange={(values: any) => {
                setFixedPercentValue(Number(values.value));
                setFieldValue('fixedPercent', values.value);
              }}
              preventionLimit={MaxValidationLimits.percent}
              name="fixedPercent"
              placeholder="%"
              textAlign="left"
            />
          </div>
          <UnsavedChangesPrompt />
        </div>
      </div>
    </div>
  );
};

export {Fifth};

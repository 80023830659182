import {useState, DragEvent} from 'react';

import BasicTooltip from 'shared/components/BasicTooltip';

import s from './Ninth.module.scss';

const Ninth = () => {
  const [pictures, setPictures] = useState<Array<string>>([]);
  const [attachments, setAttachements] = useState<Array<string>>([]);

  const handleDragEnter = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragOver = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragEnd = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer.files) {
      const fileNames: Array<string> = [];
      Array.from(event.dataTransfer.files).forEach((file) => {
        fileNames.push(file.name);
      });

      setAttachements(fileNames);
    }
  };

  const handleAttachmentsUpload = (files: FileList | null) => {
    if (files) {
      const fileNames: Array<string> = [];
      Array.from(files).forEach((file) => {
        fileNames.push(file.name);
      });

      setAttachements(fileNames);
    }
  };

  const handleAttachmentRemove = (file: string) => {
    setAttachements(attachments.filter((item) => item !== file));
  };

  const handlePicturesUpload = (files: FileList | null) => {
    if (files) {
      const pictureSrc: Array<string> = [];
      Array.from(files).forEach((file) => {
        pictureSrc.push(URL.createObjectURL(file));
      });

      setPictures(pictureSrc);
    }
  };

  const handlePictureRemove = (picture: string) => {
    setPictures(pictures.filter((item) => item !== picture));
  };

  return (
    <div className={s.ninth}>
      <div className={s.ninth__header}>
        <p className={s.ninth__title}>Attachments & Pictures</p>
      </div>
      <div className={s.ninth__attachments}>
        <div className={s.ninth__row}>
          <div className={s.ninth__label}>
            Attachments
            <BasicTooltip
              title="For best results, attach a current rent roll and financial pro forma (projections)."
              placement="bottom"
              arrow
            >
              <p className={s.ninth__hint}>?</p>
            </BasicTooltip>
          </div>
          <label htmlFor="attachmentsUpload" className={s.ninth__upload}>
            Choose files
            <input
              id="attachmentsUpload"
              type="file"
              name="attachmentsUpload"
              accept=".pdf,.doc,.docx,.rtf,.txt"
              onChange={(e) => handleAttachmentsUpload(e.target.files)}
              multiple
            />
          </label>
        </div>
        {attachments.length === 0 && (
          <div
            className={s.ninth__drop}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            onDrop={handleDrop}
          >
            <p className={s.ninth__label}>Drag and drop files here.</p>
          </div>
        )}
        <div className={s.ninth__list}>
          {attachments.length > 0 &&
            attachments.map((file) => (
              <div key={file} className={s.ninth__fileRow}>
                {file}
                <div className={s.ninth__fileDelete} onClick={() => handleAttachmentRemove(file)} />
              </div>
            ))}
        </div>
      </div>
      <div className={s.ninth__row}>
        <p className={s.ninth__label}>Pictures (Optional)</p>
        <label htmlFor="picturesUpload" className={s.ninth__upload}>
          Choose files
          <input
            id="picturesUpload"
            type="file"
            name="picturesUpload"
            accept="image/*"
            onChange={(e) => handlePicturesUpload(e.target.files)}
            multiple
          />
        </label>
      </div>
      <div className={s.ninth__gallery}>
        {pictures &&
          pictures.map((picture) => (
            <div key={picture} className={s.ninth__card}>
              <div className={s.ninth__removeBtn} onClick={() => handlePictureRemove(picture)} />
              <img src={picture} alt="Uploaded card" className={s.ninth__image} />
            </div>
          ))}
      </div>
    </div>
  );
};

export {Ninth};

import {CircularProgress} from '@mui/material';
import {DragEvent, FC, SetStateAction, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Form, Formik} from 'formik';

import {ReactComponent as GalleryIcon} from 'assets/icons/GalleryIcon.svg';

import {Attachment} from 'core/service/ava';
import {UploadingFile} from 'core/_types/UnderwritingTypes';

import {deleteAttachment, updateAttachments} from 'store/project-service/asyncActions';

import AttachmentItem from './Attachment';

import s from './Attachments.module.scss';

const MAX_FILE_SIZE = 150 * 1024 * 1024; // 150 MiB

type AttachmentsProps = {
  dealSym: string;
  attachments: Attachment[];
};

const Attachments: FC<AttachmentsProps> = ({
  dealSym, attachments,
}) => {
  const dispatch = useDispatch();
  const [sizeError, setSizeError] = useState(false);
  const [uploads, setUploads] = useState<UploadingFile[]>([]);

  const handleDragEnter = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragOver = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragEnd = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    handleAttachmentsUpload(event.dataTransfer.files);
  };

  const handleAttachmentsUpload = (files: FileList | null) => {
    if (files) {
      setSizeError(false);
      const newUploads = Array.from(files).filter((file) => {
        if (file.size <= MAX_FILE_SIZE) {
          return true;
        } else {
          setSizeError(true);
          return false;
        }
      });

      dispatch(updateAttachments({
        sym: dealSym,
        attachments: newUploads.map(file => ({
          file,
          done: () => setUploads(uploads.filter(u => u.name !== file.name)),
        })),
        dispatch,
      }));
      setUploads([...uploads, ...newUploads]);
    }
  };

  const handleAttachmentRemove = (sym: string) => {
    dispatch(deleteAttachment({sym: dealSym, attachmentSym: sym, dispatch}));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={(values) => {
        // eslint-disable-next-line no-alert
        alert(JSON.stringify(values));
      }}
    >
      {() => (
        <Form>
          <div>
            <p className={s.attachments__text}>
              Use this page to attach files like your pro forma, rent roll, and
              other supporting documents.
            </p>
            <div className={s.attachments}>
              <p className={s.attachments__heading}>Upload</p>
              <div>
                {uploads.map(u => (
                  <div key={u.name}>
                    <CircularProgress size={14} />{' '}
                    Uploading {u.name}&hellip;
                  </div>
                ))}
              </div>
              <div
                className={
                  sizeError
                    ? s['attachments__area--error']
                    : s.attachments__area
                }
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
                onDrop={handleDrop}
              >
                <label className={s.attachments__upload}>
                  <div>
                    <GalleryIcon className={s.attachments__upload__icon} />
                    <p>
                      Drag and drop the file or{' '}
                      <strong className={s.attachments__upload__link}>
                        Browse
                      </strong>
                    </p>
                    {sizeError ? (
                      <p className={s.attachments__error}>
                        Max. file size: 150 MB. Please select different a file
                      </p>
                    ) : (
                      <p className={s.attachments__upload__size}>
                        Max. file size: 150 MB
                      </p>
                    )}
                  </div>
                  <input
                    id="attachmentsUpload"
                    type="file"
                    name="attachmentsUpload"
                    onChange={(e) => handleAttachmentsUpload(e.target.files)}
                    multiple
                  />
                </label>
              </div>
              {attachments.length ? (
                <div className={s.list}>
                  <p className={s.attachments__heading}>Uploaded Files</p>
                  {attachments &&
                    attachments.map((attachment) => (
                      <div key={attachment.url} className={s.list__item}>
                        <AttachmentItem
                          dealSym={dealSym}
                          attachment={attachment}
                          handleAttachmentRemove={handleAttachmentRemove}
                        />
                      </div>
                    ))}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Attachments;

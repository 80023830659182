import {FC, useEffect} from 'react';
import {Form, FormikHandlers, FormikHelpers, FormikValues} from 'formik';
import {Grid, SelectChangeEvent} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';

// Store
import {updateOverview} from 'store/project-service/asyncActions';
import {selectorGetProject, selectorGetUpdate} from 'store/project-service/selector';
import {setUpdatedOverview} from 'store/project-service/projectSlice';

// Shared
import TextInput from 'shared/components/TextInput';
import {StepSelect} from 'shared/components/StepSelect';
import {THEMES} from 'shared/helpers';
import TextArea from 'shared/components/TextArea';

// Core
import {OverviewType} from 'core/_types/UnderwritingTypes';
import {statusMessage} from 'core/enums/statusMessage';
import {financingPurposeOptions} from 'core/utils/selectOptions/financingPurposeOptions';
import {FinancingPurpose} from 'core/enums/financingPurpose';
import useStatusMessage from 'core/utils/hooks/useStatusMessage';

type OverviewFormProps = {
  values: OverviewType;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: Pick<FormikHelpers<FormikValues>, 'setFieldValue'>['setFieldValue'];
  initialValues: OverviewType;
};

const OverviewForm: FC<OverviewFormProps> = ({values, handleChange, setFieldValue, initialValues}) => {
  const dispatch = useDispatch();

  const project = useSelector(selectorGetProject);
  const {overview} = useSelector(selectorGetUpdate);
  const message = useStatusMessage(overview);

  useEffect(() => {
    if (values !== initialValues) {
      dispatch(updateOverview({sym: project.sym, fields: values, dispatch}));
      dispatch(setUpdatedOverview(statusMessage.saving));
    }
  }, [values]);

  useEffect(
    () => () => {
      dispatch(setUpdatedOverview(''));
    },
    []
  );

  return (
    <Form>
      <Grid container spacing={2.5} rowSpacing={3}>
        <Grid item xs={6}>
          <TextInput fullWidth label="Project Name" name="name" value={values.name} onValueChange={handleChange} />
        </Grid>
        <Grid item xs={6}>
          <StepSelect
            options={financingPurposeOptions}
            placeholder="Select project type"
            theme={THEMES.blue}
            name="financingPurpose"
            value={values.financingPurpose.label}
            label="Project Type"
            handleChange={(e?: SelectChangeEvent<any>) =>
              e &&
              setFieldValue('financingPurpose', {
                value: FinancingPurpose[e.target.value.toUpperCase().replace(' ', '_')],
                label: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextArea
            width="100%"
            height="200px"
            name="projectSummary"
            value={values.projectSummary}
            onValueChange={handleChange}
            label="Project Summary"
            theme={THEMES.blue}
            resize="vertical"
          />
        </Grid>
        {!!message.length && (
          <Grid item xs={12} sx={{fontStyle: 'italic', color: '#757575'}}>
            <p>{message}</p>
          </Grid>
        )}
      </Grid>
    </Form>
  );
};

export default OverviewForm;

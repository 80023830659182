import {FC, useLayoutEffect, useState} from 'react';
import {ProFormaLowestValue} from 'core/_types/enum';
import {selectorGetProject} from 'store/project-service/selector';
import {useSelector} from 'react-redux';
import cx from 'classnames';
import AccordionWrapper from 'shared/components/Accordion';
import Graph from './Graph';
import FormaTable from './FormaTable';
import Description from './Description';
import s from './ProFormaSummary.module.scss';
import {IsMobileViewProps} from '../helpers';

const calcDifference = (a: number, b: number) =>
  a > b ? Number((100 / (a / b)).toFixed(1)) : Number((100 / (b / a)).toFixed(1));

const ProFormaSummary: FC<IsMobileViewProps> = ({isMobileView}) => {
  const project = useSelector(selectorGetProject);
  const [lowestValue, setLowestValue] = useState(0);
  const [lowestValueProportions, setLowestValueProportions] = useState(0);

  const shouldSectionBeRendered =
    !!Object.getOwnPropertyNames(project).length &&
    (!project.ltc.isZero() || !project.ltv.isZero()) &&
    !project.stabilizedLtv.isZero() &&
    project.capRateValuation !== project.stabilizedCapRateValuation &&
    project.ltv.upperBound.formatMaxDecimalPlaces(1) !== project.stabilizedLtv.upperBound.formatMaxDecimalPlaces(1);

  useLayoutEffect(() => {
    if (project.stabilizedCapRateValuation && project.capRateValuation) {
      const stabilizedCapRateValuation = Math.floor(Number(project.stabilizedCapRateValuation.value));
      const capRateValuation = Math.floor(Number(project.capRateValuation.value));
      const diffPercent = calcDifference(capRateValuation, stabilizedCapRateValuation);
      const lowestValue =
        capRateValuation < stabilizedCapRateValuation
          ? ProFormaLowestValue.CAP_RATE_VALUATION
          : ProFormaLowestValue.STABILIZED_CAP_RATE_VALUATION;
      setLowestValueProportions(diffPercent);
      setLowestValue(lowestValue);
    }
  }, [project]);

  const content = (
    <div className={cx(s.proFormaSummary, 'LenderView__section')}>
      {!isMobileView && <p className="LenderView__infoContentTitle">Pro Forma Summary</p>}
      <div className={s.proFormaSummary__container}>
        <div className={s.proFormaSummary__graphics}>
          <div className={s.proFormaSummary__content}>
            <Graph
              lowestValueProportions={
                lowestValue === ProFormaLowestValue.CAP_RATE_VALUATION ? lowestValueProportions : 100
              }
              totalBudget={project.capRateValuation?.format()}
              ltc={
                project.ltc?.upperBound.formatMaxDecimalPlaces(1) || project.ltv?.upperBound.formatMaxDecimalPlaces(1)
              }
              title="Current"
            />
            <Graph
              lowestValueProportions={
                lowestValue === ProFormaLowestValue.STABILIZED_CAP_RATE_VALUATION ? lowestValueProportions : 100
              }
              stabilizedValue={project.stabilizedCapRateValuation?.format()}
              ltv={project.stabilizedLtv?.upperBound.formatMaxDecimalPlaces(1)}
              title="As-stabilized"
            />
          </div>
          <div className={s.proFormaSummary__descriptions}>
            <Description title="Equity" variant="darkened" />
            <Description title="Debt" variant="light" />
          </div>
        </div>
        <FormaTable />
      </div>
    </div>
  );

  if (!shouldSectionBeRendered) return null;
  if (isMobileView) return <AccordionWrapper title="Pro Forma Summary">{content}</AccordionWrapper>;

  return content;
};

export default ProFormaSummary;

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getBrokerProfiles, getCapitalAdvisorProfiles, getSponsorProfiles, getUserSettings} from './asyncActions';
import {
  IUserProfilesReducer,
  CapitalAdvisorProfilesType,
  UserSettingsType,
  SponsorProfilesType,
  BrokerProfilesType,
} from './interfaces';

const initialState: IUserProfilesReducer = {
  capitalAdvisorProfiles: [],
  sponsorProfiles: [],
  brokerProfiles: [],
  userSettings: {},
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCapitalAdvisorProfiles.fulfilled, (state, action: PayloadAction<CapitalAdvisorProfilesType>) => {
      state.capitalAdvisorProfiles = action.payload;
    });
    builder.addCase(getSponsorProfiles.fulfilled, (state, action: PayloadAction<SponsorProfilesType>) => {
      state.sponsorProfiles = action.payload;
    });
    builder.addCase(getBrokerProfiles.fulfilled, (state, action: PayloadAction<BrokerProfilesType>) => {
      state.brokerProfiles = action.payload;
    });
    builder.addCase(getUserSettings.fulfilled, (state, action: PayloadAction<UserSettingsType>) => {
      state.userSettings = action.payload;
    });
  },
});

export default userSlice.reducer;

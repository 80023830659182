import {dateRange} from '../_consts';

export const dateChecker = (date: string): string | boolean => {
  if (!date.trim()) {
    return true;
  }

  const minDate = new Date(dateRange.minDate);

  date.split('-').splice(1, 2, date[2], date[1]).join('-');
  const currentDate: Date = new Date(date);

  if (currentDate < minDate && +date.split('-')[0] < 1582) {
    return 'Invalid Date';
  }

  if (Number.isNaN(currentDate.getTime())) {
    return 'Invalid Date';
  }

  return true;
};

import {useEffect} from 'react';
import {LoanAmountSubsteps} from 'core/_consts';
import {useLocation} from 'react-router-dom';

const useIsStartPointSetter = (cb: (value: boolean) => void) => {
  const location = useLocation();

  useEffect(() => {
    cb(
      new URLSearchParams(location.search).get('substep') ===
        LoanAmountSubsteps[LoanAmountSubsteps.loanSizingConstraints]
    );
  }, [location.search]);
};

export default useIsStartPointSetter;

import {FC} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from '@mui/material';

import {ReactComponent as WebsiteIcon} from 'assets/icons/website.svg';
import {ReactComponent as PhoneIcon} from 'assets/icons/phone.svg';
import {ReactComponent as EmailIcon} from 'assets/icons/email.svg';

import {TeamMemberData as MemberData} from 'core/_types/base';
import {SymOrString} from 'core/service/ava';

import {deleteAdvisorUser, deleteSponsorUser} from 'store/project-service/asyncActions';
import {selectorGetProject} from 'store/project-service/selector';

import s from './DealTeam.module.scss';

type TeamMemberProps = {
  data: MemberData;
  memberRole: string;
  userSettings: any;
  sponsors?: MemberData[];
  setSponsorSymOrEmails?: (values: SymOrString[]) => void;
  advisors?: MemberData[];
  setAdvisorSyms?: (syms: string[]) => void;
};

const TeamMember: FC<TeamMemberProps> = ({
  data,
  memberRole,
  userSettings,
  sponsors,
  setSponsorSymOrEmails,
  advisors,
  setAdvisorSyms,
}) => {
  const dispatch = useDispatch();

  const project = useSelector(selectorGetProject);

  const deleteUser = (): void => {
    if (memberRole === 'Sponsor') {
      if (sponsors && setSponsorSymOrEmails) {
        dispatch(deleteSponsorUser({sym: project.sym, email: data.email}));
        setSponsorSymOrEmails(sponsors.map(
          u => u.sym
            ? new SymOrString().setSym(u.sym)
            : new SymOrString().setString(u.email),
        ).filter(u => u.string !== data.email && u.sym !== data.sym));
      }
    } else if (memberRole === 'Capital Advisor') {
      if (advisors && setAdvisorSyms) {
        dispatch(deleteAdvisorUser({
          dealSym: project.sym,
          advisorSym: data.sym,
        }));
        setAdvisorSyms(advisors.map(u => u.sym).filter((u) => u !== data.sym));
      }
    }
  };

  return (
    <div className={s.DealTeam__item}>
      <div>
        <div className={s.DealTeam__item_header}>
          <div className={s.DealTeam__member}>
            <p>{data.displayName}</p>
            <small className={s.DealTeam__member_role}>{memberRole}</small>
          </div>

          <Button
            sx={{
              marginTop: '-5px',
              font: '400 14px Source Sans Pro',
              textTransform: 'none',
              textDecoration: 'underline',

              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={deleteUser}
          >
            Delete user
          </Button>
        </div>
      </div>

      <div>
        {data.website && (
          <div className={s.DealTeam__info}>
            <WebsiteIcon className={s.DealTeam__info_icon} />
            <a href={data.website} className={s.DealTeam__info_link}>
              {data.website}
            </a>
          </div>
        )}
        {!data.phoneNumber.isEmpty() && (
          <div className={s.DealTeam__info}>
            <PhoneIcon className={s.DealTeam__info_icon} />
            <a href={data.phoneNumber.toUrlString()} className={s.DealTeam__info_link}>
              {data.phoneNumber.format(userSettings)}
            </a>
          </div>
        )}
        <div className={s.DealTeam__info}>
          <EmailIcon className={s.DealTeam__info_icon} />
          <a href={`mailto:${data.email}`} className={s.DealTeam__info_link}>
            {data.email}
          </a>
        </div>
      </div>
    </div>
  );
};

export default TeamMember;

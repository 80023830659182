import {FC, useState} from 'react';
import {useFormikContext} from 'formik';
import StepTextInput from 'shared/components/StepTextInput';
import {UnsavedChangesPrompt} from 'shared/components/UnsavedChangesPrompt';
import SelectionCard from '../../SelectionCard';
import {isValue, TenantsTypes} from '../../helpers';

// Style
import s from './Tenants.module.scss';

type TenantsProps = {
  goNext: () => void;
};

const Tenants: FC<TenantsProps> = ({goNext}) => {
  const {values, handleChange, setFieldValue}: any = useFormikContext();

  const [selectedCard, setSelectedCard] = useState(values.tenantsType);

  const iconTitles = (Object.keys(TenantsTypes) as Array<keyof typeof TenantsTypes>).filter(isValue);

  const singleTenant = TenantsTypes[TenantsTypes['Single Tenant']];

  return (
    <div className={s.tenants}>
      <div className={s.tenants__header}>
        <p className={s.tenants__title}>Tenants</p>
      </div>
      <div className={s.tenants__cards}>
        {iconTitles.map((iconTitles) => (
          <SelectionCard
            key={iconTitles}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            handleCardSelection={() => {
              setFieldValue('tenantsType', iconTitles);
              setSelectedCard(iconTitles);
              // eslint-disable-next-line no-unused-expressions
              iconTitles !== singleTenant && goNext();
            }}
            title={iconTitles}
            iconName={iconTitles.toLocaleLowerCase()}
          />
        ))}
      </div>
      {values.tenantsType === singleTenant && (
        <div className={s.tenants__form}>
          <div className={s.tenants__tenantInput}>
            <StepTextInput
              id="tenants"
              name="tenants"
              label="Tenants"
              value={values.tenants}
              onValueChange={handleChange}
              required
              width="300px"
            />
          </div>
          <UnsavedChangesPrompt />
        </div>
      )}
    </div>
  );
};

export {Tenants};

import {FC} from 'react';
import {useHistory} from 'react-router-dom';
import {ROUTERS} from 'core/_consts';

import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';

import s from './CreateNewProj.module.scss';

const useStyles = makeStyles({
  addIcon: {
    fill: '#0073e6',
    width: '50px',
    height: '50px',
    marginLeft: '20px',
  },
});

const CreateNewProj: FC = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className={s.createNewProj}>
      <div className={s.createNewProj__title}>
        <div
          className={s.createNewProj__add}
          onClick={() => history.push(`${ROUTERS.CREATE_NEW_PROJECT}/new-user-email`)}
        >
          <AddIcon className={classes.addIcon} />
          <p className={s.createNewProj__text}>Create a new project</p>
        </div>
      </div>
      <div className={s.createNewProj__mask}>
        <div className={s.createNewProj__addresses}>
          <p className={s.createNewProj__street}>New Project</p>
        </div>
      </div>
    </div>
  );
};

export default CreateNewProj;

import {useEffect, useState} from 'react';
import RadioGroup from 'shared/components/RadioGroup';
import {QuoteTermsSteps, PrepayPenaltySubsteps, penaltyTypeOptions} from 'core/_consts';
import NextButton from 'shared/components/NextButton';
import {setPenaltyType} from 'store/quote-terms-service/quoteTermsSlice';
import {selectPenaltyType} from 'store/quote-terms-service/quoteTermsSelector';
import {useSelector} from 'react-redux';
import {nextButtonClickHandler, NextButtonRefType} from 'pages/LenderView/helpers';

const handleSelectedValue = (value: string) => {
  switch (value) {
    case penaltyTypeOptions.stepdown:
      return {
        step: QuoteTermsSteps[QuoteTermsSteps.prepayPenalty],
        substep: PrepayPenaltySubsteps[PrepayPenaltySubsteps.prepaymentPenalty],
      };

    case penaltyTypeOptions.flatPrePay:
      return {
        step: QuoteTermsSteps[QuoteTermsSteps.prepayPenalty],
        substep: PrepayPenaltySubsteps[PrepayPenaltySubsteps.feeAmount],
      };

    case penaltyTypeOptions.minimumInterest:
      return {
        step: QuoteTermsSteps[QuoteTermsSteps.prepayPenalty],
        substep: PrepayPenaltySubsteps[PrepayPenaltySubsteps.minimumInterestPerion],
      };

    case penaltyTypeOptions.otherOrLimited:
      return {
        step: QuoteTermsSteps[QuoteTermsSteps.prepayPenalty],
        substep: PrepayPenaltySubsteps[PrepayPenaltySubsteps.penaltyDetails],
      };

    default:
      return {
        step: QuoteTermsSteps[QuoteTermsSteps.prepayPenalty],
        substep: PrepayPenaltySubsteps[PrepayPenaltySubsteps.anyLockout],
      };
  }
};

const PenaltyType = () => {
  const defaultValue = useSelector(selectPenaltyType);
  const [isAnyType, setIsAnyType] = useState<Array<string>>(defaultValue);
  const [nextButtonRef, setNextButtonRef] = useState<NextButtonRefType>(null);

  useEffect(() => {
    nextButtonClickHandler(nextButtonRef);
  }, [isAnyType]);

  return (
    <div className="FormSlide">
      <p className="FormSlide__title mb35">Select the prepay penalty type.</p>
      <RadioGroup
        maxWidth="540px"
        additionalClassName="penaltyType"
        isAnyValueSelected={isAnyType}
        setIsAnyValueSelected={setIsAnyType}
        radioButtonTitles={Object.values(penaltyTypeOptions)}
        nextButtonTrigger={() => nextButtonClickHandler(nextButtonRef)}
      />
      <NextButton
        isHidden={!defaultValue.length}
        setNextButtonRef={setNextButtonRef}
        isDisabled={!isAnyType.length}
        slideData={{[PrepayPenaltySubsteps[PrepayPenaltySubsteps.penaltyType]]: isAnyType}}
        action={setPenaltyType}
        nextSlide={handleSelectedValue(isAnyType[0])}
      />
    </div>
  );
};

export default PenaltyType;

import {FC, useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

// Material
import makeStyles from '@mui/styles/makeStyles';
import Accordion from '@mui/material/Accordion';
import {Backdrop, Fade, Modal} from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Core
import {GuarantorsType} from 'core/_types/UnderwritingTypes';
import {usesTypeOptions} from 'core/utils/selectOptions/usesTypeOptions';
import {Attachment, Deal} from 'core/service/ava';

import PhotoSlider from 'pages/LenderView/PhotoSlider';

// Components (Tabs)
import AssetInfo from './AssetInfo';
import Overview from './Overview';
import FinancialInfo from './FinancialInfo';
import Pictures from './Pictures';
import Attachments from './Attachments';
import Guarantors from './Guarantors';
import SponsorProfile from './SponsorProfile';
import {SourcesAndUses} from './SourcesAndUses';

import s from './Underwriting.module.scss';

// Accordion root styles
const useStyles = makeStyles(() => ({
  root: {
    border: 'none',
    borderTop: '1px solid #dde4e4',
    boxShadow: 'none',
  },
  details: {
    border: 'none',
    boxShadow: 'none',
  },
}));

type UnderwritingProps = {
  project: Deal;
};

const Underwriting: FC<UnderwritingProps> = ({project}) => {
  const classes = useStyles();
  const {hash, pathname} = useLocation();
  const history = useHistory();

  // Sources & Uses
  const [sources, setSources] = useState<Array<any>>([]);
  const [uses, setUses] = useState<Array<any>>([]);

  // Pictures
  const [galleryItemNumber, setGalleryItemNumber] = useState(-1);
  const [pictures, setPictures] = useState<Array<any>>([]);
  const [preview, setPreview] = useState(null);

  // Guarantors
  const [guarantors, setGuarantors] = useState<GuarantorsType[]>([]);

  // Sponsor Profile
  const sponsorProfile = useMemo(() => project.sponsorProfile, [project]);

  // Getting & formatting the data
  useEffect(() => {
    // TODO: move date formatting to separate helper-function
    console.log( // DEBUG
      'md:',
      project.capStack.sourceList.map(
        (u) => u.maturityDate.formatIso(),
      ).toArray(),
    ); // DEBUG
    const formattedSources = project.capStack.sourceList.map((item) => ({
      type: {value: item.type, label: item.formatType()},
      principal: `USD ${item.principal.toNumber().toString().replace(/[\s,]/g, '')}`,
      entity: item.entity,
      maturityDate: item.maturityDate.formatIso(),
    }));
    const formattedUses = project.capStack.useList.map((item) => ({
      use: item.use,
      customUse: usesTypeOptions.includes(item.use) ? '' : item.use,
      principal: `USD ${item.principal.toNumber().toString().replace(/[\s,]/g, '')}`,
    }));
    setSources(Array.from(formattedSources));
    setUses(Array.from(formattedUses));

    setPictures(Array.from(project.imageList));

    if (Object.getOwnPropertyNames(project).length) {
      setGuarantors(Array.from(project.guarantorList));
    }
  }, [project]);

  return (
    <div className={s.Underwriting}>
      <h2 className={s.Underwriting__heading}>Underwriting</h2>
      <Modal
        open={galleryItemNumber !== -1}
        className={s.modal}
        onClose={() => setGalleryItemNumber(-1)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={galleryItemNumber !== -1}>
          <div className={s.Underwriting__sliderFade}>
            <PhotoSlider
              initialActiveItem={galleryItemNumber}
              images={pictures}
            />
          </div>
        </Fade>
      </Modal>
      <div>
        <Accordion className={classes.root}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
            <p className={s.Tab__heading}>Overview</p>
          </AccordionSummary>
          <AccordionDetails>
            <Overview project={project} />
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.root}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel2a-header">
            <p className={s.Tab__heading}>Asset information</p>
          </AccordionSummary>
          <AccordionDetails>
            <AssetInfo project={project} />
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.root}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel3a-header">
            <p className={s.Tab__heading}>Financial information</p>
          </AccordionSummary>
          <AccordionDetails>
            <FinancialInfo project={project} />
          </AccordionDetails>
        </Accordion>
        {/* <Accordion className={classes.root}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel3a-header">
            <p className={s.Tab__heading}>Tenant Information</p>
          </AccordionSummary>
          <AccordionDetails>
            <TenantInfo project={project} />
          </AccordionDetails>
        </Accordion> */}
        <Accordion
          className={classes.root}
          id="sources-and-uses"
          expanded={hash.includes('sources-and-uses')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="panel3a-header"
            onClick={() =>
              hash.includes('sources-and-uses')
                ? history.replace(pathname)
                : history.replace(`${pathname}#sources-and-uses`)
            }
          >
            <p className={s.Tab__heading}>Sources & Uses</p>
          </AccordionSummary>
          <AccordionDetails>
            <SourcesAndUses sources={sources} uses={uses} />
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.root}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel3a-header">
            <p className={s.Tab__heading}>Pictures</p>
          </AccordionSummary>
          <AccordionDetails>
            <Pictures
              projectSym={project?.sym}
              pictures={pictures}
              preview={preview}
              setPictures={setPictures}
              setPreview={setPreview}
              setGalleryItemNumber={setGalleryItemNumber}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.root}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel3a-header">
            <p className={s.Tab__heading}>Attachments</p>
          </AccordionSummary>
          <AccordionDetails>
            <Attachments
              dealSym={project?.sym}
              attachments={project.attachmentList.toArray()}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.root}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel3a-header">
            <p className={s.Tab__heading}>Guarantors</p>
          </AccordionSummary>
          <AccordionDetails>
            <Guarantors guarantors={guarantors} setGuarantors={setGuarantors} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={classes.root}
          id="sponsor-profile"
          expanded={hash.includes('sponsor-profile')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="panel3a-header"
            onClick={() =>
              hash.includes('sponsor-profile')
                ? history.replace(pathname)
                : history.replace(`${pathname}#sponsor-profile`)
            }
          >
            <p className={s.Tab__heading}>Sponsor Profile</p>
          </AccordionSummary>
          <AccordionDetails>
            <SponsorProfile sponsorProfile={sponsorProfile} />
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default Underwriting;

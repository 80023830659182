import {Dispatch, FC, SetStateAction} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cx from 'classnames';

import {Box, Modal, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';

import {Org, Share, SymOrString} from 'core/service/ava';
import {ProjectFilters, TeamMemberData} from 'core/_types/base';
import {GuarantorsType} from 'core/_types/UnderwritingTypes';

import {ModalTypes} from 'pages/ProjectView/helpers';
import Equity from 'pages/ProjectView/ModalVar/Equity';
import Senior from 'pages/ProjectView/ModalVar/Senior';
import Subordinate from 'pages/ProjectView/ModalVar/Subordinate';
import Guarantors from 'pages/ProjectView/ModalVar/Guarantors';
import DashboardSort from 'pages/ProjectView/ModalVar/DashboardSort';
import NewUser from 'pages/ProjectView/ModalVar/NewUser';
import RemoveShare from 'pages/ProjectView/ModalVar/RemoveShare';

// Store
import {selectorGetProjectFilters} from 'store/project-service/selector';
import {getProjects} from 'store/project-service/asyncActions';

import s from './ModalWin.module.scss';

const style = {
  overflow: 'auto',
  backgroundColor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  width: '800px',
  height: '80vh',
  '&::-webkit-scrollbar': {
    width: '5px',
    border: '1px solid gray',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'darkgrey',
    borderRadius: '20px',
    outline: '1px solid slategrey',
  },
  '@media(max-width: 768px)': {
    height: 'auto',
    width: '100%',
    overflowX: 'hidden',
  },
};

const useStyles = makeStyles({
  button: {
    padding: '10px',
    margin: '10px',
    borderRadius: '6px',
    color: '#1c81ff',
    background: '#fff',
    font: '600 18px Source Sans Pro',
    textTransform: 'none',

    '&:hover, &:focus': {
      color: '#fff',
      background: '#0073e6',
    },

    '@media(max-width: 768px)': {
      display: '',
    },
  },
  close: {
    padding: 0,
    color: 'gray',
    marginRight: '20px',
    font: '600 18px Source Sans Pro',
    textTransform: 'none',
    zIndex: -1,
    '@media(max-width: 768px)': {
      top: 0,
      right: 0,
    },
  },
  primaryModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

type ModalWinProps = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  modal?: ModalTypes;
  title?: string;
  share?: Share;
  provider?: Org;
  guarantors?: GuarantorsType[];
  setGuarantors?: Dispatch<SetStateAction<GuarantorsType[]>>;
  completeStatus?: string;
  guarantorEdit?: number;
  setSponsorSymOrEmails?: Dispatch<SetStateAction<SymOrString[]>>;
  sponsors?: TeamMemberData[];
  setCapitalAdvisorSyms?: Dispatch<SetStateAction<string[]>>;
  capitalAdvisors?: TeamMemberData[];
  setSearchText?: Dispatch<SetStateAction<string>>;
};

const ModalWin: FC<ModalWinProps> = ({
  isOpen,
  setOpen,
  modal,
  title,
  share,
  provider,
  guarantors,
  setGuarantors,
  completeStatus,
  guarantorEdit,
  setSponsorSymOrEmails,
  sponsors,
  setCapitalAdvisorSyms,
  capitalAdvisors,
  setSearchText,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const filters = useSelector(selectorGetProjectFilters);

  const handleClose = () => {
    setOpen(false);
  };

  const handleGetFilteredProjects = (filters: ProjectFilters) => {
    dispatch(getProjects({filters}));
  };

  const renderModal = () => {
    switch (modal) {
      case ModalTypes.equity:
        return <Equity />;
      case ModalTypes.senior:
        return <Senior handleClose={handleClose} />;
      case ModalTypes.subordinate:
        return <Subordinate handleClose={handleClose} />;
      case ModalTypes.guarantors:
        // TODO(nathan): Use assert instead, or restructure this class
        if (!guarantors || !setGuarantors) {
          throw new Error('Missing params');
        }
        return (
          <Guarantors
            setOpen={setOpen}
            guarantors={guarantors}
            setGuarantors={setGuarantors}
            completeStatus={completeStatus}
            guarantorEdit={guarantorEdit}
          />
        );
      case ModalTypes.dashboardSort:
        return (
          <DashboardSort
            reset={!!filters}
            handleSearch={handleGetFilteredProjects}
            handleClose={handleClose}
            setSearchText={setSearchText}
          />
        );
      case ModalTypes.newUser:
        return (
          <NewUser
            handleClose={handleClose}
            setSponsorSymOrEmails={setSponsorSymOrEmails}
            sponsors={sponsors}
            setCapitalAdvisorSyms={setCapitalAdvisorSyms}
            capitalAdvisors={capitalAdvisors}
          />
        );
      case ModalTypes.removeShare:
        return <RemoveShare handleClose={handleClose} share={share} provider={provider} />;
      default:
        return <></>;
    }
  };

  return (
    <div className={s.modal}>
      <Modal className={cx(classes.primaryModal)} open={isOpen} onClose={handleClose}>
        {modal === ModalTypes.newUser || modal === ModalTypes.removeShare ? (
          <Box sx={{...style, position: 'relative', height: 'auto'}}>
            {title && (
              <header className={s.modal__header}>
                <p className={s.modal__headerTitle}>{title}</p>
                <IconButton className={classes.close} disableRipple onClick={handleClose} size="small">
                  <CloseIcon />
                </IconButton>
              </header>
            )}
            {renderModal()}
          </Box>
        ) : (
          <Box sx={{...style, position: 'relative'}}>
            {title && (
              <header className={s.modal__header}>
                <p className={s.modal__headerTitle}>{title}</p>
                <IconButton className={classes.close} disableRipple onClick={handleClose} size="small">
                  <CloseIcon />
                </IconButton>
              </header>
            )}
            {renderModal()}
          </Box>
        )}
      </Modal>
    </div>
  );
};

export default ModalWin;

import {FC, useEffect, useState} from 'react';

// Core
import {createGoogleMapsLink} from 'core/utils';

// Styles
import s from './GoogleMap.module.scss';

type GoogleMapProps = {
  project: any;
};

const GoogleMap: FC<GoogleMapProps> = ({project}) => {
  const [addressLink, setAddressLink] = useState('');

  useEffect(() => {
    if (project.address) setAddressLink(createGoogleMapsLink(project.address));
  }, [project]);

  return (
    <a
      className={s.GoogleMap}
      rel="noreferrer"
      href={`https://www.google.com/maps/place/${addressLink}`}
      target="_blank"
    >
      <img
        className={s.GoogleMap__image}
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${addressLink}&zoom=15&size=490x300&markers=size:mid%7Ccolor:darkred%7C${addressLink}&key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`}
        alt="Google map"
      />
    </a>
  );
};

export default GoogleMap;

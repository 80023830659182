import {FC} from 'react';
import {Formik} from 'formik';

// Core
import {OverviewType} from 'core/_types/UnderwritingTypes';
import {DealType} from 'core/_types/base';

import OverviewForm from './OverviewForm';

interface OverviewProps {
  project: DealType;
}

const Overview: FC<OverviewProps> = ({project}) => {
  const initialValues: OverviewType = {
    name: project?.name,
    financingPurpose: {
      value: project?.financingPurpose,
      label: project?.formatFinancingPurpose(),
    },
    projectSummary: project?.projectSummary.formatAsPlainText(),
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        // eslint-disable-next-line no-alert
        alert(JSON.stringify(values));
      }}
    >
      {(props) => (
        <OverviewForm
          values={props.values}
          handleChange={props.handleChange}
          setFieldValue={props.setFieldValue}
          initialValues={initialValues}
        />
      )}
    </Formik>
  );
};

export default Overview;

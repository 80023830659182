import {FC, useCallback, useMemo} from 'react';
import {
  signOptions,
  loanFeesOptions,
  LoanFeesSubsteps,
  QuoteTermsSteps,
  RequiredReservesSubsteps,
  windowSizes,
} from 'core/_consts';
import NextButton from 'shared/components/NextButton';
import * as yup from 'yup';
import {DOLLAR_TYPE, PERCENT_TYPE} from 'core/helpers/yupSchemas';
import {Form, Formik} from 'formik';
import {FeesDetailsValue} from 'core/_types/quoteTermsTypes';
import {setLoanFeesDetails} from 'store/quote-terms-service/quoteTermsSlice';
import {selectLoanFeesDetails, selectSelectedLoanFeesValues} from 'store/quote-terms-service/quoteTermsSelector';
import {useSelector} from 'react-redux';
import cx from 'classnames';
import Controller from 'shared/components/FormikController';
import {FormikElement} from 'core/enums/formik-controller.enum';
import useWindowWidth from 'core/utils/hooks/useWindowWidth';
import s from './FeesDetails.module.scss';
import FeesDetailsRow from './FeesDatailsRow';

const FeesDetails: FC = () => {
  const selectedFeesDetails = useSelector(selectSelectedLoanFeesValues);
  const defaultValue = useSelector(selectLoanFeesDetails);

  const defaultOtherFeeValues = useMemo(() => {
    const loanFeesValues = Object.values(loanFeesOptions).map((el) => el.toLowerCase());
    return defaultValue.find((el) => !loanFeesValues.includes(el.name));
  }, [defaultValue]);

  const structuredFeesDetails: Array<FeesDetailsValue> = useMemo(
    () =>
      selectedFeesDetails.map((item) =>
        item === loanFeesOptions.otherFee.toLowerCase()
          ? {
              name: defaultOtherFeeValues?.name || '',
              amount: defaultOtherFeeValues?.amount || '',
              amountFormat: defaultOtherFeeValues?.amountFormat || signOptions.dollar,
              dueAt: defaultOtherFeeValues?.dueAt || '',
            }
          : {
              name: item,
              amount: defaultValue.find((el) => el.name === item)?.amount || '',
              amountFormat: defaultValue.find((el) => el.name === item)?.amountFormat || signOptions.dollar,
              dueAt: defaultValue.find((el) => el.name === item)?.dueAt || '',
            }
      ),
    [selectedFeesDetails, defaultOtherFeeValues]
  );

  const initialValues = useMemo(
    () => ({
      structuredFeesDetails,
    }),
    [structuredFeesDetails]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        structuredFeesDetails: yup.array().of(
          yup.object().shape({
            name: yup.string().required(),
            amountFormat: yup.string().required(),
            amount: yup
              .number()
              .when('amountFormat', (amountFormat) =>
                amountFormat === signOptions.percent ? PERCENT_TYPE : DOLLAR_TYPE
              ),
            dueAt: yup.string().required(),
          })
        ),
      }),
    [structuredFeesDetails]
  );

  const windowWidth = useWindowWidth();

  const isMobileView = useMemo(() => windowWidth <= windowSizes.mobileSmall, [windowWidth]);

  const setInputLable = useCallback(
    (fieldName: string) => (isMobileView ? fieldName : fieldName.toLowerCase()),
    [isMobileView]
  );

  const otherFeeInputLable = useMemo(() => (isMobileView ? 'Other Fee Description' : 'other Fee'), [isMobileView]);

  return (
    <div className="FormSlide">
      <p className="FormSlide__title">Enter details for the loan fees.</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        // TODO: add handle logic on submit
        // eslint-disable-next-line no-console
        onSubmit={(values) => console.log(values)}
      >
        {({isValid, values, setFieldValue}) => (
          <Form className="FormSlide__form">
            <div className={s.inputs}>
              {structuredFeesDetails.map((item, index) => (
                <FeesDetailsRow
                  formikValues={values.structuredFeesDetails}
                  rowIndex={index}
                  fieldName={item.name}
                  key={item.name}
                  setFieldValue={setFieldValue}
                >
                  {!item.name || item.name === defaultOtherFeeValues?.name ? (
                    <Controller
                      name={`structuredFeesDetails[${index}].name`}
                      control={FormikElement.TEXT_INPUT}
                      width="200px"
                      label={otherFeeInputLable}
                      fullWidth={isMobileView}
                      textAlign="right"
                    />
                  ) : (
                    <div className={cx('FormSlide__subTitle FormSlide__subTitle_grey', s.inputSubtitle)}>
                      {setInputLable(item.name)}
                    </div>
                  )}
                </FeesDetailsRow>
              ))}
            </div>
            <NextButton
              isDisabled={!isValid}
              slideData={{[LoanFeesSubsteps[LoanFeesSubsteps.feesDetails]]: values.structuredFeesDetails}}
              action={setLoanFeesDetails}
              nextSlide={{
                step: QuoteTermsSteps[QuoteTermsSteps.requiredReserves],
                substep: RequiredReservesSubsteps[RequiredReservesSubsteps.selectRequiredReserves],
              }}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FeesDetails;

import {
  IRateDetailsSlideData,
  LengthAndFeeValues,
  FeesDetailsValue,
  ReservesDetailsValue,
  SizingConstrainsValues,
  TermDurationValues,
  AmortizationPeriodDurationValues,
  AmortizationsAmountValues,
  AvailableRecourseValues,
  BurnOffAvailableValues,
  BurnOffTriggerValues,
  LockoutDurationValues,
  InterestPerionDurationValues,
  PrepaymentPenaltyValues,
  BorrowerStipulationsValue,
} from 'core/_types/quoteTermsTypes';
import {RootState} from 'store/store';
import {IQuoteTermsReducer} from './interfaces';

export const selectQuoteTermsData = (store: RootState): IQuoteTermsReducer => store.quoteTermsRedux.data;

export const selectLoanSizingConstraints = (store: RootState): SizingConstrainsValues =>
  store.quoteTermsRedux.data.loanAmount.loanSizingConstraints;

export const selectDollarLoanAmount = (store: RootState): number | null =>
  store.quoteTermsRedux.data.loanAmount.dollarLoanAmount;

export const selectLoanTermDuration = (store: RootState): Array<string> =>
  store.quoteTermsRedux.data.loanTerm.loanTermDuration;

export const selectLengthAndFee = (store: RootState): LengthAndFeeValues =>
  store.quoteTermsRedux.data.loanTerm.lengthAndFee;

export const selectAnyExtensions = (store: RootState): Array<string> =>
  store.quoteTermsRedux.data.loanTerm.anyExtensions;

export const selectExtensionsAmount = (store: RootState): Array<string> =>
  store.quoteTermsRedux.data.loanTerm.extensionsAmount;

export const selectTermDuration = (store: RootState): TermDurationValues =>
  store.quoteTermsRedux.data.loanTerm.customLoanTerm;

export const selectRateResets = (store: RootState): Array<string> => store.quoteTermsRedux.data.interestRate.rateResets;
export const selectResetYear = (store: RootState): Array<string> => store.quoteTermsRedux.data.interestRate.resetYear;
export const selectRateDetails = (store: RootState): IRateDetailsSlideData =>
  store.quoteTermsRedux.data.interestRate.rateDetails;
export const selectRateCalculation = (store: RootState): Array<string> =>
  store.quoteTermsRedux.data.interestRate.rateCalculation;

export const selectInitialDeposit = (store: RootState): number => store.quoteTermsRedux.data.initialDeposit;

export const selectSelectedLoanFeesValues = (store: RootState): Array<string> =>
  store.quoteTermsRedux.data.loanFees.selectLoanFees;
export const selectLoanFeesDetails = (store: RootState): Array<FeesDetailsValue> =>
  store.quoteTermsRedux.data.loanFees.feesDetails;

export const selectSelectedRequiredReserves = (store: RootState): Array<string> =>
  store.quoteTermsRedux.data.requiredReserves.selectRequiredReserves;
export const selectReservesDetails = (store: RootState): Array<ReservesDetailsValue> =>
  store.quoteTermsRedux.data.requiredReserves.reservesDetails;
export const selectAmortizationPeriod = (store: RootState): Array<string> =>
  store.quoteTermsRedux.data.amortization.amortizationPeriod;

export const selectIsInterestOnlyPeriod = (store: RootState): Array<string> =>
  store.quoteTermsRedux.data.amortization.interestOnlyPeriod;

export const selectInterestOnlyPeriodDuration = (store: RootState): AmortizationPeriodDurationValues =>
  store.quoteTermsRedux.data.amortization.interestOnlyPeriodDuration;

export const selectAmortizationAmount = (store: RootState): AmortizationsAmountValues =>
  store.quoteTermsRedux.data.amortization.amount;

export const selectAvailableRecourse = (store: RootState): AvailableRecourseValues =>
  store.quoteTermsRedux.data.recourse.availableRecourse;

export const selectBurnOff = (store: RootState): BurnOffAvailableValues =>
  store.quoteTermsRedux.data.recourse.burnOffAvailable;

export const selectBurnOffTrigger = (store: RootState): BurnOffTriggerValues =>
  store.quoteTermsRedux.data.recourse.burnOffTrigger;

export const selectAnyPenalty = (store: RootState): Array<string> =>
  store.quoteTermsRedux.data.prepayPenalty.anyPenalty;

export const selectPenaltyType = (store: RootState): Array<string> =>
  store.quoteTermsRedux.data.prepayPenalty.penaltyType;

export const selectAnyLockout = (store: RootState): Array<string> =>
  store.quoteTermsRedux.data.prepayPenalty.anyLockout;

export const selectLockoutDuration = (store: RootState): LockoutDurationValues =>
  store.quoteTermsRedux.data.prepayPenalty.lockoutDuration;

export const selectPenaltyDetails = (store: RootState): string =>
  store.quoteTermsRedux.data.prepayPenalty.penaltyDetails;

export const selectPrepaymentPenalty = (store: RootState): PrepaymentPenaltyValues =>
  store.quoteTermsRedux.data.prepayPenalty.prepaymentPenalty;

export const selectPenaltyFee = (store: RootState): number => store.quoteTermsRedux.data.prepayPenalty.feeAmount;

export const selectInterestPerionDuration = (store: RootState): InterestPerionDurationValues =>
  store.quoteTermsRedux.data.prepayPenalty.minimumInterestPerion;

export const selectIsLockboxNeeded = (store: RootState): Array<string> =>
  store.quoteTermsRedux.data.cashManagement.isLockboxNeeded;

export const selectLockboxType = (store: RootState): Array<string> =>
  store.quoteTermsRedux.data.cashManagement.selectLockboxType;

export const selectBorrowerStipulations = (store: RootState): BorrowerStipulationsValue =>
  store.quoteTermsRedux.data.borrowerStipulations;

export const selectOtherRequirements = (store: RootState): string => store.quoteTermsRedux.data.otherRequirements;

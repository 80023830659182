import {useState} from 'react';
import {InterestRateSubsteps, QuoteTermsSteps} from 'core/_consts';
import RadioGroup from 'shared/components/RadioGroup';
import NextButton from 'shared/components/NextButton';
import {setResetYear} from 'store/quote-terms-service/quoteTermsSlice';
import {useSelector} from 'react-redux';
import {selectResetYear} from 'store/quote-terms-service/quoteTermsSelector';

enum resetYearOptions {
  oneYear = 'year 1',
  twoYears = 'year 2',
  threeYears = 'year 3',
  fourYears = 'year 4',
  fiveYears = 'year 5',
  sixYears = 'year 6',
  sevenYears = 'year 7',
  eightYears = 'year 8',
  nineYears = 'year 9',
  tenYears = 'year 10',
}

const ResetYear = () => {
  const defaultValue = useSelector(selectResetYear);
  const [isAnyResetYear, setisAnyResetYear] = useState<Array<string>>(defaultValue);

  return (
    <div className="FormSlide">
      <div className="FormSlide__heading mb35">
        <p className="FormSlide__title">In what year does the reset occur?</p>
        <p className="FormSlide__subTitle">If more than one, select all that apply.</p>
      </div>
      <RadioGroup
        maxWidth="680px"
        isAnyValueSelected={isAnyResetYear}
        setIsAnyValueSelected={setisAnyResetYear}
        radioButtonTitles={Object.values(resetYearOptions)}
        multiSelect
      />
      <NextButton
        isDisabled={!isAnyResetYear.length}
        slideData={{[InterestRateSubsteps[InterestRateSubsteps.resetYear]]: isAnyResetYear}}
        action={setResetYear}
        nextSlide={{
          step: QuoteTermsSteps[QuoteTermsSteps.interestRate],
          substep: InterestRateSubsteps[InterestRateSubsteps.rateDetails],
        }}
      />
    </div>
  );
};

export default ResetYear;

import {FC, useState} from 'react';
import {useDispatch} from 'react-redux';

import {Org, Share, UserSettings} from '@burnaby/ava';

import {CircularProgress} from '@mui/material';

// Material UI
import {ReactComponent as PhoneIcon} from 'assets/icons/phone.svg';
import {ReactComponent as EmailIcon} from 'assets/icons/email.svg';
import {ReactComponent as CopyIcon} from 'assets/icons/CopyIcon.svg';

// Core
import {AvaType, CapitalSource, PersonType} from 'core/_types/base';

// Store
import {declineShare, generateCopyLink, undeclineShare} from 'store/shares-service/asyncActions';

// Components
import ModalWin from 'shared/components/Modal/ModalWin';
import BasicButton from 'shared/components/BasicButton';

import {ModalTypes} from '../helpers';

import s from './CapitalSources.module.scss';

type CapitalSourceDetailsProps = {
  ava: AvaType;
  userSettings: UserSettings;
  capitalSource?: CapitalSource;
  contacts?: Array<PersonType>;
  isSaved?: boolean;
  share?: Share;
  provider?: Org;
  isLoading?: boolean;
  isLinkCopied?: boolean;
  dealApproved: boolean;
};

export const CapitalSourceDetails: FC<CapitalSourceDetailsProps> = ({
  ava,
  userSettings,
  capitalSource,
  contacts,
  isSaved = false,
  share,
  provider,
  isLoading,
  isLinkCopied,
  dealApproved,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState<ModalTypes>();
  const [copyLinkText, setCopyLinkText] = useState<string>('Copy Link');

  const handleShareDecline = () => {
    dispatch(declineShare({ava, shareSym: share?.sym}));
  };

  const handleShareUndecline = () => {
    dispatch(undeclineShare({ava, shareSym: share?.sym}));
  };

  const handleCreateOffer = () => {
    window.location.href = `https://platform.stacksource.com/project:${share?.project}/new-offer:${share?.sym}`;
  };

  const handleClick = (modalType: ModalTypes): void => {
    setOpen(true);
    setModal(modalType);
  };

  const handleCopyLink = async () => {
    if (share) {
      setCopyLinkText('...');
      const [url, clipboardPermission] = await Promise.all([
        ava.shares.createInviteUrl(share.sym),
        navigator.permissions.query({name: 'clipboard-write'} as unknown as PermissionDescriptor),
      ]);
      if (clipboardPermission.state === 'granted' || clipboardPermission.state === 'prompt') {
        try {
          await navigator.clipboard.writeText(url);
          setCopyLinkText('Link Copied!');
        } catch (e) {
          // eslint-disable-next-line no-alert
          prompt('Copy the URL:', url);
          setCopyLinkText('Copy Link');
        }
      } else {
        // eslint-disable-next-line no-alert
        prompt('Copy the URL:', url);
        setCopyLinkText('Copy Link');
      }
    } else {
      setCopyLinkText('Error');
    }
  };

  const underwritingQualifications = capitalSource?.formatUnderwritingQualifications();

  return (
    <>
      <ModalWin isOpen={open} setOpen={setOpen} modal={modal} share={share} provider={provider} />
      {!isLoading ? (
        <div className={s.details}>
          {!dealApproved ? null : isLinkCopied ? (
            <p>Link copied to clipboard</p>
          ) : (
            <BasicButton
              className={s.details__copyButton}
              onClick={() => share && dispatch(generateCopyLink(share.sym))}
            >
              <CopyIcon />
              Copy Link
            </BasicButton>
          )}
          {/* TODO: Will be added in the future releases */}
          {/* <div className={s.details__row}> */}
          {/* <div>
            <p className={s.details__heading}>Recent Quotes</p>
            <div className={s.details__body} />
          </div> */}
          {/* <div>
            <p className={s.details__heading}>Typical closing time</p>
            <div className={s.details__body} />
          </div> */}
          {/* </div> */}
          {contacts?.length === 0 ? null : (
            <div>
              <p className={s.details__heading}>Contact</p>
              <div className={s.details__body}>
                {contacts &&
                  contacts.map((contact: PersonType) => (
                    <div key={contact?.email}>
                      <p>
                        <strong>{contact?.displayName}</strong>
                      </p>
                      <div className={s.details__links}>
                        {!contact?.phoneNumber || contact.phoneNumber.isEmpty() ? null : (
                          <div className={s.details__links__row}>
                            <PhoneIcon className={s.details__link__icon} />
                            <a className={s.details__link__link} href={contact?.phoneNumber?.toUrlString()}>
                              {contact?.phoneNumber?.format(userSettings)}
                            </a>
                          </div>
                        )}
                        {contact?.email ? (
                          <div className={s.details__links__row}>
                            <EmailIcon className={s.details__link__icon} />
                            <a className={s.details__link__link} href={`mailto:${encodeURIComponent(contact?.email)}`}>
                              {contact?.email}
                            </a>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
          {capitalSource?.marketingDescription ? (
            <div>
              <p className={s.details__heading}>Overview</p>
              <div className={s.details__body}>{capitalSource?.marketingDescription}</div>
            </div>
          ) : null}
          {capitalSource?.notes ? (
            <div>
              <p className={s.details__heading}>Internal notes</p>
              <div className={s.details__body}>{formatNotes(capitalSource?.notes)}</div>
            </div>
          ) : null}
          {!capitalSource?.formatHighlights()?.length ? null : (
            <div>
              <p className={s.details__heading}>Program highlights</p>
              <div className={s.details__body}>
                <ul className={s.highlights}>
                  {capitalSource?.formatHighlights().map((highlight) => (
                    <li key={highlight} className={s.highlights__item}>
                      {highlight}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {!underwritingQualifications?.length ? null : (
            <div>
              <p className={s.details__heading}>Deals that may qualify</p>
              <div className={s.details__body}>
                <ul className={s.highlights}>
                  {underwritingQualifications.map((highlight) => (
                    <li key={highlight} className={s.highlights__item}>
                      {highlight}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {isSaved && (
            <div className={s.sources__actions}>
              <BasicButton className="blue" onClick={handleCreateOffer}>
                Create Offer
              </BasicButton>
              {share?.declined ? (
                <BasicButton className="text" onClick={handleShareUndecline}>
                  Undecline Deal
                </BasicButton>
              ) : (
                <BasicButton className="text" onClick={handleShareDecline}>
                  Decline Deal
                </BasicButton>
              )}
              <BasicButton className="text" onClick={() => handleClick(ModalTypes.removeShare)}>
                Remove
              </BasicButton>
              {!share || !dealApproved ? null : (
                <BasicButton className="text" onClick={handleCopyLink}>
                  {copyLinkText}
                </BasicButton>
              )}
            </div>
          )}
        </div>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

const formatNotes = (notes: string) => {
  const items = notes.replace(/^\*\s*/, '').split(/\s*\*\s*/);
  return (
    <ul className={s.highlights}>
      {items.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={i} className={s.highlights__item}>
          {item}
        </li>
      ))}
    </ul>
  );
};

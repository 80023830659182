import {useRef} from 'react';
import {useFormikContext} from 'formik';

// Components
import {UnsavedChangesPrompt} from 'shared/components/UnsavedChangesPrompt';
import StepTextInput from 'shared/components/StepTextInput';

import s from './Fourth.module.scss';
import useGoogleAutocomplete from '../../../../core/utils/hooks/useGoogleAutocomplete';

const Fourth = () => {
  const {values, setFieldValue, handleChange}: any = useFormikContext();

  const mainAddressField = useRef<HTMLInputElement>(null);
  const {line1} = values.address;
  useGoogleAutocomplete({mainAddressField, setFieldValue, line1});

  return (
    <div className={s.fourth}>
      <div className={s.fourth__header}>
        <p className={s.fourth__title}>What is the address of your project?</p>
        <p className={s.fourth__subtitle}>
          If you are financing multiple properties together, input the address of one of the properties.
        </p>
      </div>
      <div className={s.fourth__inputsContainer}>
        <div className={s.fourth__inputs}>
          <StepTextInput
            autoCompleteRef={mainAddressField}
            id="line1"
            name="address.line1"
            value={values.address.line1}
            onValueChange={handleChange}
            label="Street Address*"
            placeholder="Enter street address"
            width="100%"
            required
          />
          <div className={s.fourth__inputsRow}>
            <StepTextInput
              value={values.address.city}
              onValueChange={handleChange}
              id="city"
              name="address.city"
              label="City*"
              placeholder="Enter city"
              required
            />
            <StepTextInput
              value={values.address.state}
              onValueChange={handleChange}
              id="state"
              name="address.state"
              label="State*"
              placeholder="Enter state"
              required
            />
            <StepTextInput
              value={values.address.zip}
              onValueChange={handleChange}
              id="zip"
              name="address.zip"
              label="Zip Code*"
              placeholder="Enter zip code"
              required
            />
          </div>
          <UnsavedChangesPrompt />
        </div>
      </div>
    </div>
  );
};

export {Fourth};

import {FC, useEffect, useState} from 'react';
import {Switch, Route, useLocation, matchPath, match, useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {browserVersion} from 'react-device-detect';

import 'react-toastify/dist/ReactToastify.css';

// Core
import {ROUTERS} from 'core/_consts';
import {sinatraNewDealUrl, toSinatraUrl} from 'core/helpers/sinatra';

// Redux
import {selectorGetProfile} from 'store/ava-service/selector';
import {selectorGetProject} from 'store/project-service/selector';
import {getProject} from 'store/project-service/asyncActions';

// Components
import {StyledCircularProgress} from 'shared/components/CircularLoader';
import CreateNewDeal from 'pages/CreateNewDeal';
import ProjectView from 'pages/ProjectView';
import LenderView from 'pages/LenderView';
import Dashboard from 'pages/Dashboard';
import Profile from 'pages/Profile';
import Login from 'pages/Login';
import SignUp from 'pages/SignUp';
import Browser from 'shared/components/Browser';
import Header from 'shared/components/Header';

let hupTm: any = null;

// Hack to work around the current version using local storage for filters.
localStorage.clear();

const App: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const project = useSelector(selectorGetProject);
  const [loaderState, setLoaderState] = useState(true);
  let sBrowser;
  const sUsrAg = navigator.userAgent;

  if (sUsrAg.indexOf('Firefox') > -1 && browserVersion >= '103') {
    sBrowser = 'Mozilla Firefox';
  } else if (sUsrAg.indexOf('Edge') > -1 && browserVersion >= '104') {
    sBrowser = 'Microsoft Edge';
  } else if (sUsrAg.indexOf('Chrome') > -1 && browserVersion >= '104') {
    sBrowser = 'Google Chrome or Chromium';
  } else if (sUsrAg.indexOf('Safari') > -1 && browserVersion >= '5') {
    sBrowser = 'Apple Safari';
  } else {
    sBrowser = 'unknown';
    window.location.href = toSinatraUrl(window.location.href, true);
    return (
      <>
        <p>Redirecting...</p>
      </>
    );
  }

  useEffect(() => {
    if (!project) {
      const setProjectSym = () => {
        const matchedUrl: match<{dealSym: string; tab: string}> | null =
          matchPath(location.pathname, {
            path: ROUTERS.PROJECT_VIEW,
          });
        if (matchedUrl && matchedUrl.params.dealSym !== 'new') {
          // Redirect "lenders" tab to "capital-sources" and "overview" to "underwriting"
          const {dealSym, tab} = matchedUrl.params;
          if (tab === 'lenders') {
            history.replace(`${ROUTERS.PROJECT}:${dealSym}/capital-sources`);
          } else if (tab === 'overview') {
            history.replace(`${ROUTERS.PROJECT}:${dealSym}/underwriting`);
          }
          dispatch(getProject({projectSym: matchedUrl.params.dealSym}));
        } else {
          const matchedUrl: match<{dealSym: string; tab: string}> | null =
            matchPath(location.pathname, {
              path: ROUTERS.PROJECT_VIEW_OLD,
            });
          if (matchedUrl) {
            const {dealSym, tab} = matchedUrl.params;
            // Redirect "lenders" tab to "capital-sources" and "overview" to "underwriting"
            if (tab === 'lenders' || tab === 'overview') {
              history.replace(`${ROUTERS.PROJECT}:${dealSym}/underwriting`);
            } else {
              history.replace(`${ROUTERS.PROJECT}:${dealSym}/${tab}`);
            }
            dispatch(getProject({projectSym: dealSym}));
          } else {
            setLoaderState(false);
            // TODO: Check if user is authorized (or redirect him to the login page)/
            // history.push(ROUTERS.LOGIN);
            if (!matchPath(location.pathname, {
              path: ROUTERS.CREATE_NEW_PROJECT_MATCH,
            })) {
              window.location.href = toSinatraUrl(window.location.href);
            }
          }
        }
      };
      setProjectSym();
    } else {
      setLoaderState(false);
    }
  }, [project]);

  let lastHUP: any = null;
  clearInterval(hupTm);
  hupTm = setInterval(() => {
    const hackUpdateProject = (window as any).HACK_updateProject;
    if (hackUpdateProject && (!lastHUP || !hackUpdateProject.equals(lastHUP))) {
      lastHUP = hackUpdateProject;
      dispatch(getProject({projectSym: hackUpdateProject.sym}));
      (window as any).HACK_updateProject = null;
    }
  }, 200);

  if (loaderState && !project) return <StyledCircularProgress />;

  const matchedUrl = matchPath(location.pathname, {
    path: ROUTERS.CREATE_NEW_PROJECT_MATCH,
  });
  if (matchedUrl) {
    window.location.href = sinatraNewDealUrl();
    return (
      <>
        <p>Redirecting...</p>
      </>
    );
  }

  return (
    <>
      <Switch>
        <Route path={ROUTERS.LOGIN} component={Login} />
        <Route path={ROUTERS.SIGNUP} component={SignUp} />
        <Route path={ROUTERS.DASHBOARD} component={Dashboard} />
        <Route
          path={ROUTERS.CREATE_NEW_PROJECT_MATCH}
          component={CreateNewDeal}
        />
        {/* <Route path={ROUTERS.LENDER_VIEW} component={LenderView} /> */}
        <Route path={ROUTERS.PROJECT_VIEW} exact component={ProjectView} />
        <Route path={ROUTERS.PROFILE} exact component={Profile} />
      </Switch>
    </>
  );
};

export default App;

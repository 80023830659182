import {FC, useState} from 'react';
import {loanFeesOptions, LoanFeesSubsteps, QuoteTermsSteps} from 'core/_consts';
import RadioGroup from 'shared/components/RadioGroup';
import NextButton from 'shared/components/NextButton';
import {setSelectedLoanFeesValues} from 'store/quote-terms-service/quoteTermsSlice';
import {useSelector} from 'react-redux';
import {selectSelectedLoanFeesValues} from 'store/quote-terms-service/quoteTermsSelector';

const SelectLoanFees: FC = () => {
  const defaultValue = useSelector(selectSelectedLoanFeesValues);
  const [selectedLoanFees, setSelectedLoanFees] = useState<Array<string>>(defaultValue);

  return (
    <div className="FormSlide">
      <div className="FormSlide__heading">
        <p className="FormSlide__title">Add the loan fees.</p>
        <p className="FormSlide__subTitle mb25">Select all fee types that apply.</p>
      </div>
      <RadioGroup
        maxWidth="540px"
        additionalClassName="loanFees"
        isAnyValueSelected={selectedLoanFees}
        setIsAnyValueSelected={setSelectedLoanFees}
        radioButtonTitles={Object.values(loanFeesOptions).map((el) => el.toLowerCase())}
        multiSelect
      />
      <NextButton
        isDisabled={!selectedLoanFees.length}
        slideData={{[LoanFeesSubsteps[LoanFeesSubsteps.selectLoanFees]]: selectedLoanFees}}
        action={setSelectedLoanFeesValues}
        nextSlide={{
          step: QuoteTermsSteps[QuoteTermsSteps.loanFees],
          substep: LoanFeesSubsteps[LoanFeesSubsteps.feesDetails],
        }}
      />
    </div>
  );
};

export default SelectLoanFees;

import {useEffect, useState, useMemo} from 'react';
import RadioGroup from 'shared/components/RadioGroup';
import NumericInput from 'shared/components/NumericInput';
import NextButton from 'shared/components/NextButton';
import {
  PrepayPenaltySubsteps,
  QuoteTermsSteps,
  RecourseSubsteps,
  yesNoOptions,
  MaxValidationLimits,
} from 'core/_consts';
import {validateNumericInput} from 'core/utils';
import {setBurnOffAvailable} from 'store/quote-terms-service/quoteTermsSlice';
import {useSelector} from 'react-redux';
import {selectBurnOff} from 'store/quote-terms-service/quoteTermsSelector';
import {nextButtonClickHandler, NextButtonRefType} from 'pages/LenderView/helpers';

enum whatDoesItBurnToOptions {
  nonRecourse = 'non-recourse',
  partialRecourse = 'partial recourse',
}

const handleSelectedValue = (value: string) =>
  value === whatDoesItBurnToOptions.nonRecourse || value === whatDoesItBurnToOptions.partialRecourse
    ? {
        step: QuoteTermsSteps[QuoteTermsSteps.recourse],
        substep: RecourseSubsteps[RecourseSubsteps.burnOffTrigger],
      }
    : {
        step: QuoteTermsSteps[QuoteTermsSteps.prepayPenalty],
        substep: PrepayPenaltySubsteps[PrepayPenaltySubsteps.anyPenalty],
      };

const isButtonActive = (isRecourseBurn: string, whatDoesItBurnTo: string): boolean =>
  isRecourseBurn === yesNoOptions.no ||
  whatDoesItBurnTo === whatDoesItBurnToOptions.nonRecourse ||
  whatDoesItBurnTo === whatDoesItBurnToOptions.partialRecourse;

const BurnOffAvailable = () => {
  const defaultValues = useSelector(selectBurnOff);
  const [isRecourseBurn, setIsRecourseBurn] = useState<Array<string>>(defaultValues.isRecourseBurn);
  const [whatDoesItBurnTo, setWhatDoesItBurnTo] = useState<Array<string>>(defaultValues.whatDoesItBurnTo);
  const [partialRecoursePercent, setPartialRecoursePercent] = useState(defaultValues.partialRecoursePercent);
  const [nextButtonRef, setNextButtonRef] = useState<NextButtonRefType>(null);

  const isRecourseBurnOff = useMemo(() => isRecourseBurn.includes(yesNoOptions.yes), [isRecourseBurn]);
  const isPartialRecourseSelected = useMemo(
    () => whatDoesItBurnTo.includes(whatDoesItBurnToOptions.partialRecourse),
    [whatDoesItBurnTo]
  );

  useEffect(() => {
    if ((whatDoesItBurnTo.length || isRecourseBurn.length) && (!isPartialRecourseSelected || !isRecourseBurnOff))
      nextButtonClickHandler(nextButtonRef);
  }, [isRecourseBurn, whatDoesItBurnTo, isRecourseBurnOff, isPartialRecourseSelected]);

  return (
    <div className="FormSlide">
      <p className="FormSlide__title mb35">Does the recourse burn off?</p>
      <RadioGroup
        isAnyValueSelected={isRecourseBurn}
        setIsAnyValueSelected={setIsRecourseBurn}
        radioButtonTitles={Object.values(yesNoOptions)}
        nextButtonTrigger={() => nextButtonClickHandler(nextButtonRef)}
      />
      {isRecourseBurnOff && (
        <div className="FormSlide__form gap25">
          <p className="FormSlide__subTitle mt25">What does it burn to?</p>
          <div className="FormSlide__section FormSlide__section_column">
            <RadioGroup
              isAnyValueSelected={whatDoesItBurnTo}
              setIsAnyValueSelected={setWhatDoesItBurnTo}
              radioButtonTitles={Object.values(whatDoesItBurnToOptions)}
              nextButtonTrigger={() => nextButtonClickHandler(nextButtonRef)}
            />
            {isPartialRecourseSelected && (
              <NumericInput
                defaultValue={partialRecoursePercent || ''}
                preventionLimit={MaxValidationLimits.percent}
                onValueChange={(values) => setPartialRecoursePercent(Number(values.value))}
                isValid={validateNumericInput(Number(partialRecoursePercent), MaxValidationLimits.percent)}
                width="80px"
                sign="%"
              />
            )}
          </div>
        </div>
      )}
      <NextButton
        setNextButtonRef={setNextButtonRef}
        isHidden={!isPartialRecourseSelected}
        isDisabled={
          !isButtonActive(isRecourseBurn[0], whatDoesItBurnTo[0]) ||
          (isPartialRecourseSelected &&
            !validateNumericInput(Number(partialRecoursePercent), MaxValidationLimits.percent))
        }
        slideData={{
          [RecourseSubsteps[RecourseSubsteps.burnOffAvailable]]: {
            isRecourseBurn,
            whatDoesItBurnTo: isRecourseBurn.includes(yesNoOptions.yes) ? whatDoesItBurnTo : [],
            partialRecoursePercent: isRecourseBurn.includes(yesNoOptions.yes) ? partialRecoursePercent : null,
          },
        }}
        action={setBurnOffAvailable}
        nextSlide={handleSelectedValue(whatDoesItBurnTo[0])}
      />
    </div>
  );
};

export default BurnOffAvailable;

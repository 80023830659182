import {FC, useState} from 'react';
import {useFormikContext} from 'formik';

// Pages
import SelectionCard from 'pages/CreateNewDeal/SelectionCard';
import {MultiFamilyTypes, isValue} from 'pages/CreateNewDeal/helpers';

import s from './Second.module.scss';

type SecondProps = {
  goNext: () => void;
};

const Second: FC<SecondProps> = ({goNext}) => {
  const {values, setFieldValue}: any = useFormikContext();

  const [selectedCard, setSelectedCard] = useState(values.assetType);
  const iconTitles = Object.keys(MultiFamilyTypes).filter(isValue);

  return (
    <div className={s.second}>
      <div className={s.second__header}>
        <p className={s.second__title}>
          What type of {selectedCard} property?
        </p>
      </div>
      {iconTitles.map((iconTitle) => (
        <SelectionCard
          key={iconTitle}
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          handleCardSelection={() => {
            setFieldValue('multiFamilyAcquisition', iconTitle);
            setSelectedCard(iconTitle);
            goNext();
          }}
          title={iconTitle}
          iconName={iconTitle.toLocaleLowerCase()}
        />
      ))}
    </div>
  );
};

export {Second};

import {useMemo} from 'react';
import {QuoteTermsSteps, PrepayPenaltySubsteps, MaxValidationLimits, dateOptions} from 'core/_consts';
import NextButton from 'shared/components/NextButton';
import {Formik, Form} from 'formik';
import {prepaymentPenaltySchema} from 'core/helpers/yupSchemas';
import {setPrepaymentPenalty} from 'store/quote-terms-service/quoteTermsSlice';
import {useSelector} from 'react-redux';
import {
  selectPrepaymentPenalty,
  selectLoanTermDuration,
  selectTermDuration,
} from 'store/quote-terms-service/quoteTermsSelector';
import Controller from 'shared/components/FormikController';
import {FormikElement} from 'core/enums/formik-controller.enum';
import {getYearsFromMonths} from 'core/utils';

const maxInputsAmount = 10;

const PrepaymentPenalty = () => {
  const defaultValues = useSelector(selectPrepaymentPenalty);
  const termDurationValues = useSelector(selectTermDuration);
  const loanTermDuration = useSelector(selectLoanTermDuration);

  const inputsAmount = loanTermDuration.length
    ? Number(loanTermDuration[0].split(' ')[0])
    : getYearsFromMonths(
        termDurationValues.termValue,
        dateOptions[termDurationValues.dateValue as keyof typeof dateOptions]
      );

  const initialValues = useMemo(
    () =>
      defaultValues.years.length
        ? defaultValues
        : {
            years: Array(inputsAmount > maxInputsAmount ? maxInputsAmount : inputsAmount)
              .fill(null)
              .map((_, index) => ({name: `Year ${index + 1}`, value: ''})),
          },
    [defaultValues, inputsAmount]
  );

  return (
    <div className="FormSlide">
      <p className="FormSlide__title mb35">Enter the prepayment penalty that applies to each year of the loan term.</p>
      <Formik
        initialValues={initialValues}
        validationSchema={prepaymentPenaltySchema}
        validateOnMount
        enableReinitialize
        // TODO: add handle logic on submit
        // eslint-disable-next-line no-console
        onSubmit={(values) => console.log(values)}
      >
        {({isValid, values, setFieldValue}) => (
          <Form className="FormSlide__form">
            <div className="FormSlide__section flexWrap">
              {initialValues.years.map((item, index) => (
                <Controller
                  key={item.name}
                  name={`years[${index}].value`}
                  control={FormikElement.NUMERIC_INPUT}
                  width="42px"
                  label={item.name}
                  sign="%"
                  setFieldValue={setFieldValue}
                  preventionLimit={MaxValidationLimits.percent}
                  decimalScale={0}
                />
              ))}
            </div>
            <NextButton
              isDisabled={!isValid}
              slideData={{[PrepayPenaltySubsteps[PrepayPenaltySubsteps.prepaymentPenalty]]: values}}
              action={setPrepaymentPenalty}
              nextSlide={{
                step: QuoteTermsSteps[QuoteTermsSteps.prepayPenalty],
                substep: PrepayPenaltySubsteps[PrepayPenaltySubsteps.feeAmount],
              }}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PrepaymentPenalty;

import {FC} from 'react';
import {Link} from 'react-router-dom';
import cx from 'classnames';

import {SinatraDealTab, sinatraDealUrl} from 'core/helpers/sinatra';

import {ReactComponent as UnderwritingIcon} from 'assets/icons/underwriting.svg';
import {ReactComponent as BrowseCapitalSourcesIcon} from 'assets/icons/browse_capital_sources.svg';
import {ReactComponent as YourOffersIcon} from 'assets/icons/your_offers.svg';

import {ProjectViewTabs} from '../helpers';

import s from './Navbar.module.scss';

type NavbarProps = {
  projectSym: string;
  tab: string;
};

const Navbar: FC<NavbarProps> = ({projectSym, tab}) => (
  <div className={s.Navbar}>
    <div className={s.Navbar__list}>
      <Link
        to={`${ProjectViewTabs[ProjectViewTabs.underwriting]}`}
        className={cx(s.Navbar__item, tab === ProjectViewTabs[ProjectViewTabs.underwriting] ? s.Navbar__active : '')}
      >
        {tab === ProjectViewTabs[ProjectViewTabs.underwriting] && (
          <div className={cx(s.Navbar__icon, s.Navbar__icon__reverse)}>
            <UnderwritingIcon />
          </div>
        )}
        Underwriting
      </Link>
      <Link
        to={`${ProjectViewTabs[ProjectViewTabs['capital-sources']]}`}
        className={cx(
          s.Navbar__item,
          tab === ProjectViewTabs[ProjectViewTabs['capital-sources']] ? s.Navbar__active : ''
        )}
      >
        {tab === ProjectViewTabs[ProjectViewTabs['capital-sources']] && (
          <div className={s.Navbar__icon}>
            <BrowseCapitalSourcesIcon />
          </div>
        )}
        Browse Capital Sources
      </Link>
      <a
        href={sinatraDealUrl(projectSym, SinatraDealTab['offer-comparison'])}
        className={cx(s.Navbar__item, tab === ProjectViewTabs[ProjectViewTabs.offers] ? s.Navbar__active : '')}
      >
        {tab === ProjectViewTabs[ProjectViewTabs.offers] && (
          <div className={s.Navbar__icon}>
            <YourOffersIcon />
          </div>
        )}
        Your Offers
      </a>
    </div>
  </div>
);

export default Navbar;

import {FC} from 'react';
import cx from 'classnames';
import BasicButton from 'shared/components/BasicButton';
import {LoanAmountSubsteps, QuoteTermsFormStatus, QuoteTermsSteps, ROUTERS} from 'core/_consts';
import {Link, useHistory, useLocation, useParams} from 'react-router-dom';
import {queryParams} from 'core/utils';
import {URLParams} from 'core/_types/base';
import styles from './QuoteTermsButtons.module.scss';

type QuoteTermsButtonsProps = {
  formPosition: string;
  quoteTermsFormStatus: string;
};

const QuoteTermsButtons: FC<QuoteTermsButtonsProps> = ({formPosition, quoteTermsFormStatus}) => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams<URLParams>();

  const undoPath = {pathname: `${ROUTERS.PROJECT}:${params.dealSym}/view`, hash: location.hash};

  const openQuoteTerms = () =>
    history.push(
      `${ROUTERS.PROJECT}:${params.dealSym}/view${queryParams({
        step: QuoteTermsSteps[QuoteTermsSteps.loanAmount],
        substep: LoanAmountSubsteps[LoanAmountSubsteps.loanSizingConstraints],
        formPosition,
      })}${location.hash}`
    );

  const declineQuoteTerms = () =>
    history.push(
      `${ROUTERS.PROJECT}:${params.dealSym}/view${queryParams({
        formPosition: QuoteTermsFormStatus.declined,
      })}${location.hash}`
    );

  return (
    <div
      className={cx(styles.Buttons, {
        [styles.Buttons_declined]: quoteTermsFormStatus === QuoteTermsFormStatus.declined,
        [styles.Buttons_hidden]: formPosition === QuoteTermsFormStatus.bottomOpened,
      })}
    >
      {quoteTermsFormStatus !== QuoteTermsFormStatus.declined ? (
        <>
          <BasicButton onClick={openQuoteTerms} className="blue" variant="contained">
            Quote Terms
          </BasicButton>
          <BasicButton className="white" variant="contained" onClick={declineQuoteTerms}>
            Decline
          </BasicButton>
        </>
      ) : (
        <p>
          You declined to make a loan offer on this project.&nbsp;
          <Link className="link" to={undoPath}>
            Undo
          </Link>
        </p>
      )}
    </div>
  );
};

export default QuoteTermsButtons;

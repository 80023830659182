import {CurrencyValue} from '@burnaby/ava';

import {Source} from 'core/_types/selectOptionsTypes/SourcesType';
import {Use} from 'core/_types/selectOptionsTypes/UsesType';

export default function totalPrincipal(values: (Source | Use)[]): string {
  return values
    .map((u) => CurrencyValue.from(u.principal || 'USD 0'))
    .reduce((acc, u) => acc.add(u), CurrencyValue.from('USD 0'))
    .format();
}

import {Reducer} from 'react';

import {
  Action,
  AnyAction,
  CaseReducer,
  CaseReducerActions,
  ReducersMapObject,
  PayloadAction,
  CreateSliceOptions,
  Slice,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
  createSlice as _createSlice,
} from '@reduxjs/toolkit'

// TODO(nathan): Decide on a plan for state management. This is a stop gap.
export function createSlice<
  State,
  SimpleCaseReducers extends Record<string, Reducer<State, PayloadAction<any>>>,
  CaseReducers extends SliceCaseReducers<State>,
  Name extends string = string
>(
  options: CreateSliceOptions<State, CaseReducers, Name> & {
    simpleReducers: SimpleCaseReducers,
  },
): Slice<State, CaseReducers, Name> & {
  actions:
    CaseReducerActions<CaseReducers, Name>
    & {
      [K in keyof SimpleCaseReducers]:
      (payload: Parameters<SimpleCaseReducers[K]>[1]['payload']) => {
        type: string,
        payload: Parameters<SimpleCaseReducers[K]>[1]['payload'],
      }
    },
} {
  const slice = _createSlice<State, CaseReducers, Name>(options);

  const reducer = slice.reducer;
  const simpleReducers = new Map(
    Object.entries(options.simpleReducers).map(
      ([key, reduce]) => [`${slice.name}/${key}`, reduce],
    ),
  );
  slice.reducer = (state: State|undefined, action: AnyAction) => {
    const simpleReducer = simpleReducers.get(action.type);
    if (simpleReducer && state) {
      return simpleReducer(state, <PayloadAction<any>> action);
    }
    return reducer(state, action);
  }

  const simpleActions = (
    <[
      Exclude<keyof SimpleCaseReducers, symbol>,
      (state: State, action: AnyAction) => State][]
    >
    Object.entries(options.simpleReducers)
  ).reduce(
    (acc, [key]) => {
      acc[key] = (payload: any) => ({
        type: `${slice.name}/${key}`,
        payload,
      });
      return acc;
    },
    <
      Record<
        keyof SimpleCaseReducers,
        (payload: any) => {type: string, payload: any}
      >
    > {},
  );

  const actions  = Object.assign(
    {},
    slice.actions,
  );

  return {
    ...slice,
    actions: {
      ...slice.actions,
      ...simpleActions,
    },
  };
}

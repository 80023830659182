import {ChangeEvent, FC, useEffect, useRef, useState} from 'react';
import {Grid} from '@mui/material';

// Shared
import TextInput from 'shared/components/TextInput';
import NumericInput from 'shared/components/NumericInput';
import {THEMES} from 'shared/helpers';
import {StepSelect} from 'shared/components/StepSelect';

// Core
import {CreditScore, DealGuarantor, StandardUsAddress, usd} from 'core/service/ava';
import {creditScoreOptions} from 'core/utils/selectOptions/guarantorsOptions';
import useGoogleAutocomplete from 'core/utils/hooks/useGoogleAutocomplete';
import {statusMessage} from 'core/enums/statusMessage';

// Styles
import s from './Guarantors.module.scss';

type GuarantorsFormProps = {
  value: DealGuarantor;
  setValue: (value: DealGuarantor) => void;
  setOpen: (open: boolean) => void;
  completeStatus?: string;
};

const GuarantorsForm: FC<GuarantorsFormProps> = ({value, setValue, setOpen, completeStatus}) => {
  const [current, setCurrent] = useState<DealGuarantor>(value);

  const mainAddressField = useRef<HTMLInputElement>(null);

  const updater = (callback: (guarantor: DealGuarantor, value: string) => DealGuarantor) => (e: ChangeEvent<any>) =>
    setCurrent(callback(current, e.target.value));

  const addressUpdater = (callback: (address: StandardUsAddress, value: string) => StandardUsAddress) =>
    updater((g, v) => g.setAddress(callback(g.address, v)));

  useGoogleAutocomplete({
    mainAddressField,
    setAddress: (addr: StandardUsAddress) => {
      setCurrent(current.setAddress(addr));
    },
    line1: current.address.line1,
  });

  return (
    <div className={s.guarantors__container}>
      <Grid container spacing={2.5} rowSpacing={3}>
        <Grid item xs={12}>
          <TextInput
            name="name"
            value={current.name}
            onValueChange={updater((g, v) => g.setName(v))}
            fullWidth
            label="Name or Entity*"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            autoCompleteRef={mainAddressField}
            name="address.line1"
            value={current.address.line1}
            onValueChange={addressUpdater((a, v) => a.setLine1(v))}
            fullWidth
            label="Address"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            name="address.line2"
            value={current.address.line2}
            onValueChange={addressUpdater((a, v) => a.setLine2(v))}
            fullWidth
            label="Address Line 2 (optional)"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            name="address.city"
            value={current.address.city}
            onValueChange={addressUpdater((a, v) => a.setCity(v))}
            fullWidth
            label="City"
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            name="address.state"
            value={current.address.state}
            onValueChange={addressUpdater((a, v) => a.setState(v))}
            fullWidth
            label="State"
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            name="address.zip"
            value={current.address.zip}
            onValueChange={addressUpdater((a, v) => a.setZip(v))}
            fullWidth
            label="ZIP"
          />
        </Grid>
        <Grid item xs={6}>
          <NumericInput
            id="netWorth"
            name="netWorth"
            defaultValue={current.netWorth.toNumber()}
            onValueChange={updater((g, v) => g.setNetWorth(usd(v)))}
            theme={THEMES.grey}
            thousandSeparator
            fullWidth
            placeholder="$"
            textAlign="left"
            label="Net Worth"
          />
        </Grid>
        <Grid item xs={6}>
          <NumericInput
            id="liquidity"
            name="liquidity"
            defaultValue={current.liquidity.toNumber()}
            onValueChange={updater((g, v) => g.setLiquidity(usd(v)))}
            theme={THEMES.grey}
            thousandSeparator
            fullWidth
            placeholder="$"
            textAlign="left"
            label="Liquidity"
          />
        </Grid>
        <Grid item xs={12}>
          <StepSelect
            name="creditScore"
            value={CreditScore.format(current.creditScore)}
            setValue={(field: string, value: string) => setCurrent(current.setCreditScore(CreditScore.from(value)))}
            options={creditScoreOptions}
            theme={THEMES.blue}
            label="Credit Score"
          />
        </Grid>
      </Grid>
      <footer className={s.guarantors__footer}>
        {completeStatus !== statusMessage.success && (
          <Grid item xs={12} sx={{fontStyle: 'italic', color: '#757575', marginTop: '15px'}}>
            <p>{completeStatus}</p>
          </Grid>
        )}

        <button className={s.guarantors__saveBtn} onClick={() => setValue(current)}>
          Save
        </button>
        <button className={s.guarantors__cancelBtn} onClick={() => setOpen(false)}>
          Cancel
        </button>
      </footer>
    </div>
  );
};

export default GuarantorsForm;

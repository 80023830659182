import {FC} from 'react';
import cx from 'classnames';
import RadioButton from 'shared/components/RadioButton';
import s from './RadioGroup.module.scss';

type RadioGroupProps = {
  radioButtonTitles: Array<string>;
  multiSelect?: boolean;
  isAnyValueSelected: Array<string>;
  setIsAnyValueSelected: (id: Array<string>) => void;
  buttonWidth?: string;
  additionalClassName?: string;
  maxWidth?: string;
  nextButtonTrigger?: () => void;
};

const RadioGroup: FC<RadioGroupProps> = ({
  radioButtonTitles,
  isAnyValueSelected,
  setIsAnyValueSelected,
  nextButtonTrigger,
  multiSelect = false,
  buttonWidth,
  maxWidth,
  additionalClassName = '',
}) => (
  <div style={{maxWidth}} className={cx(s.radioGroup, s[additionalClassName])}>
    {!!radioButtonTitles.length &&
      radioButtonTitles.map((item, index) => (
        <RadioButton
          key={item.repeat(index)}
          title={item}
          nextButtonTrigger={nextButtonTrigger}
          activeValues={isAnyValueSelected}
          setActiveValues={setIsAnyValueSelected}
          multiSelect={multiSelect}
          width={buttonWidth}
        />
      ))}
  </div>
);
export default RadioGroup;

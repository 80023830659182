import {FC, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Slider} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Form, Formik} from 'formik';

import {CurrencyUnit, CurrencyValue, Percent, Term, ava} from 'core/service/ava';
import {MaxValidationLimits} from 'core/_consts';
import {Filters} from 'core/_types/Filters';

import BasicButton from 'shared/components/BasicButton';
import {THEMES} from 'shared/helpers';
import CheckboxButton from 'shared/components/CheckboxButton';
import NumericInput from 'shared/components/NumericInput';

import {selectorGetProject} from 'store/project-service/selector';
import {getCapitalSources} from 'store/project-service/asyncActions';

import s from './Subordinate.module.scss';

const useStyles = makeStyles({
  slider: {
    color: THEMES.blue,
  },
  sliderSecondary: {
    color: THEMES.blue,
    marginTop: '3px',
  },
});

enum RecourseOption {
  NON_RECOURSE = 'NON_RECOURSE',
  PARTIAL_RECOURSE = 'PARTIAL_RECOURSE',
}

const currency0 = CurrencyValue.fromNumber(CurrencyUnit.USD, 0);
const percent100 = Percent.fromNumber(1);

let updateAmountFieldsTm = 0;

const debugLastFilters = new Map<string, Filters>();
const debugLastSenior = new Map<string, number>();

type SubordinateProps = {
  handleClose: () => void;
};

const Subordinate: FC<SubordinateProps> = ({handleClose}) => {
  const dispatch = useDispatch();

  const project = useSelector(selectorGetProject);

  const initialValues: Filters = debugLastFilters.get(project?.sym) || {};

  const valuation = project.estimatedValuation.value;
  const stableValuation = project.stabilizedEstimatedValuation.value;
  const budget = project.totalProjectBudget;
  const noi = project.currentNetOperatingIncome;
  const stableNoi = project.proFormaNetOperatingIncome;
  const maxSeniorLoan = Math.ceil(CurrencyValue.max(budget, valuation).toNumber());

  const [years, setYears] = useState<number[]>([
    // HACK to make it remember previous value.
    parseTerm(initialValues?.term?.lowerBound) || 0,
    // HACK to make it remember previous value.
    parseTerm(initialValues?.term?.upperBound) || 40,
  ]);
  const [seniorLoan, setSeniorLoan] = useState<number>(debugLastSenior.get(project?.sym) || 0);
  const [subordinateDebt, setSubordinateDebt] = useState<number>(
    // HACK to make it remember previous value.
    CurrencyValue.fromString(initialValues?.loanAmount || `USD 0`).toNumber()
  );

  const seniorLoanCv = CurrencyValue.fromNumber(CurrencyUnit.USD, seniorLoan);
  const maxSubordinateDebt = maxSeniorLoan - seniorLoan;
  const cBudget = CurrencyValue.max(currency0, budget.subtract(seniorLoanCv));
  const cValuation = CurrencyValue.max(currency0, valuation.subtract(seniorLoanCv));
  const cStableValuation = CurrencyValue.max(currency0, stableValuation.subtract(seniorLoanCv));
  const equity = maxSubordinateDebt - subordinateDebt;
  const ltv = Percent.min(percent100, seniorLoanCv.divide(valuation, percent100));
  const ltc = Percent.min(percent100, seniorLoanCv.divide(budget, percent100));

  const classes = useStyles();

  const handleRangeChange = (event: Event, newValue: number | number[]) => {
    setYears(newValue as number[]);
  };

  const handleSeniorLoanChange = (event: Event, newValue: number | number[]) => {
    setSeniorLoan(newValue as number);
    setSubordinateDebt(0);
  };

  const handleSubordinateDebtChange = (
    setFieldValue: (field: string, value: any) => void,
    event: Event,
    newValue: number | number[]
  ) => {
    setSubordinateDebt(newValue as number);
    clearTimeout(updateAmountFieldsTm);
    updateAmountFieldsTm = setTimeout(
      () => updateAmountFields(setFieldValue, newValue as number),
      50
    ) as unknown as number;
  };

  const updateAmountFields = (
    setFieldValue: (field: string, value: any) => void,
    newValue: number,
    skipField: string | null = null
  ) => {
    clearTimeout(updateAmountFieldsTm);
    setFieldValue('loanAmount', `USD ${newValue}`);

    const subordinateDebtCv = CurrencyValue.fromNumber(CurrencyUnit.USD, newValue);
    const totalDebt = seniorLoanCv.add(subordinateDebtCv);

    if (skipField !== 'ltc' && !totalDebt.isZero()) {
      const ltc = Percent.min(percent100, totalDebt.divide(budget, percent100));
      setFieldValue('ltc', ltc.formatMaxDecimalPlaces(0));
    }

    if (skipField !== 'ltv' && !totalDebt.isZero()) {
      const ltv = Percent.min(percent100, totalDebt.divide(valuation, percent100));
      setFieldValue('ltv', ltv.formatMaxDecimalPlaces(0));
    }

    if (skipField !== 'asStabilizedLtv' && !totalDebt.isZero()) {
      const ltv = Percent.min(percent100, totalDebt.divide(stableValuation, percent100));
      setFieldValue('asStabilizedLtv', ltv.formatMaxDecimalPlaces(0));
    }

    if (skipField !== 'debtYield' && !noi.isZero()) {
      const debtYield = Percent.min(percent100, noi.divide(totalDebt, percent100));
      setFieldValue('debtYield', debtYield.formatMaxDecimalPlaces(0));
    }

    if (skipField !== 'asStabilizedDebtYield' && !stableNoi.isZero()) {
      const debtYield = Percent.min(percent100, stableNoi.divide(totalDebt, percent100));
      setFieldValue('asStabilizedDebtYield', debtYield.formatMaxDecimalPlaces(0));
    }
  };

  const adjustLoanAmount = (
    setFieldValue: (field: string, value: any) => void,
    value: CurrencyValue,
    percent: string,
    skipField: string
  ) => {
    const newSubordinateDebt = value.multiplyByPercent(Percent.fromNumber(+percent / 100));
    const newValue = Math.ceil(newSubordinateDebt.toNumber());
    setSubordinateDebt(newValue);
    updateAmountFields(setFieldValue, newValue, skipField);
  };

  const adjustLoanAmountByNoi = (
    setFieldValue: (field: string, value: any) => void,
    noi: CurrencyValue,
    percent: string,
    skipField: string
  ) => {
    const newTotalDebt = +percent === 0 ? currency0 : noi.divideByPercent(Percent.fromNumber(+percent / 100));
    const newSubordinateDebt = CurrencyValue.min(
      CurrencyValue.fromNumber(CurrencyUnit.USD, maxSubordinateDebt),
      CurrencyValue.max(currency0, newTotalDebt.subtract(seniorLoanCv))
    );
    const newValue = Math.ceil(newSubordinateDebt.toNumber());
    setSubordinateDebt(newValue);
    updateAmountFields(setFieldValue, newValue, skipField);
  };

  const handleFilter = (
    setFieldValue: (field: keyof Filters, value: string[]) => void,
    arr: string[] | undefined,
    field: keyof Filters,
    value: string
  ): void => {
    if (!arr) {
      setFieldValue(field, [value]);
    } else if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
      setFieldValue(field, arr);
    } else {
      setFieldValue(field, [...arr, value]);
    }
  };

  const handleSubmit = (filters: Filters): void => {
    debugLastFilters.set(project?.sym, filters);
    debugLastSenior.set(project?.sym, seniorLoan);
    dispatch(getCapitalSources({projectSym: project?.sym, filters: {instrument: ['SUBORDINATE'], ...filters}}));
    handleClose();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={(values) => handleSubmit(values)}>
      {({values, setFieldValue, resetForm}) => (
        <Form>
          <div className={s.subordinate}>
            <div className={s.subordinate__container}>
              <h3 className={s.subordinate__subtitle}>Recourse</h3>
              <div className={s.subordinate__btnGroup}>
                <CheckboxButton
                  text="Non-Recourse"
                  variant="modalFilterStatic"
                  handleClick={() =>
                    handleFilter(setFieldValue, values.recourse, 'recourse', RecourseOption.NON_RECOURSE)
                  }
                  isActive={values.recourse && values.recourse.includes(RecourseOption.NON_RECOURSE)}
                />
                <CheckboxButton
                  text="Partial Recourse"
                  variant="modalFilterStatic"
                  handleClick={() =>
                    handleFilter(setFieldValue, values.recourse, 'recourse', RecourseOption.PARTIAL_RECOURSE)
                  }
                  isActive={values.recourse && values.recourse.includes(RecourseOption.PARTIAL_RECOURSE)}
                />
              </div>
            </div>
            <div className={s.subordinate__container}>
              <h3 className={s.subordinate__subtitle}>Loan Term</h3>
              <div className={s.subordinate__sliderWrapper}>
                <Box
                  sx={{
                    width: '63%',
                    display: 'flex',
                    alignItems: 'center',
                    '@media (max-width: 425px)': {
                      paddingLeft: '5px',
                      width: '95%',
                    },
                  }}
                >
                  <Slider
                    className={classes.slider}
                    size="small"
                    min={0}
                    max={40}
                    value={years}
                    onChange={handleRangeChange}
                    onChangeCommitted={() => {
                      setFieldValue('term.lowerBound', `${years[0]} years`);
                      setFieldValue('term.upperBound', `${years[1]} years`);
                    }}
                  />
                </Box>
                <div className={s.subordinate__sliderContent}>
                  <p className={s.subordinate__boxValue}>{years[0]}</p>
                  <span>-</span>
                  <p className={s.subordinate__boxValue}>{years[1]}</p>
                  <span>years</span>
                </div>
              </div>
            </div>
            <div className={s.subordinate__container}>
              <h3 className={s.subordinate__subtitle}>Senior Loan</h3>
              <div className={s.subordinate__sliderWrapper}>
                <Box
                  sx={{
                    width: '63%',
                    display: 'flex',
                    alignItems: 'center',
                    '@media (max-width: 425px)': {
                      paddingLeft: '5px',
                      width: '95%',
                    },
                  }}
                >
                  <Slider
                    className={classes.slider}
                    defaultValue={0}
                    size="small"
                    step={50000}
                    min={0}
                    max={maxSeniorLoan}
                    color="primary"
                    value={seniorLoan}
                    onChange={handleSeniorLoanChange}
                  />
                </Box>
                <div className={s.subordinate__sliderContent}>
                  <p className={s.subordinate__sliderContent_value}>${Intl.NumberFormat('en-US').format(seniorLoan)}</p>
                  {ltv.equals(percent100) ? null : (
                    <p className={s.subordinate__sliderContent_subValue}>LTV: {ltv.formatMaxDecimalPlaces(0)}</p>
                  )}
                  {ltc.equals(percent100) ? null : (
                    <p className={s.subordinate__sliderContent_subValue}>LTC: {ltc.formatMaxDecimalPlaces(0)}</p>
                  )}
                </div>
              </div>
              <div className={s.subordinate__container}>
                <div className={s.subordinate__subtitleGroup}>
                  <h3 className={s.subordinate__subtitle}>Subordinate Debt</h3>
                  <h3 className={s.subordinate__subtitle}>Equity</h3>
                </div>
                <div className={s.subordinate__sliderWrapper_third}>
                  <p className={s.subordinate__sliderContent_value}>
                    ${Intl.NumberFormat('en-US').format(subordinateDebt)}
                  </p>
                  <Box sx={{width: '60%', display: 'flex', alignItems: 'center'}}>
                    <Slider
                      className={classes.sliderSecondary}
                      defaultValue={0}
                      size="small"
                      step={50000}
                      min={0}
                      max={maxSubordinateDebt}
                      color="primary"
                      value={subordinateDebt}
                      onChange={(event: Event, newValue: number | number[]) =>
                        handleSubordinateDebtChange(setFieldValue, event, newValue)
                      }
                    />
                  </Box>
                  <p className={s.subordinate__sliderContent_value}>${Intl.NumberFormat('en-US').format(equity)}</p>
                </div>
                {budget.isZero() ? null : (
                  <div className={s.subordinate__percentEquity}>
                    <div>
                      <h3 className={s.subordinate__percentPartyTitle}>CLTC</h3>
                      <div className={s.subordinate__boxWrapper}>
                        <NumericInput
                          name="ltc"
                          value={values.ltc ? values.ltc.replace(/\D/g, '') : ''}
                          width="60px"
                          preventionLimit={MaxValidationLimits.percent}
                          onValueChange={(e) => {
                            setFieldValue('ltc', `${e.target.value}%`);
                            adjustLoanAmount(setFieldValue, cBudget, e.target.value, 'ltc');
                          }}
                        />
                        <span>%</span>
                      </div>
                    </div>
                  </div>
                )}
                {valuation.isZero() ? null : (
                  <div className={s.subordinate__percentEquity}>
                    <div>
                      <h3 className={s.subordinate__percentPartyTitle}>CLTV</h3>
                      <div className={s.subordinate__boxWrapper}>
                        <NumericInput
                          name="ltv"
                          value={values.ltv ? values.ltv.replace(/\D/g, '') : ''}
                          width="60px"
                          preventionLimit={MaxValidationLimits.percent}
                          onValueChange={(e) => {
                            setFieldValue('ltv', `${e.target.value}%`);
                            adjustLoanAmount(setFieldValue, cValuation, e.target.value, 'ltv');
                          }}
                        />
                        <span>%</span>
                      </div>
                    </div>
                    {stableValuation.isZero() ? null : (
                      <div className={s.subordinate__secondCol}>
                        <h3 className={s.subordinate__percentPartyTitle}>As-stabilized CLTV</h3>
                        <div className={s.subordinate__boxWrapper}>
                          <NumericInput
                            name="asStabilizedLtv"
                            value={values.asStabilizedLtv ? values.asStabilizedLtv.replace(/\D/g, '') : ''}
                            width="60px"
                            preventionLimit={MaxValidationLimits.percent}
                            onValueChange={(e) => {
                              setFieldValue('asStabilizedLtv', `${e.target.value}%`);
                              adjustLoanAmount(setFieldValue, cStableValuation, e.target.value, 'asStabilizedLtv');
                            }}
                          />
                          <span>%</span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {noi.isZero() ? null : (
                  <div className={s.subordinate__percentEquity}>
                    <div>
                      <h3 className={s.subordinate__percentPartyTitle}>Aggregate Debt Yield</h3>
                      <div className={s.subordinate__boxWrapper}>
                        <NumericInput
                          name="debtYield"
                          value={values.debtYield ? values.debtYield.replace(/\D/g, '') : ''}
                          width="60px"
                          preventionLimit={MaxValidationLimits.percent}
                          onValueChange={(e) => {
                            setFieldValue('debtYield', `${e.target.value}%`);
                            adjustLoanAmountByNoi(setFieldValue, noi, e.target.value, 'debtYield');
                          }}
                        />
                        <span>%</span>
                      </div>
                    </div>
                    {stableNoi.isZero() ? null : (
                      <div className={s.subordinate__secondCol}>
                        <h3 className={s.subordinate__percentPartyTitle}>As-stabilized Aggregate Debt Yield</h3>
                        <div className={s.subordinate__boxWrapper}>
                          <NumericInput
                            name="asStabilizedDebtYield"
                            value={values.asStabilizedDebtYield ? values.asStabilizedDebtYield.replace(/\D/g, '') : ''}
                            width="60px"
                            preventionLimit={MaxValidationLimits.percent}
                            onValueChange={(e) => {
                              setFieldValue('asStabilizedDebtYield', `${e.target.value}%`);
                              adjustLoanAmountByNoi(setFieldValue, stableNoi, e.target.value, 'asStabilizedDebtYield');
                            }}
                          />
                          <span>%</span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <footer className={s.footer}>
            <BasicButton className="darkBlue" type="submit">
              View Results
            </BasicButton>
          </footer>
        </Form>
      )}
    </Formik>
  );
};

const parseTerm = (term: string | undefined) => (term ? Term.fromString(term).toMonths() / 12 : undefined);

export default Subordinate;

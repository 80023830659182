import React, {FC} from 'react';
import Avatar from '@mui/material/Avatar';
import makeStyles from '@mui/styles/makeStyles';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import s from './UserCard.module.scss';

const useStyles = makeStyles(() => ({
  divider: {
    alignSelf: 'center',
    height: '75%',
    marginRight: '20px',
    backgroundColor: '#8394a5',
  },
  contactsIcon: {
    height: '20px',
    width: '20px',
    marginRight: '5px',
    color: '#787878',
  },
  avatar: {
    height: '85px',
    width: '85px',
  },
}));

type UserCardProps = {
  name: string;
  email: string;
  phoneNumber: string;
  profileImageUrl: string;
};

const UserCard: FC<UserCardProps> = ({name, profileImageUrl, email, phoneNumber}) => {
  const classes = useStyles();
  return (
    <div className={s.userCard}>
      <Avatar className={classes.avatar} src={profileImageUrl} />
      <div className={s.userCard__info}>
        <p className={s.userCard__initials}>{name}</p>
        <p className={s.userCard__role}>Capital Advisor</p>
        <div className={s.contacts}>
          <div className={s.contacts__item}>
            <PhoneIcon className={classes.contactsIcon} />
            <span className={s.contacts__phone}>{phoneNumber}</span>
          </div>
          <div className={s.contacts__item}>
            <EmailIcon className={classes.contactsIcon} />
            <a className={s.contacts__email} href={`mailto:${encodeURIComponent(email)}`}>
              {email}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCard;

import {LoanFeesSubsteps} from 'core/_consts';
import {FC, useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import FeesDetails from './FeesDetails';
import SelectLoanFees from './SelectLoanFees';

type LoanFeesSubstepsKeys = keyof typeof LoanFeesSubsteps;

const LoanFees: FC = () => {
  const location = useLocation();
  const [substep, setSubstep] = useState<LoanFeesSubstepsKeys | null>();

  useEffect(() => {
    setSubstep(new URLSearchParams(location.search).get('substep') as LoanFeesSubstepsKeys);
  }, [location.search]);

  switch (substep) {
    case LoanFeesSubsteps[LoanFeesSubsteps.selectLoanFees]:
      return <SelectLoanFees />;

    case LoanFeesSubsteps[LoanFeesSubsteps.feesDetails]:
      return <FeesDetails />;

    default:
      return null;
  }
};

export default LoanFees;

/* eslint-disable consistent-return */
import {Ava, UserProfileImage} from '@burnaby/ava';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';

// Core
import {TeamMemberData} from 'core/_types/base';
import {Person, SymOrString, UserProfile, ava} from 'core/service/ava';

import {
  BrokerProfilesType,
  CapitalAdvisorProfilesType,
  getCapitalAdvisorProfilesPropsType,
  SponsorProfilesType,
  UserSettingsType,
} from './interfaces';

import 'react-toastify/dist/ReactToastify.css';

export const getCapitalAdvisorProfiles = createAsyncThunk(
  'user/getCapitalAdvisorProfiles',
  async (
    {
      usersSyms,
      ava,
    }: getCapitalAdvisorProfilesPropsType,
  ): Promise<CapitalAdvisorProfilesType> => {
    try {
      const userProfiles = await Promise.all(
        usersSyms.map((item) => ava.userProfiles.get(item)),
      );
      return userProfiles.map((u) => teamMemberDataFromProfile(u));
    } catch (error: any) {
      toast.error(`${error.message}`);
      throw error;
    }
  },
);

const getTeamMemberDataFromSymOrEmails = async (symOrEmails: SymOrString[]) => {
  const results = await Promise.all<Person|UserProfile>(
    symOrEmails.map(
      u => u.sym
        ? ava.userProfiles.get(u.sym)
        : ava.people.getByEmail(u.string),
    ),
  );
  return results.map(
    (u) => u instanceof UserProfile
      ? teamMemberDataFromProfile(u)
      : teamMemberDataFromPerson(u),
  );
};

export const getSponsorProfiles = createAsyncThunk(
  'user/getSponsorProfiles',
  getTeamMemberDataFromSymOrEmails,
);

export const getBrokerProfiles = createAsyncThunk(
  'user/getBrokerProfiles',
  getTeamMemberDataFromSymOrEmails,
);

export const getUserSettings = createAsyncThunk(
  'user/getUserSettings',
  async (ava: Ava): Promise<UserSettingsType | void> => {
    try {
      const userSettings = await ava.getUserSettings();
      return userSettings;
    } catch (error: any) {
      toast.error(`${error.message}`);
    }
  },
);

function teamMemberDataFromPerson(person: Person): TeamMemberData {
  return {
    displayName: person.displayName,
    website: '',
    phoneNumber: person.phoneNumber,
    email: person.email,
    emailVerified: false,
    // TODO(nathan): Profile image for person
    profileImage: UserProfileImage.getDefault(),
    sym: '',
  };
}

function teamMemberDataFromProfile(profile: UserProfile): TeamMemberData {
  return {
    displayName: profile.name,
    website: '',
    phoneNumber: profile.phoneNumber,
    email: profile.email,
    emailVerified: profile.emailVerified,
    profileImage: profile.profileImage,
    sym: profile.sym,
  };
}

import {FC, useMemo} from 'react';
import {Formik} from 'formik';

// Core
import {DealType} from 'core/_types/base';
import {AssetInfoType} from 'core/_types/UnderwritingTypes';
import {Questionary} from 'core/enums/questionary';

import AssetInfoForm from './AssetInfoForm';

type AssetInfoProps = {
  project: DealType;
};

const AssetInfo: FC<AssetInfoProps> = ({project}) => {
  const {address} = project;

  const initialValues: AssetInfoType = {
    assetType: {
      value: project?.assetType,
      label: project?.formatAssetType(),
    },
    propertyTitle: {
      value: project?.propertyTitle,
      label: project?.formatPropertyTitle(),
    },
    numberOfUnits: project?.numberOfUnits,
    occupancy: project?.occupancy?.units,
    address: {
      line1: address?.line1,
      line2: address?.line2,
      city: address?.city,
      state: address?.state,
      zip: address?.zip,
    },
    totalSize: project?.totalSize?.amount,
    footprintSize: project?.footprintSize?.amount,
    lotSize: project?.lotSize?.amount,
    architect: project?.architect,
    structuralEngineer: project?.structuralEngineer,
    constructionManager: project?.constructionManager,
    generalContractor: project?.generalContractor,
    leasingRepresentative: project?.leasingRepresentative,
    propertyManager: project?.propertyManager,
    completedYear: project?.completedYear,
    renovatedYear: project?.renovatedYear,
    floorsAboveGround: project?.floorsAboveGround,
    floorsBelowGround: project?.floorsBelowGround,
    designInfo: project?.designInfo?.content,
    majorCrimes: Questionary[project?.disclaimer?.majorCrimes],
    lesserCrimes: Questionary[project?.disclaimer?.lesserCrimes],
    taxesDelinquent: Questionary[project?.disclaimer?.taxesDelinquent],
    paymentsDelinquent: Questionary[project?.disclaimer?.paymentsDelinquent],
    environmentalHazards: Questionary[project?.disclaimer?.environmentalHazards],
    floodZone: Questionary[project?.disclaimer?.floodZone],
    seismicZone: Questionary[project?.disclaimer?.seismicZone],
    assetNotes: project?.assetNotes.content,
  };

  const assetType = useMemo(() => project?.formatAssetType(), [project]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        // eslint-disable-next-line no-alert
        alert(JSON.stringify(values));
      }}
    >
      {(props) => (
        <AssetInfoForm
          values={props.values}
          handleChange={props.handleChange}
          setFieldValue={props.setFieldValue}
          initialValues={initialValues}
          assetType={assetType}
        />
      )}
    </Formik>
  );
};

export default AssetInfo;

const SINATRA_HOST = process.env.REACT_APP_AVA_REMOTE;

export function toSinatraUrl(bubleUrl: string, keepOldUi = false) {
  const m = /^\w+:\/+[^/]+(?:\/(.*))?$/.exec(bubleUrl);
  const path = m ? m[1] : '';
  const delim = /#/.test(path) ? '&' : '#';
  const oldUi = keepOldUi ? `${delim}ui=0` : '';
  return `${SINATRA_HOST}/${path}${oldUi}`;
}

export enum SinatraDealTab {
  'view',
  'offer-comparison',
}

export function sinatraDealUrl(sym: string, tab = SinatraDealTab.view) {
  return `${SINATRA_HOST}/deal:${sym}/${SinatraDealTab[tab]}`;
}

export function sinatraNewDealUrl() {
  return `${SINATRA_HOST}/deal:new`;
}

import {
  AcquisitionStatus,
  AssetType,
  AttachmentType,
  Deal,
  DealTenancyType,
  DisclaimerItem,
  FinancingPurpose,
  UnderwritingApprovalRequirement as Requirement,
  SponsorRole,
  TermRange,
  usd,
} from 'core/service/ava';

type ChecklistProps = {
  deal: Deal,
  sponsorEmailVerified: boolean,
}

type ChecklistItem = {
  key: string,
  label: string,
  checked: boolean,
  requirement: Requirement,
  required: boolean,
}

export function* getChecklist(props: ChecklistProps): Iterable<ChecklistItem> {
  const items = [
    ...getChecklistTasks(props),
    ...getChecklistAttachments(props),
  ];
  for (const {key, label, checked, requirement, render} of items) {
    if (render || render == null) {
      yield {
        key, label, checked, requirement,
        required: isRequired(props, requirement),
      };
    }
  }
}

function getChecklistTasks(props: ChecklistProps) {
  const {deal} = props;
  return [
    {
      key: 'verify-email',
      label: 'Verify sponsor email address',
      checked: props.sponsorEmailVerified,
      requirement: Requirement.VERIFY_SPONSOR_EMAIL,
    },
    {
      key: 'sponsor-bio',
      label: 'Fill out sponsor bio',
      checked: hasSponsorBio(props),
      requirement: Requirement.SPONSOR_BIO,
    },
    {
      key: 'asset-pictures',
      label: 'Upload asset pictures',
      checked: deal.imageList.size > 0,
      requirement: Requirement.ASSET_PICTURES,
    },
    {
      key: 'sources-and-uses',
      label: 'Sources & uses',
      checked:
        !!getAttachment(props, AttachmentType.SOURCES_AND_USES)
        || (
          deal.capStack.sourceList.size > 0
          && deal.capStack.sourceList.some(
            u => !u.principal.isZero(),
          )
          && deal.capStack.sourceList.reduce(
            (acc, u) => acc.add(u.principal),
            usd(0),
          ).equals(
            deal.capStack.useList.reduce(
              (acc, u) => acc.add(u.principal),
              usd(0),
            ),
          )
        ),
      requirement: Requirement.SOURCES_AND_USES,
      render:
        deal.financingPurpose === FinancingPurpose.ACQUISITION
        || deal.financingPurpose === FinancingPurpose.NEW_DEVELOPMENT
        || deal.financingPurpose === FinancingPurpose.REDEVELOPMENT,
    },
  ];
}

function* getChecklistAttachments(props: ChecklistProps) {
  for (const item of getChecklistAttachmentsInner(props)) {
    const attachment = getAttachment(props, item.attachmentType);
    yield {
      key: item.key,
      label: item.label,
      checked: !!attachment,
      render: item.render,
      requirement: item.requirement,
    };
  }
}

function getChecklistAttachmentsInner(props: ChecklistProps) {
  const {deal} = props;
  return [
    {
      key: 'pro-forma',
      label: 'Pro forma',
      attachmentType: AttachmentType.PRO_FORMA,
      requirement: Requirement.PRO_FORMA,
      render: deal.tenancy.type !== DealTenancyType.SINGLE,
    },
    {
      key: 'financial-statements',
      label: 'Financial statements',
      attachmentType: AttachmentType.FINANCIAL_STATEMENTS,
      requirement: Requirement.FINANCIAL_STATEMENTS,
      render:
        (
          deal.financingPurpose === FinancingPurpose.ACQUISITION
          || deal.financingPurpose === FinancingPurpose.REFINANCE
        ) && deal.tenancy.type !== DealTenancyType.SINGLE,
    },
    {
      key: 'rent-roll',
      label: 'Rent roll',
      attachmentType: AttachmentType.RENT_ROLL,
      requirement: Requirement.RENT_ROLL,
      render:
        (deal.financingPurpose === FinancingPurpose.ACQUISITION
            || deal.financingPurpose === FinancingPurpose.REFINANCE)
        && deal.tenancy.type !== DealTenancyType.SINGLE,
    },
    {
      key: 'tenant-lease',
      label: 'Tenant lease',
      attachmentType: AttachmentType.TENANT_LEASE,
      requirement: Requirement.TENANT_LEASE,
      render: deal.tenancy.type === DealTenancyType.SINGLE,
    },
    {
      key: 'sales-contract',
      label: 'Sales contract',
      attachmentType: AttachmentType.SALES_CONTRACT,
      requirement: Requirement.SALES_CONTRACT,
      render:
        deal.financingPurpose === FinancingPurpose.ACQUISITION
        && deal.acquisitionStatus === AcquisitionStatus.UNDER_CONTRACT,
    },
    {
      key: 'sales-comps',
      label: 'Sales comps',
      attachmentType: AttachmentType.SALES_COMPS,
      requirement: Requirement.SALES_COMPS,
      render:
        deal.financingPurpose === FinancingPurpose.NEW_DEVELOPMENT
        || deal.financingPurpose === FinancingPurpose.REDEVELOPMENT
        || deal.offerExpectations.loanTermRange.overlaps(
          TermRange.ofYears(0, 2),
        ),
    },
    {
      key: 'lease-comps',
      label: 'Lease comps',
      attachmentType: AttachmentType.LEASE_COMPS,
      requirement: Requirement.LEASE_COMPS,
      render:
        deal.sponsorRole === SponsorRole.INVESTOR
        && (
          deal.financingPurpose === FinancingPurpose.NEW_DEVELOPMENT
          || deal.financingPurpose === FinancingPurpose.REDEVELOPMENT
          || deal.offerExpectations.loanTermRange.overlaps(
            TermRange.ofYears(0, 2),
          )
        ),
    },
    {
      key: 'sponsor-financials',
      label: 'Sponsor financials',
      attachmentType: AttachmentType.SPONSOR_FINANCIALS,
      requirement: Requirement.SPONSOR_FINANCIALS,
    },
    {
      key: 'schedule-of-real-estate-owned',
      label: 'Schedule of real estate owned',
      attachmentType: AttachmentType.SCHEDULE_OF_REAL_ESTATE_OWNED,
      requirement: Requirement.SCHEDULE_OF_REAL_ESTATE_OWNED,
    },
    {
      key: 'construction-budget',
      label: 'Construction budget',
      attachmentType: AttachmentType.CONSTRUCTION_BUDGET,
      requirement: Requirement.CONSTRUCTION_BUDGET,
      render:
        deal.financingPurpose === FinancingPurpose.NEW_DEVELOPMENT
        || deal.financingPurpose === FinancingPurpose.REDEVELOPMENT,
    },
    {
      key: 'environmental-report',
      label: 'Environmental report',
      attachmentType: AttachmentType.ENVIRONMENTAL_REPORT,
      requirement: Requirement.ENVIRONMENTAL_REPORT,
      render: deal.disclaimer.environmentalHazards === DisclaimerItem.YES,
    },
    {
      key: 'hotel-franchise-agreement',
      label: 'Hotel franchise agreement',
      attachmentType: AttachmentType.HOTEL_FRANCHISE_AGREEMENT,
      requirement: Requirement.HOTEL_FRANCHISE_AGREEMENT,
      render:
        deal.assetType === AssetType.HOTEL
        && !!(deal.hotelFlag.organization || deal.hotelFlag.name),
    },
  ];
}

function getAttachment(props: ChecklistProps, type: AttachmentType) {
  for (const attachment of props.deal.attachmentList) {
    if (attachment.typeList.includes(type)) {
      return attachment;
    }
  }
}

function hasSponsorBio(props: ChecklistProps) {
  return !props.deal.sponsorProfile.description.isEmpty()
    || !!getAttachment(props, AttachmentType.SPONSOR_BIO);
}

function isRequired(props: ChecklistProps, requirement: Requirement) {
  return !props.deal.underwritingApprovalRequirementExceptionList
    .includes(requirement);
}

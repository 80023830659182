import {PayloadAction, prepareAutoBatched} from '@reduxjs/toolkit';

import {createSlice} from 'core/helpers/redux';
import {Attachment, Deal, Offer, Provider, Share} from 'core/service/ava';

import {
  createProject, getCapitalSources, getNextProjects, getOffers, getProject,
  getProjects,
} from './asyncActions';

type State = {
  loading: boolean,
  newProjectSym: string,
  project: Deal|null,
  projects: Deal[],
  offers: Offer[],
  next: unknown,
  filters: unknown,
  capitalSources: Provider[],
  shares: Share[],
  updated: {
    overview: string|null,
    assetInfo: string|null,
    financialInfo: string|null,
    sponsorProfile: string|null,
    sourcesAndUses: string|null,
    guarantorsUpd: {
      message: string|null,
      addCompleted: boolean,
      editCompleted: boolean,
    },
  },
};
const initialState: State = {
  loading: false,
  newProjectSym: '',
  project: null,
  projects: [],
  offers: [],
  next: null,
  filters: null,
  capitalSources: [],
  shares: [],
  updated: {
    overview: null,
    assetInfo: null,
    financialInfo: null,
    sponsorProfile: null,
    sourcesAndUses: null,
    guarantorsUpd: {
      message: null,
      addCompleted: false,
      editCompleted: false,
    },
  },
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  simpleReducers: {
    setProject: (state, action: PayloadAction<Deal>) => {
      return {...state, project: action.payload};
    },
    addDealAttachments: (
      state,
      action: PayloadAction<Iterable<Attachment>>,
    ) => {
      if (state.project) {
        return {
          ...state,
          project: state.project.addEachAttachment(action.payload),
        };
      }
      return state;
    },
    deleteDealAttachment: (state, action: PayloadAction<string>) => {
      if (state.project) {
        return {
          ...state,
          project: state.project.setAttachmentList(
            state.project.attachmentList.filter(
              (u: Attachment) => u.sym !== action.payload,
            ),
          ),
        };
      }
      return state;
    },
  },
  reducers: {
    setUpdatedOverview: (state, action: PayloadAction<string>) => {
      state.updated.overview = action.payload;
    },
    setUpdatedAssetInfo: (state, action: PayloadAction<string>) => {
      state.updated.assetInfo = action.payload;
    },
    setUpdatedFinancialInfo: (state, action: PayloadAction<string>) => {
      state.updated.financialInfo = action.payload;
    },
    setUpdatedSponsorProfile: (state, action: PayloadAction<string>) => {
      state.updated.sponsorProfile = action.payload;
    },
    setUpdatedSourcesAndUses: (state, action: PayloadAction<string>) => {
      state.updated.sourcesAndUses = action.payload;
    },
    setUpdatedGuarantors: (state, action: PayloadAction<string>) => {
      state.updated.guarantorsUpd.message = action.payload;
    },
    setUpdatedGuarantorsAddCompleted: (state, action: PayloadAction<boolean>) => {
      state.updated.guarantorsUpd.addCompleted = action.payload;
    },
    setUpdatedGuarantorsEditCompleted: (state, action: PayloadAction<boolean>) => {
      state.updated.guarantorsUpd.editCompleted = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProject.fulfilled, (state, action: PayloadAction<any>) => {
      state.project = action.payload;
    });
    builder.addCase(getProjects.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProjects.fulfilled, (state, action: any) => {
      state.projects = action.payload.projects;
      state.next = action.payload.next;
      state.filters = action.payload.filters;
      state.loading = false;
    });
    builder.addCase(getNextProjects.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNextProjects.fulfilled, (state, action: any) => {
      state.projects = action.payload.newProjects;
      state.next = action.payload.next;
      state.loading = false;
    });
    builder.addCase(getOffers.fulfilled, (state, action: PayloadAction<any>) => {
      state.offers = action.payload;
    });
    builder.addCase(getCapitalSources.fulfilled, (state, action: PayloadAction<any>) => {
      if (action.payload) {
        state.capitalSources = action.payload.providers;
      }
    });
    builder.addCase(createProject.fulfilled, (state, action: PayloadAction<any>) => {
      state.newProjectSym = action.payload;
    });
  },
});

export const {
  addDealAttachments,
  deleteDealAttachment,
  setProject,
  setUpdatedOverview,
  setUpdatedAssetInfo,
  setUpdatedSponsorProfile,
  setUpdatedFinancialInfo,
  setUpdatedSourcesAndUses,
  setUpdatedGuarantors,
  setUpdatedGuarantorsAddCompleted,
  setUpdatedGuarantorsEditCompleted,
} = projectSlice.actions;

export default projectSlice.reducer;

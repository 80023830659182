import {FC} from 'react';

import s from './LoanRequest.module.scss';

type LoanRequestProps = {
  project: any;
};

const LoanRequest: FC<LoanRequestProps> = ({project}) => (
  <div className={s.loanRequest}>
    <div className={s.loanRequest__text}>
      <p className={s.loanRequest__total}>{project.principalNeeded?.format().replace(' to ', '-')}</p>
      <p>capital request</p>
    </div>
  </div>
);

export default LoanRequest;

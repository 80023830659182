import {FC, useMemo} from 'react';
import {
  CashManagementSubsteps,
  QuoteTermsSteps,
  signOptions,
  RequiredReservesSubsteps,
  MaxValidationLimits,
  requiredReservesLabels,
  windowSizes,
} from 'core/_consts';
import NextButton from 'shared/components/NextButton';
import * as yup from 'yup';
import {Form, Formik} from 'formik';
import {DOLLAR_TYPE} from 'core/helpers/yupSchemas';
import {ReservesDetailsValue} from 'core/_types/quoteTermsTypes';
import {setReservesDetails} from 'store/quote-terms-service/quoteTermsSlice';
import {useSelector} from 'react-redux';
import {selectReservesDetails, selectSelectedRequiredReserves} from 'store/quote-terms-service/quoteTermsSelector';
import Controller from 'shared/components/FormikController';
import {FormikElement} from 'core/enums/formik-controller.enum';
import useWindowWidth from 'core/utils/hooks/useWindowWidth';
import s from './ReservesDetails.module.scss';

const ReservesDetails: FC = () => {
  const selecedReservesDetails = useSelector(selectSelectedRequiredReserves);
  const defaultValue = useSelector(selectReservesDetails);

  const structuredReservesDetails: Array<ReservesDetailsValue> = useMemo(
    () =>
      selecedReservesDetails.map((item) => ({
        name: item,
        upfront: defaultValue.find((el) => el.name === item)?.upfront || '',
        monthly: defaultValue.find((el) => el.name === item)?.monthly || '',
        cap: defaultValue.find((el) => el.name === item)?.cap || '',
      })),
    [selecedReservesDetails]
  );

  const initialValues = useMemo(
    () => ({
      structuredReservesDetails,
    }),
    [structuredReservesDetails]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        structuredReservesDetails: yup.array().of(
          yup.object().shape({
            name: yup.string().required(),
            upfront: DOLLAR_TYPE,
            monthly: DOLLAR_TYPE,
            cap: DOLLAR_TYPE,
          })
        ),
      }),
    [structuredReservesDetails]
  );

  const windowWidth = useWindowWidth();

  const responsiveInputWidth = useMemo(() => (windowWidth > windowSizes.tablet ? '140px' : '170px'), [windowWidth]);

  return (
    <div className="FormSlide">
      <div className="FormSlide__heading">
        <p className="FormSlide__title">Enter details for the required reserves.</p>
        <p className="FormSlide__subTitle">
          Either Upfront or both Monthly and Cap for each type of reserve are required.
        </p>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        // TODO: add handle logic on submit
        // eslint-disable-next-line no-console
        onSubmit={(values) => console.log(values)}
      >
        {({values, setFieldValue}) => (
          <Form className="FormSlide__form">
            <div className={s.inputs}>
              {structuredReservesDetails.map((item, itemIndex) => (
                <div key={item.name} className={s.section}>
                  <div className="FormSlide__subTitle FormSlide__subTitle_grey">{item.name}</div>
                  <div className={s.section__fields}>
                    {Object.keys(requiredReservesLabels).map((label, index) => (
                      <Controller
                        key={label}
                        name={`structuredReservesDetails[${itemIndex}][${
                          Object.values(requiredReservesLabels)[index]
                        }]`}
                        control={FormikElement.NUMERIC_INPUT}
                        preventionLimit={MaxValidationLimits.highestLimit}
                        reversed
                        thousandSeparator
                        setFieldValue={setFieldValue}
                        textAlign="right"
                        label={label}
                        width={responsiveInputWidth}
                        sign={signOptions.dollar}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <NextButton
              isDisabled={
                !values.structuredReservesDetails
                  .map(
                    (item) =>
                      (item.upfront && !(item.monthly || item.cap)) || (item.monthly && item.cap && !item.upfront)
                  )
                  .every(Boolean)
              }
              slideData={{
                [RequiredReservesSubsteps[RequiredReservesSubsteps.reservesDetails]]: values.structuredReservesDetails,
              }}
              action={setReservesDetails}
              nextSlide={{
                step: QuoteTermsSteps[QuoteTermsSteps.cashManagement],
                substep: CashManagementSubsteps[CashManagementSubsteps.isLockboxNeeded],
              }}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ReservesDetails;

import {useState} from 'react';
import NumericInput from 'shared/components/NumericInput';
import NextButton from 'shared/components/NextButton';
import {MaxValidationLimits, PrepayPenaltySubsteps, QuoteTermsSteps} from 'core/_consts';
import {validateNumericInput} from 'core/utils';
import {setFeeAmount} from 'store/quote-terms-service/quoteTermsSlice';
import {useSelector} from 'react-redux';
import {selectPenaltyFee} from 'store/quote-terms-service/quoteTermsSelector';

const FeeAmount = () => {
  const defaultValue = useSelector(selectPenaltyFee);
  const [feeValue, setFeeValue] = useState(defaultValue);

  return (
    <div className="FormSlide">
      <div className="FormSlide__form">
        <p className="FormSlide__title mb35">What is the fee?</p>
        <NumericInput
          isValid={validateNumericInput(Number(feeValue), MaxValidationLimits.percent)}
          preventionLimit={MaxValidationLimits.percent}
          onValueChange={(values) => setFeeValue(Number(values.value))}
          defaultValue={defaultValue || ''}
          width="120px"
          label="Percentage of Loan"
          sign="%"
        />
      </div>
      <NextButton
        isDisabled={!validateNumericInput(Number(feeValue), MaxValidationLimits.percent)}
        slideData={{[PrepayPenaltySubsteps[PrepayPenaltySubsteps.feeAmount]]: feeValue}}
        action={setFeeAmount}
        nextSlide={{
          step: QuoteTermsSteps[QuoteTermsSteps.prepayPenalty],
          substep: PrepayPenaltySubsteps[PrepayPenaltySubsteps.minimumInterestPerion],
        }}
      />
    </div>
  );
};

export default FeeAmount;

import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {CreateNewDealInitialValues} from 'core/utils/createNewDealValues';
import {
  IAssetType,
  IAttachmentsPictures,
  ICreateNewDealReducer,
  IFinancePurpose,
  IFinancingRequest,
  IFinancingRequestDetails,
  IMultifamilyAcquisition,
  IMultiFamilyType,
  IProjectAddress,
  IProjectBudget,
  IPropertyIncome,
  IRole,
  ISummary,
  IUserData,
} from './interfaces';

const initialState: {data: ICreateNewDealReducer} = {
  data: new CreateNewDealInitialValues(),
};

const createNewDealSlice = createSlice({
  name: 'createNewDeal',
  initialState,
  reducers: {
    setInitialValues: (state, action: PayloadAction<ICreateNewDealReducer>) => {
      state.data = action.payload;
    },
    setUserData: (state, action: PayloadAction<IUserData>) => {
      state.data.userData = action.payload;
    },
    setAssetType: (state, action: PayloadAction<IAssetType>) => {
      state.data.assetType = action.payload;
    },
    setMultiFamilyType: (state, action: PayloadAction<IMultiFamilyType>) => {
      state.data.multiFamilyType = action.payload;
    },
    setFinancePurpose: (state, action: PayloadAction<IFinancePurpose>) => {
      state.data.financePurpose = action.payload;
    },
    setProjectAddress: (state, action: PayloadAction<IProjectAddress>) => {
      state.data.projectAddress = action.payload;
    },
    setPropetryIncome: (state, action: PayloadAction<IPropertyIncome>) => {
      state.data.propertyIncome = action.payload;
    },
    setProjectBudget: (state, action: PayloadAction<IProjectBudget>) => {
      state.data.projectBudget = action.payload;
    },
    setFinancingRequest: (state, action: PayloadAction<IFinancingRequest>) => {
      state.data.financingRequest = action.payload;
    },
    setFinancingRequestDetails: (state, action: PayloadAction<IFinancingRequestDetails>) => {
      state.data.financingRequestDetails = action.payload;
    },
    setAttachmentsPictures: (state, action: PayloadAction<IAttachmentsPictures>) => {
      state.data.attachmentsPictures = action.payload;
    },
    setProjectSummary: (state, action: PayloadAction<ISummary>) => {
      state.data.projectSummary = action.payload;
    },
    setUserRole: (state, action: PayloadAction<IRole>) => {
      state.data.userRole = action.payload;
    },
    setMultiFamilyAcquisition: (state, action: PayloadAction<IMultifamilyAcquisition>) => {
      state.data.multiFamilyAcquisition = action.payload;
    },
  },
});

export const {
  setInitialValues,
  setUserData,
  setAssetType,
  setMultiFamilyType,
  setFinancePurpose,
  setProjectAddress,
  setPropetryIncome,
  setProjectBudget,
  setFinancingRequest,
  setFinancingRequestDetails,
  setAttachmentsPictures,
  setProjectSummary,
  setUserRole,
  setMultiFamilyAcquisition,
} = createNewDealSlice.actions;

export default createNewDealSlice.reducer;

import {FC} from 'react';
import {useDispatch} from 'react-redux';

import {DealType} from 'core/_types/base';

import {getProject} from 'store/project-service/asyncActions';

import preview from 'assets/images/preview.jpeg';
import s from './ProjectCard.module.scss';

type ProjectCardProps = {
  deal: DealType;
  setProjectSelected: (value: boolean) => void;
};

const ProjectCard: FC<ProjectCardProps> = ({deal, setProjectSelected}: ProjectCardProps) => {
  const dispatch = useDispatch();
  const projectImage = Array.from(deal.imageList).map((image: any) => image.url)[0];

  const handleClick = () => {
    setProjectSelected(true);
    dispatch(getProject({projectSym: deal.sym}));
  };

  return (
    <div onClick={handleClick} className={s.projectCard}>
      {projectImage ? (
        <img className={s.projectCard__image} src={projectImage} alt="Project preview" />
      ) : (
        <img className={s.projectCard__image} src={preview} alt="Project preview" />
      )}
      <div className={s.projectCard__addresses}>
        <p className={s.projectCard__name}>{deal.name}</p>
        <p className={s.projectCard__street}>
          <span>
            {deal.address.line1} {deal.address.formatCityAndState()} ·{' '}
          </span>
          <span className={s.projectCard__budget}>{deal.totalProjectBudget.formatAbbr()}</span>
        </p>
      </div>
    </div>
  );
};

export default ProjectCard;

import {AmortizationType, ProgramType, RecourseType} from 'core/_types/Filters';

export const seniorFilters = {
  bank: {
    value: 'BANK' as ProgramType,
    label: 'Bank',
  },
  creditUnion: {
    value: 'CREDIT_UNION' as ProgramType,
    label: 'Credit Union',
  },
  agency: {
    value: 'AGENCY' as ProgramType,
    label: 'Agency',
  },
  lifeCo: {
    value: 'LIFE_CO' as ProgramType,
    label: 'Life Co',
  },
  SBA: {
    value: 'SBA' as ProgramType,
    label: 'SBA',
  },
  CMBS: {
    value: 'CMBS' as ProgramType,
    label: 'CMBS',
  },
  debtFund: {
    value: 'DEBT_FUND' as ProgramType,
    label: 'Debt Fund',
  },
  other: {
    value: 'OTHER' as ProgramType,
    label: 'Other',
  },
  nonRecourse: {
    value: 'NON_RECOURSE' as RecourseType,
    label: 'Non-Recourse',
  },
  partialRecourse: {
    value: 'PARTIAL_RECOURSE' as RecourseType,
    label: 'Partial Recourse',
  },
  interestOnly: {
    value: 'INTEREST_ONLY' as AmortizationType,
    label: 'Interest Only',
  },
  partialInterestOnly: {
    value: 'PARTIAL_INTEREST_ONLY' as AmortizationType,
    label: 'Partial Interest Only',
  },
};

export const ROUTERS = {
  PROJECT: '/deal',
  PROJECT_VIEW: '/deal::dealSym/:tab',
  PROJECT_VIEW_OLD: '/project::dealSym/:tab',
  LENDER_VIEW: '/deal::dealSym/view',
  CREATE_NEW_PROJECT: '/deal:new',
  CREATE_NEW_PROJECT_MATCH: '/deal\\:new',
  LOGIN: '/login',
  SIGNUP: '/signup',
  DASHBOARD: '/deals',
  NON_BINDING_OFFER: '/deal::dealSym/view/non-binding-offer',
  PROFILE: '/profile',
};

export enum AttachmentTypes {
  // TODO: ask about possible formats for archive, image, text & video
  pdf = 'pdf',
  jpg = 'image',
  png = 'image',
  zip = 'archive',
  rar = 'archive',
  xlsx = 'spreadsheet',
  xls = 'spreadsheet',
  txt = 'text',
  docx = 'text',
  other = 'other_attachment',
}

import {FC} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Formik} from 'formik';

// Core
import {Filters} from 'core/_types/Filters';

// Store
import {getCapitalSources} from 'store/project-service/asyncActions';
import {selectorGetProject} from 'store/project-service/selector';

import SeniorForm from './SeniorForm';

type SeniorProps = {
  handleClose: () => void;
};

const Senior: FC<SeniorProps> = ({handleClose}) => {
  const dispatch = useDispatch();
  const project = useSelector(selectorGetProject);

  const parsedLocalStorage = JSON.parse(localStorage.getItem('seniorFilters') || '{}');

  const initialValues: Filters = parsedLocalStorage || {};

  const handleSubmit = (filters: Filters): void => {
    dispatch(getCapitalSources({projectSym: project?.sym, filters: {instrument: ['SENIOR'], ...filters}}));
    localStorage.setItem('seniorFilters', JSON.stringify(filters));
    handleClose();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={(values) => handleSubmit(values)}>
      {(props) => <SeniorForm values={props.values} setFieldValue={props.setFieldValue} resetForm={props.resetForm} />}
    </Formik>
  );
};

export default Senior;
